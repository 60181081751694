import { createSelector } from 'reselect';

import { NONE } from '../../api';
import {
  locationSelector,
  roundByLocationSelector,
} from '../../main/selectors';
import { matchesByRoundMatchIdSelector } from '../../main/selectors/models';

export const gameSelector = state => state.game;

export const eventsListSelector = createSelector(
  gameSelector,
  game => game.eventsList,
);

export const matchesListSelector = createSelector(
  roundByLocationSelector,
  gameSelector,
  (round, game) =>
    round == null ? game.matchesList : roundToMatchesList(round),
);

export const firstMatchSelector = createSelector(
  matchesListSelector,
  matchesList =>
    matchesList.data == null || matchesList.data.results.length === 0
      ? undefined
      : matchesList.data.results[0],
);

export const currentMatchSelector = createSelector(
  locationSelector,
  matchesByRoundMatchIdSelector,
  (location, matchesByRoundMatchId) => {
    const { roundMatchId } = location.payload;
    return roundMatchId == null
      ? undefined
      : matchesByRoundMatchId[roundMatchId];
  },
);

export const querySelector = createSelector(gameSelector, game => game.query);

export const selectedFiltersSelector = createSelector(
  gameSelector,
  game => game.selectedFilters,
);

export const selectedOddsByIdSelector = createSelector(
  gameSelector,
  game => game.selectedOddsById,
);

export const sidebarOpenSelector = createSelector(
  gameSelector,
  game => game.sidebarOpen,
);

export const wagersSelector = createSelector(gameSelector, game => game.wagers);

function roundToMatchesList(round) {
  const roundData =
    round == null || round.data == null ? undefined : round.data;

  return roundData == null
    ? round
    : {
        ...round,
        data: {
          count: roundData === NONE ? 0 : roundData.matches.length,
          next: null,
          previous: null,
          results: roundData === NONE ? [] : roundData.matches,
        },
      };
}
