import {
  genericFetchInitialState,
  genericFetchKeyedReducer,
  genericFetchMoreReducer,
  genericFetchMultipleKeyedReducer,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  ANALYSIS_FORM_CHANGE,
  FETCH_AUTHENTICATED_TIPSTER_ACTION,
  FETCH_CONTENT_LIST,
  FETCH_EVENT,
  FETCH_EVENT_GROUPS,
  FETCH_EVENT_LIST,
  FETCH_FILTERED_PUBLIC_ANALYSIS,
  FETCH_MORE_CONTENT_LIST,
  FETCH_PRIVATE_ANALYSIS,
  FETCH_PUBLIC_ANALYSIS,
  FETCH_UNCORRECTED_ANALYSES,
  FETCH_UPCOMING_ANALYSES,
  GROUP_FILTER_CHANGE,
  INIT_ANALYSIS_FORM,
  RESET_FILTERS,
  SET_AVAILABLE_FILTERS,
  SET_SELECTED_FILTER_KEY,
} from './actions';

const initialState = {
  authenticatedTipster: genericFetchInitialState,
  contentList: genericFetchInitialState,
  latestContent: genericFetchInitialState,
  eventGroups: genericFetchInitialState,
  eventList: genericFetchInitialState,
  adminUpcoming: genericFetchInitialState,
  adminUncorrected: genericFetchInitialState,
  eventGroupsFilter: {},
  eventById: {},
  privateAnalyses: {},
  publicAnalyses: {},
  formData: {
    text: '',
    headline: '',
    stake: 0,
    access: 0,
  },
  availableFilters: undefined,
  analysesByFilterKey: {},
  selectedFilterKey: undefined,
};

export default function analysesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AUTHENTICATED_TIPSTER_ACTION:
      return {
        ...state,
        authenticatedTipster: genericFetchReducer(
          state.authenticatedTipster,
          action.wrappedAction,
        ),
      };

    case FETCH_PRIVATE_ANALYSIS:
      return genericFetchKeyedReducer(
        state,
        'privateAnalyses',
        action.analysisId,
        action.wrappedAction,
      );

    case FETCH_PUBLIC_ANALYSIS:
      return genericFetchKeyedReducer(
        state,
        'publicAnalyses',
        action.analysisId,
        action.wrappedAction,
      );

    case SET_SELECTED_FILTER_KEY:
      return {
        ...state,
        selectedFilterKey: action.key,
      };

    case FETCH_FILTERED_PUBLIC_ANALYSIS:
      return genericFetchKeyedReducer(
        state,
        'analysesByFilterKey',
        action.key,
        action.wrappedAction,
      );

    case FETCH_UPCOMING_ANALYSES:
      return genericFetchMultipleKeyedReducer(
        state,
        'privateAnalyses',
        'adminUpcoming',
        action.wrappedAction,
      );

    case FETCH_UNCORRECTED_ANALYSES:
      return genericFetchMultipleKeyedReducer(
        state,
        'privateAnalyses',
        'adminUncorrected',
        action.wrappedAction,
      );

    case FETCH_CONTENT_LIST:
      return {
        ...state,
        contentList: genericFetchReducer(
          state.contentList,
          action.wrappedAction,
        ),
      };

    case FETCH_MORE_CONTENT_LIST:
      return {
        ...state,
        analysesByFilterKey: {
          ...state.analysesByFilterKey,
          [action.key]: genericFetchMoreReducer(
            state.analysesByFilterKey[action.key],
            action.wrappedAction,
          ),
        },
      };

    case FETCH_EVENT_GROUPS:
      return {
        ...state,
        eventGroups: genericFetchReducer(
          state.eventGroups,
          action.wrappedAction,
        ),
      };

    case FETCH_EVENT_LIST:
      return {
        ...state,
        eventList: genericFetchReducer(state.eventList, action.wrappedAction),
      };

    case FETCH_EVENT:
      return {
        ...state,
        eventById: {
          ...state.eventById,
          [action.eventId]: genericFetchReducer(
            state.eventById[action.eventId],
            action.wrappedAction,
          ),
        },
      };

    case SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        availableFilters: action.filters,
      };
    }

    case GROUP_FILTER_CHANGE: {
      return {
        ...state,
        eventGroupsFilter: action.filter,
      };
    }

    case INIT_ANALYSIS_FORM: {
      return {
        ...state,
        formData: { ...initialState.formData, ...action.formData },
      };
    }

    case ANALYSIS_FORM_CHANGE: {
      return {
        ...state,
        formData: { ...state.formData, [action.field]: action.value },
      };
    }

    case RESET_FILTERS: {
      return {
        ...state,
        selectedFilterKey: undefined,
      };
    }

    default:
      return state;
  }
}
