import { getUserName } from '../../utils';
import { readFromLocalStorage, writeToLocalStorage } from '../../utils/dom';

const ENTERED_RESULTS_LOCAL_STORAGE_KEY = 'competition/enteredResultsByMatchId';

export function enteredResultsByMatchIdFromLocalStorage() {
  function warn(message, data = {}) {
    console.warn(
      'enteredResultsByMatchIdFromLocalStorage: Failed to read from localStorage.',
      message,
      { localStorageKey: ENTERED_RESULTS_LOCAL_STORAGE_KEY, ...data },
    );
  }

  const data = readFromLocalStorage(ENTERED_RESULTS_LOCAL_STORAGE_KEY);

  if (data == null) {
    return {};
  }

  function assertString(value, key) {
    if (typeof value !== 'string') {
      warn('Expected string.', { data, key, value });
      return '';
    }
    return value;
  }

  const type = Object.prototype.toString.call(data);
  const expectedType = '[object Object]';
  if (type !== expectedType) {
    warn('Wrong type.', { data, type, expectedType });
    return {};
  }

  return Object.entries(data).reduce((result, [key, value]) => {
    const { home, away } = value || {};
    result[key] = {
      home: {
        value: assertString(home, key),
        touched: false,
      },
      away: {
        value: assertString(away, key),
        touched: false,
      },
    };
    return result;
  }, {});
}

export function enteredResultsByMatchIdToLocalStorage(enteredResultsByMatchId) {
  const data = Object.entries(enteredResultsByMatchId).reduce(
    (result, [matchId, { home, away }]) => {
      result[matchId] = { home: home.value, away: away.value };
      return result;
    },
    {},
  );

  writeToLocalStorage(ENTERED_RESULTS_LOCAL_STORAGE_KEY, data);
}

export function makeOptimisticCoupon(
  competition,
  enteredResultsByMatchId,
  user,
) {
  const matches = Object.values(competition.matches);

  return {
    points: 0,
    user_name: getUserName(user),
    user_id: user.id,
    competition: {
      ...competition,
      matches: undefined,
    },
    data: matches.map(match => {
      const results = enteredResultsByMatchId[match.id];
      return {
        home: results == null ? undefined : results.home.value,
        away: results == null ? undefined : results.away.value,
        points: null,
        match,
      };
    }),
  };
}
