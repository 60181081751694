import PropTypes from 'prop-types';

import { classList } from '../../utils';
import LinkButton from '../LinkButton';
import styles from './styles.scss';

PrivacyLinks.propTypes = {
  isHomePage: PropTypes.bool.isRequired,
};

export default function PrivacyLinks({ isHomePage }) {
  return (
    <div
      className={classList({
        [styles.root]: true,
        [styles.centered]: !isHomePage,
      })}
    >
      <div className={styles.linkContainer}>
        <p>
          <a
            href="https://www.trmedia.se/integritetspolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Integritetspolicy
          </a>
        </p>
        <div className={styles.divider} />
        <p>
          <a
            href="https://www.trmedia.se/cookiepolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookiepolicy
          </a>
        </p>
        <div className={styles.divider} />
        <p>
          <LinkButton
            bold={false}
            onClick={() => {
              window.OneTrust && window.OneTrust.ToggleInfoDisplay();
            }}
          >
            Cookie-inställningar
          </LinkButton>
        </p>
      </div>
    </div>
  );
}
