import {
  genericFetchInitialState,
  genericFetchKeyedReducer,
  genericFetchMoreReducer,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  FETCH_AUTHORS,
  FETCH_BLOG_POSTS_BY_AUTHOR_ID,
  FETCH_MORE_BLOG_POSTS,
} from './actions';

const initialState = {
  authors: genericFetchInitialState,
  blogPostsById: {},
};

export default function blogReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AUTHORS:
      return {
        ...state,
        authors: genericFetchReducer(state.authors, action.wrappedAction),
      };

    case FETCH_BLOG_POSTS_BY_AUTHOR_ID:
      return genericFetchKeyedReducer(
        state,
        'blogPostsById',
        action.id,
        action.wrappedAction,
      );

    case FETCH_MORE_BLOG_POSTS: {
      return {
        ...state,
        blogPostsById: {
          ...state.blogPostsById,
          [action.id]: genericFetchMoreReducer(
            state.blogPostsById[action.id],
            action.wrappedAction,
          ),
        },
      };
    }

    default:
      return state;
  }
}
