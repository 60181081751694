import PropTypes from 'prop-types';
import {
  cloneElement,
  createContext,
  useCallback,
  useRef,
  useState,
} from 'react';
import Link from 'redux-first-router-link';

import { classList, loginUrl, registerUrl } from '../../utils';
import Chevron from '../Chevron';
import EventListener from '../EventListener';
import { SPACER } from '../TabBar';
import styles from './styles.scss';
import UserItem from './UserItems';

export const NavOpenContext = createContext(false);

const NavDropdown = ({
  alignRight = false,
  tabNode,
  items,
  innerDropdown = false,
  user = {},
  closeParent,
}) => {
  const [open, setOpen] = useState(false);
  const containerElement = useRef();

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const close = useCallback(() => {
    setOpen(open === false);
  }, [open]);

  const closeIfOutside = useCallback(
    event => {
      if (
        containerElement != null &&
        event.target instanceof window.HTMLElement &&
        !containerElement.current.contains(event.target)
      ) {
        close();
      }
    },
    [close],
  );

  const renderItem = useCallback(
    (item, innerDropdown, closeParent) => {
      if (item.noLink) {
        return (
          <div className={`${styles.dropdownLink} ${styles.defaultCursor}`}>
            {item.children}
          </div>
        );
      }

      if (item === SPACER) {
        return null;
      }
      if (!item.children) {
        return;
      }
      if (item.items) {
        return (
          <NavDropdown
            alignRight={false}
            closeParent={close.bind(this)}
            tabNode={
              <div className={styles.dropdownLink}>
                {item.children}
                <Chevron direction="down" className={styles.icon} />
              </div>
            }
            items={item.items}
            innerDropdown
          />
        );
      }

      if (item.href != null) {
        return (
          <a
            href={item.href}
            rel="noopener noreferrer"
            className={styles.dropdownLink}
            onClick={close.bind(this)}
          >
            {item.children}
          </a>
        );
      }
      if (item.onClick != null) {
        return (
          <button
            type="button"
            className={styles.dropdownLink}
            onClick={() => {
              close();
              item.onClick();
            }}
          >
            {item.children}
          </button>
        );
      }
      return (
        <Link
          to={item.to}
          className={styles.dropdownLink}
          onClick={innerDropdown ? closeParent : close.bind(this)}
        >
          {item.children}
        </Link>
      );
    },
    [close],
  );

  return (
    <NavOpenContext.Provider value={open}>
      <div
        className={classList({
          [styles.innerdropdown]: innerDropdown,
          [styles.dropdown]: !innerDropdown,
          [styles.open]: open,
        })}
        ref={containerElement}
      >
        {cloneElement(tabNode, {
          open,
          onClick: () => {
            toggle();
          },
        })}

        {open && (
          <>
            <EventListener
              eventName="focus"
              listener={closeIfOutside.bind(this)}
            />

            <EventListener
              eventName="click"
              listener={closeIfOutside.bind(this)}
            />

            <EventListener
              eventName="keydown"
              listener={event => {
                if (event.key === 'Escape') {
                  close();
                }
              }}
            />
            <ul
              className={classList({
                [innerDropdown
                  ? styles.innerdropdownMenu
                  : styles.dropdownMenu]: true,
                [styles.dropdownMenuRight]: alignRight,
              })}
            >
              {items.map((item, index) => {
                const renderedItem = renderItem(
                  item,
                  innerDropdown,
                  closeParent,
                );
                return (
                  <li
                    key={index}
                    role={renderedItem == null ? 'separator' : undefined}
                    className={item.className ?? styles.dropdownItemWrapper}
                  >
                    {renderedItem}
                  </li>
                );
              })}
              {!innerDropdown && !user.data && (
                <div className={styles.mobileLogin}>
                  <a
                    href={registerUrl()}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.registerButton}
                    onClick={close.bind(this)}
                  >
                    Registrera dig
                  </a>
                  <a
                    href={loginUrl()}
                    rel="noopener noreferrer"
                    className={styles.loginButton}
                    onClick={close.bind(this)}
                  >
                    <UserItem
                      title={user.fetching ? 'Loggar in…' : 'Logga in'}
                      loading={user.fetching}
                      className={styles.login}
                    />
                  </a>
                </div>
              )}
            </ul>
          </>
        )}
      </div>
    </NavOpenContext.Provider>
  );
};

NavDropdown.propTypes = {
  alignRight: PropTypes.bool,
  tabNode: PropTypes.node.isRequired,
  innerDropdown: PropTypes.bool,
  closeParent: PropTypes.func,
  user: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
      }).isRequired,
      PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
      }).isRequired,
      PropTypes.shape({
        to: PropTypes.shape({ type: PropTypes.string.isRequired }),
        children: PropTypes.node.isRequired,
      }).isRequired,
      PropTypes.oneOf([SPACER]).isRequired,
    ]).isRequired,
  ).isRequired,
};

export default NavDropdown;
