import { DEFAULT } from '../../api';
import {
  genericFetchInitialState,
  genericFetchMoreReducer,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  FETCH_MORE_TEAMS_LIST,
  FETCH_POPULAR_TEAMS,
  FETCH_POPULAR_TOURNAMENTS,
  FETCH_TEAM1_STATISTICS,
  FETCH_TEAM2_STATISTICS,
  FETCH_TEAMS_LIST,
  FILTER_CHANGE,
  QUERY_CHANGE,
  TOGGLE_OVER_UNDER,
} from './actions';

const initialState = {
  query: '',
  popularTeams: genericFetchInitialState,
  popularTournaments: genericFetchInitialState,
  teamsList: genericFetchInitialState,
  team1Statistics: genericFetchInitialState,
  team2Statistics: genericFetchInitialState,
  expandedOverUnders: {},
  filters: {
    half: DEFAULT,
    max_matches: DEFAULT,
    odds_span: DEFAULT,
    only: DEFAULT,
    tournament_id: DEFAULT,
  },
};

export default function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_POPULAR_TEAMS:
      return {
        ...state,
        popularTeams: genericFetchReducer(
          state.popularTeams,
          action.wrappedAction,
        ),
      };

    case FETCH_POPULAR_TOURNAMENTS:
      return {
        ...state,
        popularTournaments: genericFetchReducer(
          state.popularTournaments,
          action.wrappedAction,
        ),
      };

    case FETCH_TEAMS_LIST:
      return {
        ...state,
        teamsList: genericFetchReducer(state.teamsList, action.wrappedAction),
      };

    case FETCH_MORE_TEAMS_LIST:
      return {
        ...state,
        teamsList: genericFetchMoreReducer(
          state.teamsList,
          action.wrappedAction,
        ),
      };

    case FETCH_TEAM1_STATISTICS:
      return {
        ...state,
        team1Statistics: genericFetchReducer(
          state.team1Statistics,
          action.wrappedAction,
        ),
      };

    case FETCH_TEAM2_STATISTICS:
      return {
        ...state,
        team2Statistics: genericFetchReducer(
          state.team2Statistics,
          action.wrappedAction,
        ),
      };

    case FILTER_CHANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };

    case TOGGLE_OVER_UNDER: {
      const { [action.id]: expanded = false } = state.expandedOverUnders;
      return {
        ...state,
        expandedOverUnders: {
          ...state.expandedOverUnders,
          [action.id]: !expanded,
        },
      };
    }

    case QUERY_CHANGE:
      return {
        ...state,
        query: action.query,
      };

    default:
      return state;
  }
}
