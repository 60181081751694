import { formatMoney } from '@trmediaab/zebra-utils';
import millify from 'millify';

import { NONE } from '../api';
import europatipset from '../images/europatipset.svg';
import powerplay from '../images/powerplay.svg';
import stryktipset from '../images/stryktipset.svg';
import topptipset from '../images/topptipset.svg';
import config from '../main/config';

const TOPPTIPSET_STRYK = 'topptipsetstryk';
const TOPPTIPSET_EUROPA = 'topptipseteuropa';
const reToppExtra = /(^Topptips Extra)/;
const reToppEuropa = /(^Topptips Europa)/;

export const GAME_TYPE_V75 = 'v75';
export const GAME_TYPE_GS75 = 'gs75';
export const GAME_TYPE_V64 = 'v64';
export const GAME_TYPE_V86 = 'v86';
export const GAME_TYPE_BIG9 = 'big9';
export const GAME_TYPE_STRYKTIPSET = 'stryktipset';
export const GAME_TYPE_EUROPATIPSET = 'europatipset';
export const GAME_TYPE_TOPPTIPSET = 'topptipset';
export const GAME_TYPE_POWERPLAY = 'powerplay';
export const GAME_TYPES = [
  GAME_TYPE_STRYKTIPSET,
  GAME_TYPE_EUROPATIPSET,
  GAME_TYPE_TOPPTIPSET,
  config.POWERPLAY_ENABLED && GAME_TYPE_POWERPLAY,
].filter(Boolean);

const analysisImageMap = {
  europatipset: { icon: europatipset },
  powerplay: { icon: powerplay },
  stryktipset: { icon: stryktipset },
  topptipset: { icon: topptipset },
};

const MILLION = 1000000;

export const getProductDisplayName = name => {
  if (reToppExtra.test(name)) {
    return name.replace('Extra', '');
  } else if (reToppEuropa.test(name)) {
    return name.replace('Europa', '');
  }
  return name;
};

export const getGameType = product => {
  if (product === TOPPTIPSET_STRYK || product === TOPPTIPSET_EUROPA) {
    return GAME_TYPE_TOPPTIPSET;
  }
  return product;
};

export function gameTitle(gameType) {
  switch (gameType) {
    case GAME_TYPE_STRYKTIPSET:
      return 'Stryktipset';
    case GAME_TYPE_EUROPATIPSET:
      return 'Europatipset';
    case GAME_TYPE_TOPPTIPSET:
      return 'Topptipset';
    case GAME_TYPE_POWERPLAY:
      return 'Powerplay';
    default:
      return 'Okänt spel';
  }
}

export const getGameTypeImage = gameType => analysisImageMap[gameType];

export const getSortedJackpotRounds = rounds =>
  rounds
    .filter(round => round !== NONE && round.jackpot != null)
    .sort(
      (a, b) =>
        new Date(a.close_time || a.start_time) -
        new Date(b.close_time || b.start_time),
    );

export const formatJackpot = jackpot =>
  `${
    jackpot < MILLION
      ? `${formatMoney(jackpot * 100, true)}`
      : `${millify(jackpot, {
          units: [null, null, 'milj kr'],
          space: true,
          precision: 1,
          decimalSeparator: ',',
        })}`
  } extra i potten`;

export const getAtgRoundLink = ({
  round_date: roundDate,
  bet_type: betType,
  tracks_slug: tracksSlug,
}) => `https://www.atg.se/spel/${roundDate}/${betType}/${tracksSlug}`;
