import { call, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { HOME } from '../../main/actions/router';
import config from '../../main/config';
import { fetchGeneric, onlyIfMissing } from '../../main/sagas/genericFetch';
import {
  fetchAllRoundsSaga,
  fetchATGJackpotsSaga,
  fetchLatestRoundsSaga,
} from '../../main/sagas/models';
import {
  fetchBestMatch,
  fetchBlogPosts,
  fetchCarouselSlides,
  fetchGoldenBetTip,
  fetchMonthTopUser,
  fetchNews,
  fetchSvenskaSpelWidget,
  fetchTopStreak,
} from './actions';
import {
  bestMatchSelector,
  blogPostsSelector,
  carouselSlidesSelector,
  goldenBetTipSelector,
  newsSelector,
  svenskaSpelWidgetSelector,
  topMonthUserSelector,
  topStreakSelector,
} from './selectors';

const NUM_BLOG_POSTS = 12;
const NUM_BET_TIP_OVERODDS_EXPERTER = 1;

export default function* rootSaga() {
  yield takeLatest(HOME, fetchBlogPostsSaga);
  yield takeLatest(HOME, fetchLatestRoundsSaga);
  yield takeLatest(HOME, fetchATGJackpotsSaga);
  yield takeLatest(HOME, fetchAllRoundsSaga);
  yield takeLatest(HOME, fetchCarouselSlidesSaga);
  yield takeLatest(HOME, fetchNewsSaga);
  yield takeLatest(HOME, fetchGoldenBetTipSaga);
  yield takeLatest(HOME, fetchSvenskSpelWidgetSaga);
  yield takeLatest(HOME, fetchMonthTopUserSaga);
  yield takeLatest(HOME, fetchStreakSaga);
  yield takeLatest(HOME, fetchBestMatchSaga);
}

export function* fetchBlogPostsSaga(action) {
  yield fetchGeneric(
    fetchBlogPosts,
    onlyIfMissing(blogPostsSelector),
    call(api.latestContent, {
      type: api.CONTENT_TYPES.BLOG,
      limit: NUM_BLOG_POSTS,
    }),
    action,
  );
}

export function* fetchGoldenBetTipSaga(action) {
  yield fetchGeneric(
    fetchGoldenBetTip,
    onlyIfMissing(goldenBetTipSelector),
    call(api.getTipsterBetTips, {
      tipster: config.OVERODDS_EXPERTER_SLUG,
      limit: NUM_BET_TIP_OVERODDS_EXPERTER,
      live: true,
    }),
    action,
  );
}

export function* fetchCarouselSlidesSaga(action) {
  yield fetchGeneric(
    fetchCarouselSlides,
    onlyIfMissing(carouselSlidesSelector),
    call(api.latestContent, {
      type: api.CONTENT_TYPES.CAROUSEL_TEASER,
    }),
    action,
  );
}

export function* fetchNewsSaga(action) {
  yield fetchGeneric(
    fetchNews,
    onlyIfMissing(newsSelector),
    call(api.latestContent, {
      type: api.CONTENT_TYPES.NEWS_TICKER,
    }),
    action,
  );
}

export function* fetchSvenskSpelWidgetSaga(action) {
  yield fetchGeneric(
    fetchSvenskaSpelWidget,
    onlyIfMissing(svenskaSpelWidgetSelector),
    call(api.getSvenskaSpelWidget),
    action,
  );
}

export function* fetchMonthTopUserSaga(action) {
  yield fetchGeneric(
    fetchMonthTopUser,
    onlyIfMissing(topMonthUserSelector),
    call(api.getTipstersTopList, {
      listPath: api.CONTENT_TYPES.TOP_USER_MONTH,
    }),
    action,
  );
}

export function* fetchStreakSaga(action) {
  yield fetchGeneric(
    fetchTopStreak,
    onlyIfMissing(topStreakSelector),
    call(api.getTipstersTopList, {
      listPath: api.CONTENT_TYPES.TOP_STREAK,
    }),
    action,
  );
}

export function* fetchBestMatchSaga(action) {
  yield fetchGeneric(
    fetchBestMatch,
    onlyIfMissing(bestMatchSelector),
    call(api.getBestMatch),
    action,
  );
}
