import { parseISO } from 'date-fns';

import { formatDate, unslugify } from '.';

const SIMILAR_ODDS_RADIUS = 0.05; // percentage units

const MATCH_SLUG_REGEX = /^[\s-]*([\S\s]*?)(\d+-\d+-\d+)?[\s-]*$/;

const SURFACE_NAMES = {
  artificial: 'konstgräs',
  grass: 'riktigt gräs',
};

const PROVIDER_NAMES = {
  atg: 'ATG',
  svenskaspel: 'Svenska Spel Sport & Casino AB',
  ubse: 'Unibet',
};

export function addOddsPercentageUnits(units, oddsValue) {
  // `1 / odds` gives the probability of the odds.
  // If removing unitis so that the probability becomes 0 or lower, `Infinity`
  // is returned. So this function can divide by 0 on purpose.
  return 1 / Math.max(0, 1 / oddsValue + units);
}

export function calculateGamePrice(choices) {
  const nonZero = choices
    .map(({ home, draw, away }) => [home, draw, away].filter(Boolean).length)
    .filter(numChosen => numChosen > 0);

  return nonZero.length === 0
    ? 0
    : nonZero.reduce((product, numChosen) => product * numChosen, 1);
}

export function joinPlayedGameChoices(choices) {
  return choices
    .map(({ home, draw, away }) =>
      [home ? '1' : '', draw ? 'X' : '', away ? '2' : ''].join(''),
    )
    .join(',');
}

export function parseRoundSystemData(data) {
  return typeof data === 'string'
    ? data.split(',').map(value => parseGameSegment(value))
    : [];
}

export function parseGameSegment(value) {
  return {
    home: value.includes('1'),
    draw: value.includes('X'),
    away: value.includes('2'),
  };
}

export function concatMatches(matchesA, matchesB) {
  return (
    [
      ...matchesA,
      ...matchesB.filter(matchB =>
        matchesA.every(matchA => matchA.id !== matchB.id),
      ),
    ]
      // Make sure that the new list is sorted like A and B are when coming from
      // the API (newest first).
      .sort(
        (matchA, matchB) =>
          new Date(matchA.publish_start).getTime() -
            new Date(matchB.publish_start).getTime() ||
          matchA.slug.localeCompare(matchB.slug),
      )
  );
}

export function countByWinner(matches, fn) {
  return matches.filter(match => fn(match.winner)).length;
}

export function filterByHomeTeam(team, matches) {
  return matches.filter(match => match.home_team.id === team.id);
}

export function filterByAwayTeam(team, matches) {
  return matches.filter(match => match.away_team.id === team.id);
}

export function filterByCoach(coach, property, matches) {
  return matches.filter(match => {
    const matchCoach = match[property];
    return matchCoach != null && matchCoach.id === coach.id;
  });
}

export function filterBySimilarOdds(team, oddsValue, matches) {
  const lower = addOddsPercentageUnits(SIMILAR_ODDS_RADIUS, oddsValue);
  const upper = addOddsPercentageUnits(-SIMILAR_ODDS_RADIUS, oddsValue);

  return matches.filter(match => {
    const odds = getCommonOdds(match);

    const value =
      match.home_team.id === team.id
        ? odds.home
        : match.away_team.id === team.id
        ? odds.away
        : undefined;

    return value != null && value >= lower && value <= upper;
  });
}

export function filterBySurface(surface, matches) {
  return matches.filter(match => match.surface === surface);
}

export function filterByTeams(teamA, teamB, matches) {
  return matches.filter(
    match =>
      (match.home_team.id === teamA.id && match.away_team.id === teamB.id) ||
      (match.home_team.id === teamB.id && match.away_team.id === teamA.id),
  );
}

export function filterByTeamsExact(homeTeam, awayTeam, matches) {
  return matches.filter(
    match =>
      match.home_team.id === homeTeam.id && match.away_team.id === awayTeam.id,
  );
}

export function filterByYear(matches, yearFrom, yearTo) {
  return matches.filter(
    match =>
      new Date(match.season.start).getFullYear() >= yearFrom &&
      new Date(match.season.end).getFullYear() <= yearTo,
  );
}

/**
 * Format an odds number for display. Always display two decimals. Use comma as
 * decimal separator.
 *
 * Alternatively, treat it as a percentage. No decimals.
 */
export function formatOdds(value, { percentage } = {}) {
  return percentage === 'decimalComputed'
    ? `${Math.round(value * 100)}%`
    : percentage === 'integer'
    ? `${value}%`
    : value.toFixed(2).replace('.', ',');
}

export function getCommonOdds(match) {
  return match.odds == null ||
    match.odds['1X2'] == null ||
    match.odds['1X2'].svenskaspel == null
    ? {}
    : match.odds['1X2'].svenskaspel;
}

export function getSurfaceName(surface) {
  const name = SURFACE_NAMES[surface];
  return name == null ? surface : name;
}

export function getProviderName(provider) {
  const name = PROVIDER_NAMES[provider];
  return name == null ? provider : name;
}

export function getYears(matches) {
  return [
    ...new Set(matches.map(match => new Date(match.start_time).getFullYear())),
  ].sort((a, b) => a - b);
}

/**
 * Turns a match object into for example "Chelsea – Manchester United".
 * Optionally show the date of the match.
 */
export function matchTitle(match, { showDate = false } = {}) {
  const date = formatDate(parseISO(match.start_time), 'yyyy-MM-dd');
  return showDate ? `${match.name} ${date}` : match.name;
}

/**
 * Attempts to do the same as `matchTitle` but with only a slug as input.
 */
export function matchTitleFromSlug(slug, { showDate = false } = {}) {
  const match = MATCH_SLUG_REGEX.exec(slug);

  if (match == null) {
    return slug;
  }

  const [, teams, date] = match;

  const teamWords = unslugify(teams, { uppercase: 'smart', asArray: true });

  const middle = Math.floor(teamWords.length / 2);

  const start =
    teamWords.length >= 2
      ? [...teamWords.slice(0, middle), '–', ...teamWords.slice(middle)].join(
          ' ',
        )
      : teamWords.join(' ');

  return !showDate || date == null ? start : `${start} ${date}`;
}

/**
 * The secret formula to turn three odds numbers into percentages. Don’t ask me
 * how it works. `f(a) + f(b) + f(c) === 1` is supposed to hold true.
 */
export function oddsToPercentages(odds) {
  const { home: a, draw: b, away: c } = odds;

  function f(v) {
    return (a * b * c) / (v * (a * b + a * c + b * c));
  }

  return {
    ...odds,
    home: f(a),
    draw: f(b),
    away: f(c),
  };
}

/**
 * Some tournaments exist in several countries and have the same name. Add the
 * country to their names to tell them apart.
 */
export function uniqueTournamentNames(tournaments) {
  return tournaments.map(tournament => {
    const hasDuplicateName = tournaments.some(
      tournament2 =>
        tournament2 !== tournament && tournament2.name === tournament.name,
    );
    return hasDuplicateName
      ? {
          ...tournament,
          name: `${tournament.name} (${tournament.country})`,
        }
      : tournament;
  });
}
