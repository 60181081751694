import { createSelector } from 'reselect';

import { locationSelector } from '../../main/selectors';

export const bettingSchoolSelector = state => state.bettingSchool;

export const schoolTypeSelector = createSelector(
  locationSelector,
  location => location.payload.id,
);

export const schoolPostSelector = createSelector(
  bettingSchoolSelector,
  school => school.byId,
);

export const schoolTipsterSelector = state =>
  state.bettingSchool.schoolTipsters;
