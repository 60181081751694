import { call, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { SHARED_BETS } from '../../main/actions/router';
import { fetchGeneric, onlyIfMissing } from '../../main/sagas/genericFetch';
import { fetchSharedBets } from './actions';
import { sharedBetsSelector } from './selectors';

export default function* rootSaga() {
  yield takeLatest(SHARED_BETS, fetchSharedBetsSaga);
}

export function* fetchSharedBetsSaga(action) {
  yield fetchGeneric(
    fetchSharedBets,
    onlyIfMissing(sharedBetsSelector),
    call(api.getSharedBets),
    action,
  );
}
