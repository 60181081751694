import { takeLatest } from 'redux-saga/effects';

import { BESTGAME_EMBED } from '../../../main/actions/router';
import {
  fetchBestMatchSaga,
  fetchMonthTopUserSaga,
  fetchStreakSaga,
} from '../../home/sagas';

export default function* rootSaga() {
  yield takeLatest(BESTGAME_EMBED, fetchMonthTopUserSaga);
  yield takeLatest(BESTGAME_EMBED, fetchStreakSaga);
  yield takeLatest(BESTGAME_EMBED, fetchBestMatchSaga);
}
