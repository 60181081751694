import { DEFAULT } from '../../../api';
import {
  genericFetchInitialState,
  genericFetchReducer,
} from '../../../main/reducers/genericFetch';
import { FETCH_SEASON_STANDINGS, FILTER_CHANGE } from './actions';

const initialState = {
  filters: {
    homeAway: undefined,
    numMatches: DEFAULT,
  },
  standings: genericFetchInitialState,
};

export default function tableReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SEASON_STANDINGS:
      return {
        ...state,
        standings: genericFetchReducer(state.standings, action.wrappedAction),
      };

    case FILTER_CHANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      };

    default:
      return state;
  }
}
