//All syndicates
export const syndicatesSelector = state => state.playTogether.syndicates;

//User syndicates
export const userSyndciatesSelector = state =>
  state.playTogether.userSyndicates;

//User created or updated syndicate
export const createdSyndicateSelector = state =>
  state.playTogether.createdSyndicate;

export const createdSyndicateErrorSelector = state =>
  state.playTogether.createdSyndicate.error?.responseText;

export const createdSyndicateLoadingSelector = state =>
  state.playTogether.createdSyndicate.fetching;

//User deleted syndicate
export const deletedSyndicateSelector = state =>
  state.playTogether.deletedSyndicate;
