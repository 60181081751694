// Global CSS variables have to be defined in this very file (rather than in
// src/main/css/globals.js) in order not to have to restart webpack-dev-server
// every time you change any of them.

// Grey colors are named after Star Wars characters because naming is hard.
const greyLeia = '#e7edee';
const greyYoda = '#f8f8f8';
const greyHanSolo = '#fafafa';
const greyLuke = '#cfdadd';
const greyLando = '#b6c8cb';
const greyBoba = '#6b8287';
const greyBrownLeia = '#d1d1d1';
const greyBrownBoba = '#999999';
const greyBrownVader = '#707070';
const greyDarkVader = '#333333';

// "Colorful" colors.
const blue = '#36a9e1';
const blueDark = '#014076';
const green = '#86c06a';
const greenDark = '#008733';
const greenBright = '#48d48d';
const red = '#f46483';
const redError = '#d95353';
const yellow = '#ffd189';
const yellowHighlight = '#fceb85';
const purple = '#a489ff';
const redOrange = '#e27469';
const golden = 'linear-gradient(90deg, #f7e379 0%, #ebc344 100%)';
const gold = '#ebc344';

// Poolgame colors.
const bomben = '#a00813';
const europatipset = '#0b8734';
const powerplay = '#a00813';
const stryktipset = '#00427a';
const topptipset = '#f29400';
const fulltraff = '#70189f';

//ATG colors.
const v75 = '#044F9F';
const v86 = '#802B7E';
const gs75 = '#15614F';
const v64 = '#ED6C15';
const big9 = '#044F9F';

// The base unit for basically all spacing. Lots of padding and space between
// stuff is made up of multiples of this. This is divided by two on supporting
// phones.
const spacing = 15;

// Breakpoints.
const breakpoints = {
  phonePortrait: 0,
  phoneLandscape: 475,
  tabletPortrait: 750,
  tabletLandscape: 1000,
  desktop: 1190,

  navHorizontal: 1225,
};

const skyscraperAdWidth = 250;

const globals = {
  // Grey colors.
  'g-grey-leia': greyLeia,
  'g-grey-han-solo': greyHanSolo,
  'g-grey-luke': greyLuke,
  'g-grey-lando': greyLando,
  'g-grey-boba': greyBoba,
  'g-grey-brown-leia': greyBrownLeia,
  'g-grey-brown-boba': greyBrownBoba,
  'g-grey-brown-vader': greyBrownVader,
  'g-grey-dark-vader': greyDarkVader,
  'g-input-disabled-color': greyHanSolo,
  // "Colorful" colors.
  'g-blue': blue,
  'g-blue-dark': blueDark,
  'g-green': green,
  'g-green-dark': greenDark,
  'g-green-bright': greenBright,
  'g-red': red,
  'g-yellow': yellow,
  'g-yellow-highlight': yellowHighlight,
  'g-purple': purple,
  'g-red-error': redError,
  'g-red-orange': redOrange,
  'g-golden': golden,
  'g-gold': gold,
  //Poolgame colors.
  'g-bomben': bomben,
  'g-europatipset': europatipset,
  'g-powerplay': powerplay,
  'g-stryktipset': stryktipset,
  'g-topptipset': topptipset,
  'g-fulltraff': fulltraff,
  //ATG colors
  'g-big9': big9,
  'g-v75': v75,
  'g-v86': v86,
  'g-gs75': gs75,
  'g-v64': v64,

  // Common usage for some colors.
  'g-text-primary-color': 'black',
  'g-text-secondary-color': greyBrownBoba,
  'g-border-color': greyBrownLeia,
  'g-page-background-color': greyLeia,
  'g-grey-background-color': greyYoda,
  'g-link-color': blue,
  'g-link-hover-color': blue,
  'g-link-hover-decoration': 'underline',

  // Font stuff.
  'g-font-size': '14px',
  'g-line-height': '1.5',
  'g-font-family': [
    'Montserrat',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ].join(', '),

  // The font weights used.
  'g-normal': '400',
  'g-medium': '500',
  'g-bold': '700',
  'g-extrabold': '800',

  // All major `z-index` values.
  'g-z-index-SidebarLayout-sidebar': 8000,
  'g-z-index-SidebarLayout-sidebarToggle': 8100,
  'g-z-index-Nav': 9000,
  'g-z-index-Modal': 10000,

  // Measurements that need to be shared between several components. They are
  // named after the component that “owns” it (but other components can use it).
  // "g-Modal_padding": "40px",

  // These values unfortunately depends on CommonTable styling and need to be
  // manually updated to be kept in sync.
  '$g-CommonTable_row-height': '40px',
  '$g-CommonTable_heading-row-height': '26px',
  '$g-CurrentPageBar_height': '30px',
  '$g-Nav_height-regular': '76px',
  '$g-Spinner_fade-duration': '200ms',
  '$g-Spinner_fade-delay': '200ms',
  'g-CenterLayout-skyscraper-width': `${skyscraperAdWidth}px`,
  'g-CenterLayout-max-with-skyscraper-width': `${
    980 + skyscraperAdWidth + spacing * 2
  }px`,
  'g-CenterLayout-breakpoint-skyscraper': `${
    980 + skyscraperAdWidth + spacing * 6
  }px`,

  // Spacing variables that are automatically divided by 2 on supporting phones.
  'g-spacing-1': makeSpacing(1),
  'g-spacing-2': makeSpacing(2),
  'g-spacing-3': makeSpacing(3),
  'g-spacing-4': makeSpacing(4),

  // The base spacing value as a number.
  'g-spacing': spacing,
  // For the cases where you want `spacing` number of pixels, even on phones.
  'g-spacing-1-always': `${spacing}px`,

  // Breakpoints.
  'g-breakpoint-phone-portrait': breakpoints.phonePortrait,
  'g-breakpoint-phone-landscape': breakpoints.phoneLandscape,
  'g-breakpoint-tablet-portrait': breakpoints.tabletPortrait,
  'g-breakpoint-tablet-landscape': breakpoints.tabletLandscape,
  'g-breakpoint-desktop': breakpoints.desktop,
  'g-breakpoint-nav-horizontal': breakpoints.navHorizontal,

  // Usage example: `@media $g-breakpoint-phone-portrait-and-down {}`.
  'g-breakpoint-phone-portrait-and-down': maxWidth(breakpoints.phoneLandscape),
  'g-breakpoint-phone-landscape-and-down': maxWidth(breakpoints.tabletPortrait),
  'g-breakpoint-tablet-portrait-and-down': maxWidth(
    breakpoints.tabletLandscape,
  ),
  'g-breakpoint-tablet-landscape-and-down': maxWidth(breakpoints.desktop),
  // 'breakpoint-desktop-and-down' and 'breakpoint-phone-portrait-and-up' are
  // omitted here because they don’t make sense: They would be the same as not
  // using a media query at all.
  'g-breakpoint-phone-landscape-and-up': minWidth(breakpoints.phoneLandscape),
  'g-breakpoint-tablet-portrait-and-up': minWidth(breakpoints.tabletPortrait),
  'g-breakpoint-tablet-landscape-and-up': minWidth(breakpoints.tabletLandscape),
  'g-breakpoint-desktop-and-up': minWidth(breakpoints.desktop),

  'g-breakpoint-nav-collapsed-and-down': maxWidth(breakpoints.navHorizontal),
  'g-breakpoint-nav-horizontal-and-up': minWidth(breakpoints.navHorizontal),
};

module.exports = {
  parser: 'postcss-scss',
  plugins: [
    ['postcss-simple-vars', { variables: globals }],
    ['postcss-nested', { preserveEmpty: true }],
    'postcss-custom-properties',
    ['postcss-calc', { preserve: true }],
    'autoprefixer',
  ],
};

function maxWidth(width) {
  return `(max-width: ${width - 1}px)`;
}

function minWidth(width) {
  return `(min-width: ${width}px)`;
}

function makeSpacing(n) {
  // Since the `var()` has the second parameter specified,
  // postcss-custom-properties will generate a fallback for browsers that don’t
  // support custom variables.
  return `calc(${n} * var(--g-spacing, ${spacing}px))`;
}
