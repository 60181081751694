import { encodeUrl } from '@trmediaab/zebra-utils';

import config from '../main/config';
import { zip } from '../utils';
import request from './request';
import { status } from './status';

/**
 * See this page for API documentation, such as available `data` parameters.
 * https://trsport.trdev.se/api/
 */

export const CONTENT_TYPES = {
  DAGENS_OVERODDS: 'dagens_overodds',
  SISTA_MINUTEN: 'sista_minuten',
  ROUND_ANALYSIS: 'round_analysis',
  ROUNDMATCH_ANALYSIS: 'roundmatch_analysis',
  LEAGUE: 'league',
  TEAM: 'team',
  BLOG: 'blog',
  INTERVIEW: 'interview',
  CAROUSEL_TEASER: 'carousel_teaser',
  NEWS_TICKER: 'news_ticker',
  SEASON_ANALYSIS: 'season_analysis',
  GROUP_ANALYSIS: 'group_analysis',
  TEAM_ANALYSIS: 'team_analysis',
  BETTING_SCHOOL: 'betting_school',
  TOP_USER_MONTH: 'best_tipster_last_30_days',
  TOP_STREAK: 'longest_winning_streak',
};

// Both teams scored the same in a match.
export const DRAW = 'draw';

// Used to represent that something is truly missing. `null` and `undefined`
// mean “there’s no result yet” while `NONE` means that there _is_ a result but
// the result is saying that the thing you’re looking for does not exist. For
// example, there may or may not be a `latestRound`.
export const NONE = 'none';

// Many API parameters need to be left out (basically, set to `undefined`) in
// order to get the "default" behavior (such as no filtering). These parameters
// are often mapped to a `<Select/>` (which requires string values for all
// options). Using this value and the `defaultToUndefined` function is a
// convenient way to get around this.
export const DEFAULT = 'default';

export function defaultToUndefined(obj) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    result[key] = value === DEFAULT ? undefined : value;
    return result;
  }, {});
}

export function latestContent(data) {
  return request('GET', `/api/v1/content/latest/`, data);
}

export function getContent({ id }) {
  return request('GET', encodeUrl`/api/v1/content/${id}/`);
}

export function getAuthenticatedTipster() {
  return request('GET', `/api/v1/private/content/tipster/`);
}

export function listMatches(data) {
  return request('GET', `/api/v1/football/matches/`, data);
}

export function getMatch({ id }) {
  return request('GET', encodeUrl`/api/v1/football/matches/${id}/`);
}

export function getBetTips(data = {}) {
  return request('GET', `/api/v1/content/bet_tips/`, data);
}

export function getBetTipsLatest(data = {}) {
  return request('GET', `/api/v1/content/bet_tips/latest/`, data);
}

export async function getYoutubePlaylists() {
  const playlistIds = config.YT_PLAYLIST_IDS.split(',');

  const urls = playlistIds.map(
    id =>
      `${
        'https://www.googleapis.com/youtube/v3/playlistItems' +
        '?part=id,snippet&' +
        '&key='
      }${config.YT_KEY}&maxResults=50&playlistId=${id}`,
  );

  const responses = await Promise.all(urls.map(url => fetch(url)));
  const responseJson = await Promise.all(
    responses.map(response => response.json()),
  );

  return responseJson;
}

export function getBetTip({ analysisId }) {
  return request('GET', encodeUrl`/api/v1/content/bet_tips/${analysisId}/`);
}

export function getPrivateBetTip({ analysisId }) {
  return request(
    'GET',
    encodeUrl`/api/v1/private/content/bet_tips/${analysisId}/`,
  );
}

export function getPrivateBetTips(data = {}) {
  return request('GET', `/api/v1/private/content/bet_tips/`, data);
}

export function getBestMatch() {
  return request('GET', `/api/v1/public/content/best_match/`);
}

export function getEventGroups() {
  return request('GET', `/api/v1/private/sports/groups/`);
}

export function getEventList({ groupId }) {
  return request('GET', `/api/v1/private/sports/events/`, {
    group_id: groupId,
  });
}

export function getEvent({ eventId }) {
  return request('GET', encodeUrl`/api/v1/private/sports/events/${eventId}/`);
}

export function getMatchEvents({ id }) {
  return request('GET', encodeUrl`/api/v1/football/matches/${id}/events/`);
}

export function getAuthors() {
  return request('GET', `/api/v1/content/authors/`);
}

export function getMatchLineups({ id }) {
  return request(
    'GET',
    encodeUrl`/api/v1/football/matches/${id}/lineups/`,
  ).then(response => ({
    home: fixMissingPlayers(response.home),
    away: fixMissingPlayers(response.away),
  }));
}

export function getMatchStatistics({ id }) {
  return request('GET', encodeUrl`/api/v1/football/matches/${id}/statistics/`);
}

function fixMissingPlayers(lineup) {
  return {
    ...lineup,
    missing_players: lineup.missing_players.map(({ player, type }) => ({
      ...player,
      missing_reason: type,
    })),
  };
}

export function listTeams(data) {
  return request('GET', `/api/v1/football/teams/`, data);
}

export function getTeam({ id }) {
  return request('GET', encodeUrl`/api/v1/football/teams/${id}/`);
}

export function listTeamComingMatches({ id }, data) {
  return request(
    'GET',
    encodeUrl`/api/v1/football/teams/${id}/coming_matches/`,
    data,
  );
}

export function getTeamStatistics({ id }, data) {
  return request(
    'GET',
    encodeUrl`/api/v1/football/teams/${id}/statistics/`,
    data,
    {
      silencedStatusCodes: [status.HTTP_404_NOT_FOUND],
    },
  ).then(
    response => ({
      // Make the response more useful. This intentionally leaves out some
      // useless things.
      matches: zip(response.matches, response.summary.detail).map(
        ([match, sign]) => ({
          ...match,
          winner: getWinner(id, match, sign),
        }),
      ),
    }),
    error =>
      error.status === status.HTTP_404_NOT_FOUND
        ? { matches: [] }
        : Promise.reject(error),
  );
}

export function getTeamFilterStatistics({ id }, data) {
  return request(
    'GET',
    encodeUrl`/api/v1/football/teams/${id}/filter_statistics/`,
    data,
  );
}

export function getTeamStatisticsVersus({ id, opponentId }, data) {
  return request(
    'GET',
    encodeUrl`/api/v1/football/teams/${id}/versus/${opponentId}/`,
    data,
  );
}

export function listTournaments(data) {
  return request('GET', `/api/v1/football/tournaments/`, data);
}

export function getTournament({ id }) {
  return request('GET', encodeUrl`/api/v1/football/tournaments/${id}/`);
}

export function listTournamentSeasons({ tournamentId }, data) {
  return request(
    'GET',
    encodeUrl`/api/v1/football/tournaments/${tournamentId}/seasons/`,
    data,
  );
}

export function getTournamentSeason({ id, tournamentId }, data) {
  return request(
    'GET',
    encodeUrl`/api/v1/football/tournaments/${tournamentId}/seasons/${id}/`,
    data,
  );
}

export function getTournamentSeasonStandings(
  { id, tournamentId, groupId },
  data,
) {
  const escapedUrl =
    groupId != null
      ? encodeUrl`/api/v1/football/tournaments/${tournamentId}/seasons/${id}/groups/${groupId}/standings/`
      : encodeUrl`/api/v1/football/tournaments/${tournamentId}/seasons/${id}/standings/`;
  return request('GET', escapedUrl, data, {
    silencedStatusCodes: [status.HTTP_404_NOT_FOUND],
  }).catch(error =>
    error.status === status.HTTP_404_NOT_FOUND ? [] : Promise.reject(error),
  );
}

export function listRounds({ type }, data) {
  return request('GET', encodeUrl`/api/v1/svenskaspel/${type}/rounds/`, data);
}

export function latestRound() {
  return request('GET', `/api/v1/svenskaspel/rounds/current`);
}

export function getRound({ type, id }) {
  const round = id || 'latest';
  return (
    request('GET', encodeUrl`/api/v1/svenskaspel/${type}/rounds/${round}/`)
      // With latest the API returns an array with 0 or 1 items. Turn that into the one item or
      // `NONE`.
      .then(response =>
        Array.isArray(response)
          ? response.length > 0
            ? response[0]
            : NONE
          : response,
      )
  );
}

export function getATGJackpots() {
  return request(
    'GET',
    `/api/v1/public/round/jackpots/`,
    undefined,
    undefined,
    undefined,
    'trais',
  );
}

export function getRoundMatch({ roundMatchId }) {
  return request(
    'GET',
    encodeUrl`/api/v1/svenskaspel/round_matches/${roundMatchId}/`,
  );
}

export function postRoundWager({ type, id }, data) {
  return request(
    'POST',
    encodeUrl`/api/v1/svenskaspel/${type}/rounds/${id}/wager/`,
    data,
  );
}

export function createBetTip(data) {
  return request('POST', `/api/v1/private/content/bet_tips/`, data);
}

export function deleteBetTip(id) {
  return request('DELETE', encodeUrl`/api/v1/private/content/bet_tips/${id}`);
}

export function updateBetTip(data) {
  return request(
    'PUT',
    encodeUrl`/api/v1/private/content/bet_tips/${data.id}/`,
    data,
  );
}

export function getCompetition({ id }) {
  return request('GET', encodeUrl`/api/v1/football/competitions/${id}/`);
}

export function getCompetitionResult({ id }) {
  return request('GET', encodeUrl`/api/v1/football/competitions/${id}/result/`);
}

export function getCompetitionCoupon({ id }) {
  // The API returns `{}` if the user hasn’t submitted a coupon yet. Consider
  // the coupon missing if `.user_id` is missing.
  return request(
    'GET',
    encodeUrl`/api/v1/football/competitions/${id}/coupon/`,
  ).then(response => (response.user_id == null ? NONE : response));
}

export function postCompetitionCoupon({ id }, data) {
  return request(
    'POST',
    encodeUrl`/api/v1/football/competitions/${id}/coupon/`,
    data,
  );
}

export function getBettingSchool(data) {
  return request('GET', `/api/v1/content/betting_school/`, data);
}

export function getTipsterProfile({ slug, filters }) {
  return request('GET', encodeUrl`/api/v1/content/tipsters/${slug}/`, filters);
}

export function getTipsterBetTips(data) {
  return request('GET', `/api/v1/content/bet_tips/`, data);
}

export function getTipstersTopList({ listPath }) {
  return request(
    'GET',
    `/api/v1/content/tipsters/toplist/${listPath}${
      !listPath.includes('?') && !listPath.endsWith('/') ? '/' : ''
    }`,
  );
}

export function getTipsters() {
  return request('GET', `/api/v1/content/tipsters/`);
}

export function getSyndicates(uid) {
  return request('GET', `/api/v1/content/syndicates/`, {
    uid,
  });
}

export function createSyndicate(data) {
  return request('POST', `/api/v1/private/content/syndicates/`, data);
}

export function deleteSyndicate(id) {
  return request(
    'DELETE',
    encodeUrl`/api/v1/private/content/syndicates/${id}`,
  ).then(response => response === undefined && { success: true });
}

export function updateSyndicate(data) {
  return request(
    'PUT',
    encodeUrl`/api/v1/private/content/syndicates/${data.id}/`,
    data,
  );
}

export function getSharedBets() {
  return request(
    'GET',
    `/api/v1/public/content/shared_bets/overodds/`,
    undefined,
    undefined,
    { site: 'andelsspel' },
    'trais',
  );
}

export function getSvenskaSpelWidget() {
  return request(
    'GET',
    `/api/svs/widget/`,
    undefined,
    undefined,
    undefined,
    'overodds',
  );
}

export function sendEmail(values) {
  return request(
    'POST',
    `/api/sendTipsterForm`,
    values,
    undefined,
    undefined,
    'overodds',
  );
}

/**
 * Some endpoints return fields such as:
 *
 *     "url": "https://trsport.trdev.se/api/v1/football/matches/371/"
 *
 * This function lets you request such a URL. It’s basically the same as using
 * the `request` function directly.
 */
export function getUrl(apiUrl, data) {
  return request('GET', apiUrl, data);
}

function getWinner(id, match, sign) {
  if (sign === 'O') {
    return DRAW;
  }

  if (sign === 'V') {
    // This team won.
    return id;
  }

  // `sign === "F"` at this point, which means that the other team won.
  return id === match.home_team.id ? match.away_team.id : match.home_team.id;
}
