import Loadable from 'react-loadable';

import Loading from '../components/Loading';
import { GAME_TYPES, gameTitle } from '../utils/productUtil';
import {
  ABOUT,
  ABOUT_TEAM_MEMBER,
  ANALYSES,
  ANALYSES_ADMIN,
  ANALYSIS_POST,
  ANALYSIS_POST_CREATE,
  ANALYSIS_POST_EDIT,
  BECOME_TIPSTER,
  BESTGAME_EMBED,
  BIG9,
  BLOG,
  BLOG_POST,
  COMPETITION,
  enteredAnyGamePage,
  GAME,
  GAME_EVENTS,
  GAME_INFO,
  GAME_INTERVIEWS,
  GAME_LINEUPS,
  GAME_STATISTICS,
  GAME_SUMMARY,
  GAME_TABLE,
  HOME,
  LEAGUE_POST,
  PLAY_REDUCED,
  PLAY_TOGETHER,
  PROFILE,
  SCHOOL,
  SCHOOL_AUTHOR,
  SCHOOL_POST,
  SELECT_BETOFFER,
  SELECT_EVENT,
  SHARED_BETS,
  SOCCER,
  STATISTICS,
  STATISTICS_ONE_TEAM,
  STATISTICS_TWO_TEAMS,
  TEAM_POST,
  TIPSTERS,
  WORLD_CUP,
} from './actions/router';
import config from './config';
import { locationSelector } from './selectors';

export const loadableOptions = {
  loading: Loading,
  timeout: config.LOADABLE_TIMEOUT,
};

const LoadableGame = Loadable({
  ...loadableOptions,
  // Dynamic import shouldn't cause cyclic deps?
  /* eslint-disable-next-line import/no-cycle */
  loader: () => import('../views/game'),
});

function route({
  path,
  importPath,
  title,
  nav,
  subNav,
  currentPageBar,
  panoramaAd = false,
  svSpelBar = true,
  subImportPath,
}) {
  const autoTitle = typeof title === 'string' ? title : undefined;

  return {
    path,
    title,
    nav: nav == null ? autoTitle : nav,
    subNav,
    currentPageBar:
      typeof currentPageBar === 'boolean'
        ? currentPageBar
          ? autoTitle
          : undefined
        : currentPageBar,
    panoramaAd,
    svSpelBar,
    component:
      importPath == null
        ? undefined
        : Loadable({
            ...loadableOptions,
            loader: () =>
              import(
                /* webpackChunkName: "[request]" */ `../views/${importPath}/index.js`
              ),
          }),
    subComponent:
      subImportPath == null
        ? undefined
        : Loadable({
            ...loadableOptions,
            loader: () =>
              import(
                /* webpackChunkName: "[request]" */ `../views/${subImportPath}/index.js`
              ),
          }),
  };
}

export function getNav(routeObject, payload) {
  return typeof routeObject.nav === 'function'
    ? routeObject.nav(payload)
    : routeObject.nav;
}

export function getSubNav(routeObject, payload) {
  return typeof routeObject.subNav === 'function'
    ? routeObject.subNav(payload)
    : routeObject.subNav;
}

export function getCurrentPageBar(routeObject, payload) {
  return typeof routeObject.currentPageBar === 'function'
    ? routeObject.currentPageBar(payload)
    : routeObject.currentPageBar;
}

export function getTitle(routeObject, payload) {
  const title =
    typeof routeObject.title === 'function'
      ? routeObject.title(payload)
      : routeObject.title;
  return Array.isArray(title) ? title : [title];
}

const gameBasePath = `/:gameType(${GAME_TYPES.join('|')})/:roundId?`;

function gameRoute({ pathSegment, titleSegment, subImportPath }) {
  return {
    ...route({
      path: `${gameBasePath}/${pathSegment}/:roundMatchId`,
      importPath: undefined,
      title: payload =>
        [titleSegment, gameTitle(payload.gameType)].filter(Boolean),
      nav: payload => gameTitle(payload.gameType),
      subNav: titleSegment,
      currentPageBar: payload => gameTitle(payload.gameType),
      panoramaAd: false,
      subImportPath,
    }),
    component: LoadableGame,
    thunk(dispatch, getState) {
      const state = getState();
      const { gameType, roundId, roundMatchId } =
        locationSelector(state).payload;
      dispatch(enteredAnyGamePage({ gameType, roundId, roundMatchId }));
    },
  };
}

const ABOUT_ROUTE_BASE = {
  path: '/om',
  importPath: 'about',
  title: 'Om oss',
  panoramaAd: true,
};

const ANALYSES_ROUTE_BASE = {
  path: '/analyser',
  importPath: 'analyses',
  title: 'Rekar',
  panoramaAd: true,
};

const ANALYSES_ADMIN_BASE = {
  path: '/admin',
  importPath: 'analyses/AnalysesAdmin',
  title: 'Administrera spelrekar',
  panoramaAd: true,
};

const BLOG_ROUTE_BASE = {
  path: '/blogg',
  importPath: 'blog',
  title: 'Blogg',
  svSpelBar: false,
};

const SOCCER_BASE = {
  path: '/engelsk-fotboll',
  importPath: 'soccer',
  title: 'Engelsk Fotboll',
};

const SCHOOL_ROUTE_BASE = {
  path: '/spelskola',
  importPath: 'school',
  title: 'Spelskola',
};

const SHARED_BETS_ROUTE_BASE = {
  path: '/proffsandelar',
  importPath: 'sharedBets',
  title: 'Proffsandelar',
};

const STATISTICS_ROUTE_BASE = {
  path: '/statistik',
  importPath: 'statistics',
  title: 'Statistik',
  currentPageBar: true,
  panoramaAd: true,
};

const ANALYSIS_POST_CREATE_ROUTE_BASE = {
  path: '/skapa-spelrek',
  importPath: 'analyses/SelectEvent',
  title: 'Skapa spelrek',
};

const routesMap = {
  [HOME]: route({
    path: '/',
    importPath: 'home',
    title: 'Hem',
  }),
  [ABOUT]: route(ABOUT_ROUTE_BASE),
  [ABOUT_TEAM_MEMBER]: route({
    ...ABOUT_ROUTE_BASE,
    path: `${ABOUT_ROUTE_BASE.path}/:slug`,
    importPath: 'about/team',
  }),
  [ANALYSES]: route(ANALYSES_ROUTE_BASE),
  [ANALYSIS_POST]: route({
    ...ANALYSES_ROUTE_BASE,
    path: `${ANALYSES_ROUTE_BASE.path}/:analysisId`,
    importPath: 'analyses/AnalysisPost',
  }),
  [ANALYSIS_POST_EDIT]: route({
    ...ANALYSES_ROUTE_BASE,
    path: `${ANALYSES_ROUTE_BASE.path}/:analysisId/edit`,
    importPath: 'analyses/AnalysisForm',
  }),
  [ANALYSES_ADMIN]: route(ANALYSES_ADMIN_BASE),
  [BESTGAME_EMBED]: route({
    path: '/embed/bestgame',
    importPath: 'embed/bestgame',
    title: 'Bästa spelet just nu',
  }),
  [BLOG]: route(BLOG_ROUTE_BASE),
  [BLOG_POST]: route({
    ...BLOG_ROUTE_BASE,
    path: `${BLOG_ROUTE_BASE.path}/:slug`,
    importPath: 'blog/BlogPost',
  }),
  [BIG9]: route({
    path: '/blogg/spel/:tag',
    importPath: 'blog',
    title: 'BIG 9',
    svSpelBar: false,
  }),
  [COMPETITION]: route({
    path: '/vm-tävling',
    importPath: 'competition',
    title: 'VM-Tävling',
  }),
  [PLAY_REDUCED]: route({
    path: '/spela-reducerat',
    importPath: 'playReduced',
    title: 'Spela reducerat',
  }),
  [GAME]: {
    ...gameRoute({
      pathSegment: '',
      titleSegment: undefined,
      subImportPath: undefined,
    }),
    path: gameBasePath,
  },
  [GAME_EVENTS]: gameRoute({
    pathSegment: 'matchhändelser',
    titleSegment: 'Matchhändelser',
    subImportPath: 'game/events',
  }),
  [GAME_INFO]: gameRoute({
    pathSegment: 'info',
    titleSegment: 'Information/Analys',
    subImportPath: 'game/info',
  }),
  [GAME_INTERVIEWS]: gameRoute({
    pathSegment: 'intervjuer',
    titleSegment: 'Intervjuer',
    subImportPath: 'game/interviews',
  }),
  [GAME_LINEUPS]: gameRoute({
    pathSegment: 'laguppställning',
    titleSegment: 'Laguppställning',
    subImportPath: 'game/lineups',
  }),
  [GAME_SUMMARY]: gameRoute({
    pathSegment: 'summering',
    titleSegment: 'Summering',
    subImportPath: 'game/summary',
  }),
  [GAME_STATISTICS]: gameRoute({
    pathSegment: 'statistik',
    titleSegment: 'Statistik',
    subImportPath: 'game/statistics',
  }),
  [GAME_TABLE]: gameRoute({
    pathSegment: 'tabell',
    titleSegment: 'Tabell',
    subImportPath: 'game/table',
  }),
  [STATISTICS]: route(STATISTICS_ROUTE_BASE),
  [STATISTICS_ONE_TEAM]: route({
    ...STATISTICS_ROUTE_BASE,
    path: `${STATISTICS_ROUTE_BASE.path}/:slug1`,
  }),
  [STATISTICS_TWO_TEAMS]: route({
    ...STATISTICS_ROUTE_BASE,
    path: `${STATISTICS_ROUTE_BASE.path}/:slug1/vs/:slug2`,
  }),
  [SELECT_EVENT]: route(ANALYSIS_POST_CREATE_ROUTE_BASE),
  [SELECT_BETOFFER]: route({
    ...ANALYSIS_POST_CREATE_ROUTE_BASE,
    path: `${ANALYSIS_POST_CREATE_ROUTE_BASE.path}/:eventId`,
    importPath: 'analyses/SelectBetOffer',
  }),
  [ANALYSIS_POST_CREATE]: route({
    ...ANALYSIS_POST_CREATE_ROUTE_BASE,
    path: `${ANALYSIS_POST_CREATE_ROUTE_BASE.path}/:eventId/:betOfferId/:provider/:outcomeId`,
    importPath: 'analyses/AnalysisForm',
  }),
  [WORLD_CUP]: route({
    path: '/vm-analyser/:slug?',
    importPath: 'worldcup',
    title: 'VM-Analyser',
    // Needed so that the sidebar toggle icon gets space on mobile.
    currentPageBar: true,
  }),
  [SHARED_BETS]: route({
    ...SHARED_BETS_ROUTE_BASE,
    importPath: 'sharedBets',
  }),
  [SCHOOL]: route({
    ...SCHOOL_ROUTE_BASE,
    importPath: 'school',
  }),
  [SCHOOL_POST]: route({
    ...SCHOOL_ROUTE_BASE,
    path: '/spelskola/:slug?',
    importPath: 'school/SchoolPost',
  }),
  [SCHOOL_AUTHOR]: route({
    ...SCHOOL_ROUTE_BASE,
    path: '/spelskola/av/:slug?',
    importPath: 'school/SchoolByAuthor',
  }),
  [PLAY_TOGETHER]: route({
    path: '/spela-tillsammans',
    importPath: 'playTogether',
    title: 'Spela tillsammans',
  }),
  [PROFILE]: route({
    path: '/rekare/:slug',
    importPath: 'profile',
    title: payload => payload.slug,
  }),
  [TIPSTERS]: route({
    path: '/rekare/',
    importPath: 'tipsters',
    title: 'Rekare',
  }),
  [BECOME_TIPSTER]: route({
    path: '/bli-rekare',
    importPath: 'becomeTipster',
    title: 'Bli rekare',
  }),
  [SOCCER]: route(SOCCER_BASE),
  [LEAGUE_POST]: route({
    ...SOCCER_BASE,
    path: `${SOCCER_BASE.path}/:slug`,
    importPath: 'soccer/LeaguePost',
  }),
  [TEAM_POST]: route({
    ...SOCCER_BASE,
    path: `${SOCCER_BASE.path}/:league/:slug`,
    importPath: 'soccer/TeamPost',
  }),
};

export default routesMap;
