/* eslint-disable prefer-promise-reject-errors */

import config from '../main/config';
import { addQueryString, makeQueryString } from '../utils';
import truth from './auth';
import { status } from './status';

const URL_TIL_PATH_REGEX = /^https?:\/\/[^/]+/;

/**
 * Makes a request to `apiUrl` using `method` and sending `data`. Returns a
 * `Promise` that resolves to the returned JSON. The promise is rejected for
 * error status codes and network errors.
 *
 * Additionally, there's some extra logic around 401 status codes:
 *
 * - If the user is logged in one attempt to refresh the token is made.
 * - If the user isn't logged in, the UI is expected to show a "you need to log
 *   in to view this content" message.
 *
 * Note: The backend is trusted to consistenly return a correct JSON value for
 * every endpoint. No validation is done.
 *
 * Example usage:
 *
 *     request("GET", "/api/something", {foo: bar}).then(
 *       result => {
 *         // Success: Use `result`.
 *       },
 *       error => {
 *         // Handle unexpected error (network error, server error (500),
 *         // JSON parse error, etc.)
 *         // error.status: number
 *         // error.responseText: string
 *       },
 *     );
 */
export default function request(
  method,
  apiUrl,
  data,
  { silencedStatusCodes = [] } = {},
  headers,
  apiName = 'trsport',
) {
  const isGET = method.toLowerCase() === 'get';
  const info = { method, apiUrl, data };

  const newApiUrl =
    data != null && isGET
      ? addQueryString(apiUrl, makeQueryString(data))
      : apiUrl;

  const url =
    apiName === 'trais'
      ? `${config.API_BASE_URL_TRAIS}${apiUrl}`
      : apiName === 'overodds'
      ? apiUrl
      : `${config.API_BASE_URL}${newApiUrl.replace(URL_TIL_PATH_REGEX, '')}`;

  const { jwtToken, csrfToken } = truth;

  function makeRequest({ refresh }) {
    return fetch(url, {
      method,
      body: data != null && !isGET ? JSON.stringify(data) : undefined,
      headers: {
        Accept: 'application/json',
        'Content-Type': data == null ? '' : 'application/json',
        Authorization: jwtToken == null ? '' : `JWT ${jwtToken}`,
        'X-CSRFToken': csrfToken == null ? '' : csrfToken,
        'X-JWT-CSRF-Token': csrfToken == null ? '' : csrfToken,
        'TRMedia-API-Key': 'TJKRXAcZyMzW8aAPADFhbReQn5wcSPav7MJxZ8NRwud5fY23v5',
        ...headers,
      },
      credentials: 'include',
    })
      .then(response => {
        if (refresh && response.status === status.HTTP_401_UNAUTHORIZED) {
          return (
            truth
              .refreshDebounced()
              // Try to make the request again regardless of whether the refresh
              // succeeded.
              // 1. The user’s tokens get messed up.
              // 2. That causes 401 for _all_ API requests (that’s how Django
              //    REST Framework works).
              // 3. The refresh fails (the user isn’t logged in anymore).
              // 4. But the bad tokens have been cleared, so the requests should
              //    succeed now.
              .then(
                () => makeRequest({ refresh: false }),
                () => makeRequest({ refresh: false }),
              )
          );
        }

        if (
          response.status >= status.HTTP_200_OK &&
          response.status < status.HTTP_400_BAD_REQUEST
        ) {
          return method.toLowerCase() === 'delete'
            ? undefined
            : response.json().catch(error => {
                error.response = error;
                throw error;
              });
        }

        const error = createStatusCodeError(response);
        error.response = response;
        throw error;
      })
      .catch(error => {
        if ('response' in error === false) {
          throw error;
        }

        const { response } = error;
        delete error.response;

        error.message = createUpdatedErrorMessage(error, info, response);
        error.status = response == null ? -1 : response.status;

        const textPromise =
          response == null || response.bodyUsed
            ? Promise.resolve('')
            : response.text().then(
                text => text,
                () => '',
              );

        return textPromise.then(text => {
          error.responseText = text;
          if (
            !(
              silencedStatusCodes.includes(error.status) ||
              // Don't warn if the user needs to log in to show a piece of
              // content. A "please log in" message will be shown in the UI.
              (!truth.isAuthenticated() &&
                error.status === status.HTTP_401_UNAUTHORIZED)
            )
          ) {
            console.error('REST API error', error, response, text);
          }
          throw error;
        });
      });
  }

  return makeRequest({ refresh: true });
}

function createStatusCodeError(response) {
  return new Error(
    `Unexpected response status code. Got ${response.status} but expected 200 <= status < 400.`,
  );
}

function createUpdatedErrorMessage(error, info, response) {
  return [
    `REST API error for request: ${info.method} ${info.apiUrl}`,
    `RequestData sent: ${JSON.stringify(info.data, null, 2)}`,
    `Response: ${response ? createResponseSummary(response) : response}`,
    String(error),
  ].join('\n');
}

function createResponseSummary(response) {
  const start = `${response.status} ${response.statusText} / ${response.type}`;
  const redirect = response.redirected
    ? `Redirected to: ${response.url}`
    : null;
  return [start, redirect].filter(Boolean).join(' | ');
}
