export const FILTER_CHANGE = 'game/statistics/FILTER_CHANGE';
export const TOGGLE_TABLE = 'game/statistics/TOGGLE_TABLE';

export const filterChange = ({ name, value }) => ({
  type: FILTER_CHANGE,
  name,
  value,
});

export const toggleTable = id => ({
  type: TOGGLE_TABLE,
  id,
});
