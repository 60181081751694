import { delay } from 'redux-saga';
import { call, cancel, fork, put, select } from 'redux-saga/effects';

import {
  fetchGenericFailure,
  fetchGenericPastDelay,
  fetchGenericStart,
  fetchGenericSuccess,
} from '../actions/genericFetch';

const DELAY = 200; // ms

export function* fetchGeneric(
  wrappingActionCreator,
  shouldRequest,
  callRequest,
  action,
) {
  const shouldContinue = yield call(shouldRequest);

  if (!shouldContinue) {
    return false;
  }

  yield put(wrappingActionCreator(fetchGenericStart()));

  const pastDelayTask = yield fork(markPastDelay, wrappingActionCreator);
  let response = false;

  try {
    response = yield callRequest;
    yield put(wrappingActionCreator(fetchGenericSuccess(response)));
  } catch (error) {
    yield put(wrappingActionCreator(fetchGenericFailure(error, action)));
  }

  yield cancel(pastDelayTask);

  return response;
}

export function* markPastDelay(wrappingActionCreator) {
  yield call(delay, DELAY);
  yield put(wrappingActionCreator(fetchGenericPastDelay()));
}

export function onlyIfMissing(selector, key) {
  return function* onlyIfMissingSaga() {
    const genericFetchState = yield select(selector);

    return key
      ? genericFetchState[key] == null || genericFetchState[key].data == null
      : genericFetchState == null || genericFetchState.data == null;
  };
}

export function alwaysFetch() {
  return true;
}
