import {
  genericFetchInitialState,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  FETCH_BEST_MATCH,
  FETCH_BLOG_POSTS,
  FETCH_CAROUSEL_SLIDES,
  FETCH_GOLDEN_BET_TIP,
  FETCH_MONTH_TOP_USER,
  FETCH_NEWS,
  FETCH_SVENSKA_SPEL_WIDGET,
  FETCH_TOP_STREAK,
} from './actions';

const initialState = {
  blogPosts: genericFetchInitialState,
  carouselSlides: genericFetchInitialState,
  news: genericFetchInitialState,
  goldenBetTip: genericFetchInitialState,
  svenskaSpelWidget: genericFetchInitialState,
  topMonthUser: genericFetchInitialState,
  topStreak: genericFetchInitialState,
  bestMatch: genericFetchInitialState,
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BLOG_POSTS:
      return {
        ...state,
        blogPosts: genericFetchReducer(state.blogPosts, action.wrappedAction),
      };

    case FETCH_CAROUSEL_SLIDES:
      return {
        ...state,
        carouselSlides: genericFetchReducer(
          state.carouselSlides,
          action.wrappedAction,
        ),
      };

    case FETCH_NEWS:
      return {
        ...state,
        news: genericFetchReducer(state.news, action.wrappedAction),
      };

    case FETCH_GOLDEN_BET_TIP:
      return {
        ...state,
        goldenBetTip: genericFetchReducer(
          state.goldenBetTip,
          action.wrappedAction,
        ),
      };

    case FETCH_MONTH_TOP_USER:
      return {
        ...state,
        topMonthUser: genericFetchReducer(
          state.topMonthUser,
          action.wrappedAction,
        ),
      };

    case FETCH_TOP_STREAK:
      return {
        ...state,
        topStreak: genericFetchReducer(state.topStreak, action.wrappedAction),
      };

    case FETCH_BEST_MATCH:
      return {
        ...state,
        bestMatch: genericFetchReducer(state.bestMatch, action.wrappedAction),
      };

    case FETCH_SVENSKA_SPEL_WIDGET:
      return {
        ...state,
        svenskaSpelWidget: genericFetchReducer(
          state.svenskaSpelWidget,
          action.wrappedAction,
        ),
      };

    default:
      return state;
  }
}
