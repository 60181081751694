export const ANALYSIS_FORM_CHANGE = 'analyses/ANALYSIS_FORM_CHANGE';
export const DELETE_ANALYSIS = 'analyses/DELETE_ANALYSIS';
export const FETCH_CONTENT_LIST = 'analyses/FETCH_CONTENT_LIST';
export const FETCH_PRIVATE_ANALYSIS = 'analyses/FETCH_PRIVATE_ANALYSIS';
export const FETCH_PUBLIC_ANALYSIS = 'analyses/FETCH_PUBLIC_ANALYSIS';
export const FETCH_EVENT = 'analyses/FETCH_EVENT';
export const FETCH_EVENT_GROUPS = 'analyses/FETCH_EVENT_GROUPS';
export const FETCH_EVENT_LIST = 'analyses/FETCH_EVENT_LIST';
export const FETCH_MORE_CONTENT_LIST = 'analyses/FETCH_MORE_CONTENT_LIST';
export const FETCH_UPCOMING_ANALYSES = 'analyses/FETCH_UPCOMING_ANALYSES';
export const FETCH_UNCORRECTED_ANALYSES = 'analyses/FETCH_UNCORRECTED_ANALYSES';
export const GROUP_FILTER_CHANGE = 'analyses/GROUP_FILTER_CHANGE';
export const INIT_ANALYSIS_FORM = 'analyses/INIT_ANALYSIS_FORM';
export const SAVE_ANALYSIS_FORM = 'analyses/SAVE_ANALYSIS_FORM';
export const RESET_FILTERS = 'analyses/RESET_FILTERS';
export const START_FETCH_MORE_ANALYSES = 'analyses/START_FETCH_MORE_ANALYSES';
export const SUBMIT_ANALYSIS_FORM = 'analyses/SUBMIT_ANALYSIS_FORM';
export const UPDATE_EVENT_LIST = 'analyses/UPDATE_EVENT_LIST';
export const SELECTED_FILTERS_UPDATE = 'analyses/SELECTED_FILTERS_UPDATE';
export const SET_AVAILABLE_FILTERS = 'analyses/SET_AVAILABLE_FILTERS';
export const SET_SELECTED_FILTER_KEY = 'analyses/SET_SELECTED_FILTER_KEY';
export const FETCH_FILTERED_PUBLIC_ANALYSIS =
  'analyses/FETCH_FILTERED_PUBLIC_ANALYSIS';
export const FETCH_AUTHENTICATED_TIPSTER_ACTION =
  'analyses/FETCH_AUTHENTICATED_TIPSTER_ACTION';

export const fetchContentList = wrappedAction => ({
  type: FETCH_CONTENT_LIST,
  wrappedAction,
});

export const fetchAuthenticatedTipster = wrappedAction => ({
  type: FETCH_AUTHENTICATED_TIPSTER_ACTION,
  wrappedAction,
});

export const fetchEventGroups = wrappedAction => ({
  type: FETCH_EVENT_GROUPS,
  wrappedAction,
});

export const fetchEvent = (eventId, wrappedAction) => ({
  type: FETCH_EVENT,
  wrappedAction,
  eventId,
});

export const fetchPrivateAnalysis = (analysisId, wrappedAction) => ({
  type: FETCH_PRIVATE_ANALYSIS,
  wrappedAction,
  analysisId,
});

export const fetchPublicAnalysis = (analysisId, wrappedAction) => ({
  type: FETCH_PUBLIC_ANALYSIS,
  wrappedAction,
  analysisId,
});

export const fetchUpcomingAnalyses = wrappedAction => ({
  type: FETCH_UPCOMING_ANALYSES,
  wrappedAction,
});

export const fetchUncorrectedAnalyses = wrappedAction => ({
  type: FETCH_UNCORRECTED_ANALYSES,
  wrappedAction,
});

export const fetchEventList = wrappedAction => ({
  type: FETCH_EVENT_LIST,
  wrappedAction,
});

export const updateEventList = ({ groupId }) => ({
  type: UPDATE_EVENT_LIST,
  groupId,
});

export const setGroupFilter = ({ filter }) => ({
  type: GROUP_FILTER_CHANGE,
  filter,
});

export const setAnalysisFormValue = ({ field, value }) => ({
  type: ANALYSIS_FORM_CHANGE,
  field,
  value,
});

export const initAnalysisForm = formData => ({
  type: INIT_ANALYSIS_FORM,
  formData,
});

export const submitAnalysisForm = formData => ({
  type: SUBMIT_ANALYSIS_FORM,
  formData,
});

export const deleteAnalysis = analysisId => ({
  type: DELETE_ANALYSIS,
  analysisId,
});

export const fetchMoreContentList = key => wrappedAction => ({
  type: FETCH_MORE_CONTENT_LIST,
  wrappedAction,
  key,
});

export const startFetchMoreAnalyses = key => ({
  type: START_FETCH_MORE_ANALYSES,
  key,
});

export const selectedFiltersUpdate = filters => ({
  type: SELECTED_FILTERS_UPDATE,
  payload: { filters },
});

export const setAvailableFilters = filters => ({
  type: SET_AVAILABLE_FILTERS,
  filters,
});

export const setSelectedFilterKey = key => ({
  type: SET_SELECTED_FILTER_KEY,
  key,
});

export const fetchFilteredPublicAnalyses = key => wrappedAction => ({
  type: FETCH_FILTERED_PUBLIC_ANALYSIS,
  wrappedAction,
  key,
});
