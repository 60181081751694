export const FETCH_PROFILE = 'tipsters/FETCH_PROFILE';
export const FETCH_TIPSTER_BET_TIPS = 'tipsters/FETCH_TIPSTER_BET_TIPS';
export const START_FETCH_MORE_BET_TIPS = 'tipsters/START_FETCH_MORE_BET_TIPS';
export const FETCH_MORE_BET_TIPS = 'tipsters/FETCH_MORE_BET_TIPS';
export const SELECTED_FILTERS_UPDATE = 'tipsters/SELECTED_FILTERS_UPDATE';

export const selectedFiltersUpdate = (filters, slug) => ({
  type: SELECTED_FILTERS_UPDATE,
  payload: { filters, slug },
});

export const fetchTipsterProfile = slug => wrappedAction => ({
  type: FETCH_PROFILE,
  wrappedAction,
  slug,
});

export const fetchTipsterBetTips = (slug, live) => wrappedAction => ({
  type: FETCH_TIPSTER_BET_TIPS,
  wrappedAction,
  slug,
  live,
});

export const fetchMoreBetTips = (slug, live) => wrappedAction => ({
  type: FETCH_MORE_BET_TIPS,
  wrappedAction,
  slug,
  live,
});

export const startFetchMoreBetTips = (slug, live) => ({
  type: START_FETCH_MORE_BET_TIPS,
  slug,
  live,
});
