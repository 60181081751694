import { CONTENT_TYPES } from '../../api';
import { readFromLocalStorage, writeToLocalStorage } from '../../utils/dom';
import { oddsToPercentages, parseGameSegment } from '../../utils/game';

const ODDS_LOCAL_STORAGE_KEY = 'game/selectedOddsById';

export function contentTypesForInfoTab() {
  return [CONTENT_TYPES.ROUNDMATCH_ANALYSIS];
}

export function contentTypesForInterviewsTab() {
  return [CONTENT_TYPES.INTERVIEW];
}

export const selectedOddsInitialState = {
  home: false,
  draw: false,
  away: false,
};

export function selectedOddsByIdFromLocalStorage() {
  function warn(message, data = {}) {
    console.warn(
      'selectedOddsByIdFromLocalStorage: Failed to read from localStorage.',
      message,
      { localStorageKey: ODDS_LOCAL_STORAGE_KEY, ...data },
    );
  }

  const data = readFromLocalStorage(ODDS_LOCAL_STORAGE_KEY);

  if (data == null) {
    return {};
  }

  const type = Object.prototype.toString.call(data);
  const expectedType = '[object Object]';
  if (type !== expectedType) {
    warn('Wrong type.', { data, type, expectedType });
    return {};
  }

  return Object.entries(data).reduce((result, [key, value]) => {
    if (typeof value !== 'string') {
      warn('Wrong key-value type.', { data, key, value });
      return result;
    }
    result[key] = parseGameSegment(value);
    return result;
  }, {});
}

export function selectedOddsByIdToLocalStorage(selectedOddsById) {
  const data = Object.entries(selectedOddsById).reduce(
    (result, [slug, { home, draw, away } = selectedOddsInitialState]) => {
      result[slug] = [home ? '1' : '', draw ? 'X' : '', away ? '2' : ''].join(
        '',
      );
      return result;
    },
    {},
  );

  writeToLocalStorage(ODDS_LOCAL_STORAGE_KEY, data);
}

/**
 * Sorts a list of Content (from the API), first by date and then by title.
 */
export function sortContent(content) {
  return [...content].sort(
    (a, b) =>
      new Date(a.publish_start).getTime() -
        new Date(b.publish_start).getTime() ||
      a.headline.localeCompare(b.headline),
  );
}

export function sortPlayers(players, property) {
  return [...players].sort((a, b) => b[property] - a[property]);
}

export function getInjuredPlayers(lineup) {
  return lineup.missing_players.filter(
    player => player.missing_reason === 'injured',
  );
}

export function oddsListItem(oddsValues, { percentage, ...rest } = {}) {
  if (
    oddsValues == null ||
    oddsValues.home == null ||
    oddsValues.draw == null ||
    oddsValues.away == null
  ) {
    return;
  }

  return {
    percentage,
    ...(percentage === 'decimalComputed'
      ? oddsToPercentages(oddsValues)
      : oddsValues),
    ...rest,
  };
}
