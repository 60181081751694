import { delay } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import truth from '../../api/auth';
import { status } from '../../api/status';
import { getUserName } from '../../utils';
import { MINUTE, SECOND } from '../../utils/time';
import { resetState } from '../actions';
import { auth } from '../actions/auth';
import { toHome } from '../actions/router';
import Bugsnag from '../bugsnag';
import { userSelector } from '../selectors/auth';

const MAX_REFRESH_DELAY = 10 * MINUTE;

export default function* rootSaga() {
  yield call(refreshOnStartupSaga);
}

export function* resetSaga() {
  yield put(resetState());
  yield put(toHome());
}

export function* refreshOnStartupSaga() {
  let attempts = 0;
  while (true) {
    attempts += 1;
    const timeout = Math.min(MAX_REFRESH_DELAY, 1.8 ** attempts * SECOND);

    try {
      const response = yield call([truth, 'refreshDebounced']);
      yield call(setBugsnagUser, response.user);
      yield put(auth(response.user));

      // The user is logged in and the token is refreshed. All done.
      break;
    } catch (error) {
      if (
        error.status === status.HTTP_401_UNAUTHORIZED ||
        error.status === status.HTTP_403_FORBIDDEN
      ) {
        const user = select(userSelector);
        if (user?.data != null) {
          yield call(resetSaga);
        }
        yield put(auth());
        break;
      }

      const time =
        timeout < MINUTE
          ? `${(timeout / SECOND).toFixed(2)} seconds`
          : `${(timeout / MINUTE).toFixed(2)} minutes`;
      console.warn(
        `Token refresh on startup failed. Retrying in ${time}`,
        error,
      );
    }

    yield call(delay, timeout);
  }
}

function setBugsnagUser(user) {
  if (user != null) {
    Bugsnag.setUser(user.id, user.email, getUserName(user));
  }
}
