import { all, call, put } from 'redux-saga/effects';

import * as api from '../../api';
import truth from '../../api/auth';
import { GAME_TYPES } from '../../utils/productUtil';
import { fetchGenericSuccess } from '../actions/genericFetch';
import {
  fetchATGJackpots,
  fetchCompetition,
  fetchCompetitionCoupon,
  fetchCompetitionResult,
  fetchContent,
  fetchLatestRound,
  fetchLineups,
  fetchMatchStatistics,
  fetchRound,
  fetchTeam,
  fetchTeamComingMatches,
  fetchTeamStatistics,
  fetchTournamentsList,
} from '../actions/models';
import {
  ATGJackpotsSelector,
  comingMatchesByTeamIdSelector,
  competitionCouponsBySlugSelector,
  competitionResultsBySlugSelector,
  competitionsBySlugSelector,
  contentBySlugSelector,
  latestRoundsSelector,
  lineupsByMatchIdSelector,
  roundsSelector,
  statisticsByMatchIdSelector,
  statisticsByTeamIdSelector,
  teamsBySlugSelector,
  tournamentsListSelector,
} from '../selectors/models';
import { alwaysFetch, fetchGeneric, onlyIfMissing } from './genericFetch';

export function* fetchCompetitionSaga(slug, action) {
  yield fetchGeneric(
    fetchCompetition.bind(undefined, slug),
    onlyIfMissing(state => competitionsBySlugSelector(state)[slug]),
    call(api.getCompetition, { id: slug }),
    action,
  );
}

export function* fetchCompetitionCouponsSaga(
  {
    slug,
    // "noOverride" | "ignorePrevious" | "alwaysFetch"
    fetchOverride = 'noOverride',
  },
  action,
) {
  if (truth.isAuthenticated() && fetchOverride !== 'ignorePrevious') {
    yield fetchGeneric(
      fetchCompetitionCoupon.bind(undefined, slug),
      fetchOverride === 'alwaysFetch'
        ? alwaysFetch
        : onlyIfMissing(state => competitionCouponsBySlugSelector(state)[slug]),
      call(api.getCompetitionCoupon, { id: slug }),
      action,
    );
  } else {
    yield put(fetchCompetitionCoupon(slug, fetchGenericSuccess(api.NONE)));
  }
}

export function* fetchCompetitionResultSaga({ slug, shouldRequest }, action) {
  yield fetchGeneric(
    fetchCompetitionResult.bind(undefined, slug),
    shouldRequest == null
      ? onlyIfMissing(state => competitionResultsBySlugSelector(state)[slug])
      : shouldRequest,
    call(api.getCompetitionResult, { id: slug }),
    action,
  );
}

export function* fetchContentSagaHelper(action) {
  const { slug } = action.payload;
  yield call(fetchContentSaga, slug, action);
}

export function* fetchContentSaga(slug, action) {
  yield fetchGeneric(
    fetchContent.bind(undefined, slug),
    onlyIfMissing(state => contentBySlugSelector(state)[slug]),
    call(fetchContentWithMatches, slug),
    action,
  );
}

export function fetchContentWithMatches(id) {
  return api.getContent({ id });
}

export function* fetchRoundSaga({ gameType, roundId }, action) {
  yield fetchGeneric(
    fetchRound.bind(undefined, gameType, roundId),
    onlyIfMissing(state => {
      const roundsState = roundsSelector(state)[gameType];

      if (roundId == null) {
        return roundsState.latest;
      }

      return (
        roundsState[roundId] ||
        (roundsState.latest.data != null &&
        roundsState.latest.data.id === roundId
          ? roundsState.latest
          : undefined)
      );
    }),
    call(fetchRoundWithContent, { gameType, roundId }),
    action,
  );
}

export function fetchRoundWithContent({ gameType, roundId }) {
  const roundPromise =
    roundId == null
      ? api.getRound({ type: gameType })
      : api.getRound({ type: gameType, id: roundId });
  return roundPromise.then(round =>
    round === api.NONE
      ? round
      : Promise.all(
          round.content.map(id => api.getContent({ id }).catch(error => error)),
        ).then(content => ({ ...round, content })),
  );
}

export function fetchLatestContentWithMatches(filters) {
  return api
    .latestContent(filters)
    .then(data =>
      Promise.all(
        data.results.map(analysis =>
          Promise.all(
            analysis.matches.map(matchUrl => api.getUrl(matchUrl)),
          ).then(matches => ({ ...analysis, matches })),
        ),
      ).then(results => ({ ...data, results })),
    );
}

export function* fetchLatestRoundsSaga(action) {
  yield fetchGeneric(
    fetchLatestRound,
    onlyIfMissing(state => latestRoundsSelector(state)),
    call(api.latestRound),
    action,
  );
}

export function* fetchATGJackpotsSaga(action) {
  yield fetchGeneric(
    fetchATGJackpots,
    onlyIfMissing(state => ATGJackpotsSelector(state)),
    call(api.getATGJackpots),
    action,
  );
}

export function* fetchAllRoundsSaga(action) {
  yield all(
    GAME_TYPES.map(gameType => call(fetchRoundSaga, { gameType }, action)),
  );
}

export function* fetchLineupsSaga(matchId, action) {
  yield fetchGeneric(
    fetchLineups.bind(undefined, matchId),
    onlyIfMissing(state => lineupsByMatchIdSelector(state)[matchId]),
    call(api.getMatchLineups, { id: matchId }),
    action,
  );
}

export function* fetchMatchStatisticsSaga(matchId, action) {
  yield fetchGeneric(
    fetchMatchStatistics.bind(undefined, matchId),
    onlyIfMissing(state => statisticsByMatchIdSelector(state)[matchId]),
    call(api.getMatchStatistics, { id: matchId }),
    action,
  );
}

export function* fetchTeamSaga(slug, action) {
  yield fetchGeneric(
    fetchTeam.bind(undefined, slug),
    onlyIfMissing(state => teamsBySlugSelector(state)[slug]),
    call(api.getTeam, { id: slug }),
    action,
  );
}

export function* fetchTeamStatisticsSaga(teamId, action) {
  yield fetchGeneric(
    fetchTeamStatistics.bind(undefined, teamId),
    onlyIfMissing(state => statisticsByTeamIdSelector(state)[teamId]),
    call(api.getTeamStatistics, { id: teamId }),
    action,
  );
}

export function* fetchTeamComingMatchesSaga(teamId, excludeMatch, action) {
  yield fetchGeneric(
    fetchTeamComingMatches.bind(undefined, teamId),
    onlyIfMissing(state => comingMatchesByTeamIdSelector(state)[teamId]),
    call(
      api.listTeamComingMatches,
      { id: teamId },
      { exclude_match: excludeMatch },
    ),
    action,
  );
}

export function* fetchTournamentsListSaga(action) {
  yield fetchGeneric(
    fetchTournamentsList,
    onlyIfMissing(tournamentsListSelector),
    call(api.listTournaments),
    action,
  );
}
