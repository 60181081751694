import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { HOME } from '../../main/actions/router';
import routesMap, { getCurrentPageBar, getTitle } from '../../main/routesMap';
import { locationPropType, locationSelector } from '../../main/selectors';
import CenteredLayout from '../CenteredLayout';
import CurrentPageBar from '../CurrentPageBar';
import ErrorBoundary from '../ErrorBoundary';
import Nav from '../Nav';
import NotFound from '../NotFound';
import PrivacyLinks from '../PrivacyLinks';
import SvSpelBar from '../SvSpelBar';
import SvSpelPixel from '../SvSpelPixel';
import styles from './styles.scss';

const TITLE_BASE = 'Överodds';
const TITLE_SEPARATOR = ' | ';

Root.propTypes = {
  location: locationPropType.isRequired,
  strippedPage: PropTypes.bool.isRequired,
};

/**
 * The root component.
 */
export function Root({ location, strippedPage }) {
  const route = routesMap[location.type];
  const PageComponent = route == null ? NotFound : route.component;

  if (strippedPage) {
    return (
      <ErrorBoundary>
        <PageComponent />
      </ErrorBoundary>
    );
  }

  const currentPageBarContent =
    route == null ? undefined : getCurrentPageBar(route, location.payload);
  const currentPageBar =
    currentPageBarContent == null ? undefined : (
      <CurrentPageBar>{currentPageBarContent}</CurrentPageBar>
    );

  const title = [
    ...(route == null ? [] : getTitle(route, location.payload)),
    TITLE_BASE,
  ].join(TITLE_SEPARATOR);

  return (
    <div className={styles.root}>
      <Helmet
        defaultTitle={title}
        titleTemplate={`%s${TITLE_SEPARATOR}${title}`}
      />
      <Nav location={location} border={currentPageBar == null} />
      {route?.svSpelBar && <SvSpelBar />}
      {currentPageBar}
      <ErrorBoundary>
        <div className={styles.main}>
          <PageComponent />
          <CenteredLayout>
            <PrivacyLinks isHomePage={location.type === HOME} />
          </CenteredLayout>
        </div>
      </ErrorBoundary>
      <SvSpelPixel />
    </div>
  );
}

export default connect(
  createStructuredSelector({
    location: locationSelector,
  }),
)(Root);
