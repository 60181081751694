import { createSelector } from 'reselect';

export const statisticsSelector = state => state.statistics;

export const filtersSelector = createSelector(
  statisticsSelector,
  statistics => statistics.filters,
);

export const teamsListSelector = createSelector(
  statisticsSelector,
  statistics => statistics.teamsList,
);

export const team1StatisticsSelector = createSelector(
  statisticsSelector,
  statistics => statistics.team1Statistics,
);

export const team2StatisticsSelector = createSelector(
  statisticsSelector,
  statistics => statistics.team2Statistics,
);

export const popularTeamsSelector = createSelector(
  statisticsSelector,
  statistics => statistics.popularTeams,
);

export const popularTournamentsSelector = createSelector(
  statisticsSelector,
  statistics => statistics.popularTournaments,
);

export const expandedOverUndersSelector = createSelector(
  statisticsSelector,
  statistics => statistics.expandedOverUnders,
);

export const querySelector = createSelector(
  statisticsSelector,
  statistics => statistics.query,
);
