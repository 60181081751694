import { createSelector } from 'reselect';

export const blogSelector = state => state.blog;

export const blogPostsSelector = createSelector(
  blogSelector,
  blog => blog.blogPostsById,
);

export const authorSelector = createSelector(
  blogSelector,
  blog => blog.authors,
);
