export const FETCH_COMPETITION = 'models/FETCH_COMPETITION';
export const FETCH_COMPETITION_COUPON = 'models/FETCH_COMPETITION_COUPON';
export const FETCH_COMPETITION_RESULT = 'models/FETCH_COMPETITION_RESULT';
export const FETCH_CONTENT = 'models/FETCH_CONTENT';
export const FETCH_ROUND = 'models/FETCH_ROUND';
export const FETCH_LATEST_ROUND = 'models/FETCH_LATEST_ROUND';
export const FETCH_LINEUPS = 'models/FETCH_LINEUPS';
export const FETCH_MATCH = 'models/FETCH_MATCH';
export const FETCH_MATCH_STATISTICS = 'models/FETCH_MATCH_STATISTICS';
export const FETCH_TEAM = 'models/FETCH_TEAM';
export const FETCH_TEAM_COMING_MATCHES = 'models/FETCH_TEAM_COMING_MATCHES';
export const FETCH_TEAM_STATISTICS = 'models/FETCH_TEAM_STATISTICS';
export const FETCH_TOURNAMENTS_LIST = 'models/FETCH_TOURNAMENTS_LIST';
export const FETCH_ATG_JACKPOTS = 'models/FETCH_ATG_JACKPOTS';

export const fetchCompetition = (slug, wrappedAction) => ({
  type: FETCH_COMPETITION,
  slug,
  wrappedAction,
});

export const fetchCompetitionCoupon = (slug, wrappedAction) => ({
  type: FETCH_COMPETITION_COUPON,
  slug,
  wrappedAction,
});

export const fetchCompetitionResult = (slug, wrappedAction) => ({
  type: FETCH_COMPETITION_RESULT,
  slug,
  wrappedAction,
});

export const fetchContent = (slug, wrappedAction) => ({
  type: FETCH_CONTENT,
  slug,
  wrappedAction,
});

export const fetchRound = (roundType, roundId, wrappedAction) => ({
  type: FETCH_ROUND,
  roundType,
  roundId,
  wrappedAction,
});

export const fetchLatestRound = wrappedAction => ({
  type: FETCH_LATEST_ROUND,
  wrappedAction,
});

export const fetchATGJackpots = wrappedAction => ({
  type: FETCH_ATG_JACKPOTS,
  wrappedAction,
});

export const fetchLineups = (matchId, wrappedAction) => ({
  type: FETCH_LINEUPS,
  matchId,
  wrappedAction,
});

export const fetchMatchStatistics = (matchId, wrappedAction) => ({
  type: FETCH_MATCH_STATISTICS,
  matchId,
  wrappedAction,
});

export const fetchMatch = (roundMatchId, wrappedAction) => ({
  type: FETCH_MATCH,
  roundMatchId,
  wrappedAction,
});

export const fetchTeam = (slug, wrappedAction) => ({
  type: FETCH_TEAM,
  slug,
  wrappedAction,
});

export const fetchTeamComingMatches = (teamId, wrappedAction) => ({
  type: FETCH_TEAM_COMING_MATCHES,
  teamId,
  wrappedAction,
});

export const fetchTeamStatistics = (teamId, wrappedAction) => ({
  type: FETCH_TEAM_STATISTICS,
  teamId,
  wrappedAction,
});

export const fetchTournamentsList = wrappedAction => ({
  type: FETCH_TOURNAMENTS_LIST,
  wrappedAction,
});
