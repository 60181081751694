import { all, put, takeEvery } from 'redux-saga/effects';

import {
  TRACK_BETTIP_REFERRAL_CLICK,
  TRACK_CLICK_READ_MORE_BLOG,
  TRACK_OPEN_BETTIP,
  TRACK_VISIT_BLOG,
} from '../actions/analytics';
import { eventActions, eventCategories, GTM_EVENT } from '../eventsMap';

const gtmAction = payload => ({
  type: GTM_EVENT,
  ...payload,
});

export function* analyticsSaga(action) {
  let events = [];
  switch (action.type) {
    case TRACK_BETTIP_REFERRAL_CLICK: {
      const { betTip, provider } = action;
      events = [
        [
          eventCategories.BETTIP_SPORT,
          eventActions.BETTIP_REFERRAL,
          betTip.bet_offer?.event?.sport,
        ],
        provider
          ? [
              eventCategories.BETTIP_PROVIDER,
              eventActions.BETTIP_REFERRAL_PROVIDER_LINK,
              provider,
            ]
          : [
              eventCategories.BETTIP_PROVIDER,
              eventActions.BETTIP_REFERRAL,
              betTip?.provider,
            ],
        [
          eventCategories.BETTIP_TIPSTER,
          eventActions.BETTIP_REFERRAL,
          betTip.tipster?.name,
        ],
        [
          eventCategories.BETTIP_REFERRAL,
          betTip.tipster?.name,
          betTip.headline,
        ],
      ];
      break;
    }
    case TRACK_OPEN_BETTIP: {
      const { betTip } = action;
      events = [
        [
          eventCategories.BETTIP_SPORT,
          eventActions.OPEN_BETTIP,
          betTip.bet_offer?.event?.sport,
        ],
        [
          eventCategories.BETTIP_TIPSTER,
          eventActions.OPEN_BETTIP,
          betTip.tipster?.name,
        ],
        [eventCategories.OPEN_BETTIP, betTip.tipster?.name, betTip.headline],
      ];
      break;
    }
    case TRACK_CLICK_READ_MORE_BLOG: {
      const { article } = action;
      events = [
        [
          eventCategories.CLICK_EVENTS,
          eventActions.CLICK_READ_MORE_BLOG_HOME_PAGE,
          `${article.authors[0]?.firstname} ${article.authors[0]?.lastname}`,
        ],
      ];
      break;
    }
    case TRACK_VISIT_BLOG: {
      const { article } = action;
      events = [
        [
          eventCategories.VISIT_BLOG,
          `${article.authors[0]?.firstname} ${article.authors[0]?.lastname}`,
          article.headline,
        ],
      ];
      break;
    }
    default:
      return;
  }

  yield all(
    events.map(([eventCategory, eventAction, eventLabel]) =>
      put(gtmAction({ eventCategory, eventAction, eventLabel })),
    ),
  );
}

export default function* rootSaga() {
  yield takeEvery(
    [
      TRACK_BETTIP_REFERRAL_CLICK,
      TRACK_OPEN_BETTIP,
      TRACK_CLICK_READ_MORE_BLOG,
      TRACK_VISIT_BLOG,
    ],
    analyticsSaga,
  );
}
