import { DEFAULT } from '../../../api';
import { FILTER_CHANGE, TOGGLE_TABLE } from './actions';

const initialState = {
  filters: {
    yearFrom: undefined,
    yearTo: undefined,
    numMatches: DEFAULT,
  },
  expandedTablesById: {},
};

export default function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case FILTER_CHANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      };

    case TOGGLE_TABLE: {
      const { [action.id]: previousValue = false } = state.expandedTablesById;

      return {
        ...state,
        expandedTablesById: {
          ...state.expandedTablesById,
          [action.id]: !previousValue,
        },
      };
    }

    default:
      return state;
  }
}
