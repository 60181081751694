import { isToday, isTomorrow } from 'date-fns';
import formatDateLib from 'date-fns/format';
import svLocale from 'date-fns/locale/sv';

import config from '../main/config';

const SLUG_SEPARATOR_REGEX = /[\s-]+/;

const ORIGIN_REGEX = /^\w+:\/\/([^/]+)/;

/**
 * Returns a complete URL (affiliate branding) for SvenskaSpel
 * p - program id - 295103
 * a - affiliate id - 3165053
 */

export function getSvSpelAffiliateLink(dest) {
  const currLocation = window.location.href;

  let url = dest;

  if (dest.includes('url=')) {
    url = new URL(dest).searchParams.get('url');
  }

  const partA = `https://clk.tradedoubler.com/click?p=295103&a=3165053&epi=&epi2=${currLocation}&url=${url}`;
  const partB = `https://clk.tradedoubler.com/click?f=0&p=353618&a=89693&epi=3165053_0&epi2=${currLocation}&url=`;

  return `${partB}${encodeURIComponent(partA)}`;
}

/** Impressions aren't counted using just the affiliate link
 *  this is trade doubler's way of counting impressions
 */
export function getImageImpressionUrl() {
  return `https://clk.tradedoubler.com/click?imp&f=0&p=353617&a=89693&g=25614120&epi=3165053_0&epi2=${encodeURIComponent(
    window.location.href,
  )}`;
}

/**
 * Returns a complete url (affiliate branding) for Unibet
 */

export function getUnibetAffiliateLink(link) {
  return `${link}?coupon=combination%7C3167822017%7C500%7Creplace`;
}

/**
 * Adds `queryString` at the end of `url` (which might already have a query
 * string), taking care of the question mark.
 */
export function addQueryString(url, queryString) {
  const strippedUrl = url.replace(/\?$/, '');
  const separator = strippedUrl.includes('?') ? '&' : '?';
  return queryString === '' ? url : `${strippedUrl}${separator}${queryString}`;
}

/**
 * Turns `array` into an object. `fn` is called on every item in the array and
 * must return `[key, value]`.
 */
export function arrayToObject(array, fn) {
  const object = Object.create(null);

  for (const item of array) {
    const [key, value] = fn(item);
    object[key] = value;
  }

  return object;
}

/**
 * Returns `string` with the first letter uppercase and all the others lowercase.
 */
export function capitalize(string) {
  return string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Turns `{foo: true, bar: false, baz: true}` into `'foo baz'`.
 */
export function classList(mapping) {
  return Object.keys(mapping)
    .filter(className => mapping[className])
    .join(' ');
}

/**
 * Returns a new function that can be called any number of times, but only calls
 * `fn` once per `timeout` milliseconds.
 */
export function debounce(fn, timeout = 50) {
  let timeoutId = null;

  return (...args) => {
    if (timeoutId != null) {
      return;
    }

    timeoutId = window.setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, timeout);
  };
}

/**
 * Format a Date into a string.
 *
 * Formatting docs: https://date-fns.org/docs/format
 */
export function formatDate(date, format = "d MMMM, yyyy 'kl.' HH:mm") {
  return formatDateLib(date, format, { locale: svLocale });
}

export function getRelativeDateFormat(date, format) {
  if (isToday(date)) {
    return "'Idag' 'kl.' HH:mm";
  }
  if (isTomorrow(date)) {
    return "'Imorgon' 'kl.' HH:mm";
  }
  return format;
}

/**
 * Format a number with a maximum number of decimals, but with no trailing
 * decimal zeroes.
 */
export function formatMaxDecimals(number, numDecimals) {
  return number
    .toFixed(numDecimals)
    .replace(/(\.[1-9]*)0+$/, '$1')
    .replace(/\.$/, '');
}

/**
 * Returns just the domain of `url`, for display in a link to `url`. (Returns
 * `url` unchanged if the domain part cannot be found.)
 */
export function getLinkText(url) {
  const match = ORIGIN_REGEX.exec(url);
  return match == null ? url : match[1];
}

/**
 * Logged in users can oddly enough lack both first and last name. This function
 * lets you pretend they always have a name.
 */
export function getUserName(user) {
  const parts = [user.first_name, user.last_name].filter(Boolean);
  return parts.length === 0 ? '(anonym)' : parts.join(' ');
}

/**
 * Turns `array` into a `Map` using `fn` to turn items into keys which map to
 * arrays of all items with the same key.
 */
export function groupBy(array, fn) {
  const result = new Map();

  for (const item of array) {
    const key = fn(item);
    const previous = result.get(key) || [];
    previous.push(item);
    result.set(key, previous);
  }

  return result;
}

/**
 * Turns an object with string, undefined or array-of-string values to a URL
 * query string.
 */
export function makeQueryString(data) {
  return Object.entries(data)
    .flatMap(([key, value]) =>
      value == null
        ? []
        : Array.isArray(value)
        ? value.map(subValue => [key, subValue])
        : [[key, value]],
    )

    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    )
    .join('&');
}

/**
 * Because `noop` is nicer to read than `() => {}`.
 */
export function noop() {
  // Do nothing.
}

/**
 * Simlilar a to Django’s `|pluralize` filter.
 *
 * Example usage:
 *
 *     `intervju${pluralize(1, ["", "er"])}` // intervju
 *     `intervju${pluralize(2, ["", "er"])}` // intervjuer
 *     pluralize(numBikes, ["cykel", "cyklar"])
 */
export function pluralize(count, choices) {
  return count === 1 ? choices[0] : choices[1];
}

/**
 * Returns `[fn(0), fn(1), fn(2), ..., fn(n - 1)]`.
 */
export function range(n, fn) {
  return Array.from({ length: n }, (_, i) => fn(i));
}

/**
 * Returns the sum of the numbers in `array`.
 */
export function sum(array) {
  return array.reduce((a, b) => a + b, 0);
}

/**
 * Returns a new function that can be called any number of times, but only calls
 * `fn` after `timeout` milliseconds has passed since the last call.
 */
export function throttle(fn, timeout = 250) {
  let timeoutId = null;

  return (...args) => {
    if (timeoutId != null) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, timeout);
  };
}

/**
 * Returns `array` with all duplicate values removed. Optionally uses `fn` to
 * transform array items before comparing. Favors earlier values.
 */
export function unique(array, fn) {
  const seen = new Set();

  return array.filter(item => {
    const key = fn ? fn(item) : item;

    if (seen.has(key)) {
      return false;
    }

    seen.add(key);
    return true;
  });
}

/**
 * Makes a slug more human readable.
 *
 * `uppercase` can be one of "first", "every" and "smart".
 */
export function unslugify(slug, { uppercase = 'first', asArray = false } = {}) {
  const words = slug.split(SLUG_SEPARATOR_REGEX).filter(word => word !== '');

  const caseAdjustedWords =
    uppercase === 'smart'
      ? words.map(word =>
          word.length <= 3
            ? // Uppercase AIK, BK, FF, FK, GIF, IF, IFK, IK, SK, etc.
              word.toUpperCase()
            : // Capitalize other words.
              capitalize(word),
        )
      : uppercase === 'every'
      ? words.map(word => capitalize(word))
      : words.map((word, index) =>
          index === 0 ? capitalize(word) : word.toLowerCase(),
        );

  return asArray ? caseAdjustedWords : caseAdjustedWords.join(' ');
}

/**
 * Turn two lists into one list of tuples. If one list is longer, drop its
 * extraneous items.
 */
export function zip(listA, listB) {
  return range(Math.min(listA.length, listB.length), index => [
    listA[index],
    listB[index],
  ]);
}

export const formatNumber = (
  value,
  {
    useGrouping = true,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  } = {},
) => {
  let result;

  try {
    result = new Intl.NumberFormat('sv-SE', {
      useGrouping,
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(value);
  } catch {
    result = value != null ? String(value) : value;
  }
  return result;
};

export const registerUrl = () =>
  `${config.AUTH_URL}/register?dest=${encodeURI(window.location.href)}`;

export const loginUrl = () =>
  `/oauth/authorize?dest=${encodeURI(window.location.href)}`;

export const logoutUrl = () =>
  `${config.AUTH_URL}/logout?dest=${encodeURI(window.location.href)}`;
