import { call, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { PLAY_TOGETHER } from '../../main/actions/router';
import {
  alwaysFetch,
  fetchGeneric,
  onlyIfMissing,
} from '../../main/sagas/genericFetch';
import { userSelector } from '../../main/selectors/auth';
import {
  DELETE_SYNDICATE,
  FETCH_USER_SYNDICATES_LIST,
  fetchSyndicatesList,
  fetchUserSyndicates,
  handleDeleteSyndicate,
  SUBMIT_SYNDICATE_FORM,
  submitSyndicate,
  SYNDICATE_FORM_CLOSE,
} from './actions';
import { syndicatesSelector, userSyndciatesSelector } from './selectors';

export default function* rootSaga() {
  yield takeLatest(
    [PLAY_TOGETHER, SYNDICATE_FORM_CLOSE],
    fetchSyndicatesListSaga,
  );
  yield takeLatest(
    [FETCH_USER_SYNDICATES_LIST, SYNDICATE_FORM_CLOSE],
    fetchUserSyndicatesListSaga,
  );
  yield takeLatest(SUBMIT_SYNDICATE_FORM, submitSyndicateFormSaga);
  yield takeLatest(DELETE_SYNDICATE, deleteSyndicateSaga);
}

export function* fetchSyndicatesListSaga(action) {
  yield fetchGeneric(
    fetchSyndicatesList,
    action.shouldOverride ? alwaysFetch : onlyIfMissing(syndicatesSelector),
    call(api.getSyndicates),
    action,
  );
}

export function* fetchUserSyndicatesListSaga(action) {
  const user = yield select(userSelector);

  yield fetchGeneric(
    fetchUserSyndicates,
    action.shouldOverride ? alwaysFetch : onlyIfMissing(userSyndciatesSelector),
    call(api.getSyndicates, user.data.uid),
    action,
  );
}

export function* submitSyndicateFormSaga(action) {
  const { formData } = action;

  const method = formData?.id ? api.updateSyndicate : api.createSyndicate;

  yield fetchGeneric(
    submitSyndicate,
    alwaysFetch,
    call(method, formData),
    action,
  );
}

export function* deleteSyndicateSaga(action) {
  const { id } = action;

  yield fetchGeneric(
    handleDeleteSyndicate,
    alwaysFetch,
    call(api.deleteSyndicate, id),
    action,
  );
}
