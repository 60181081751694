import PropTypes from 'prop-types';
import { Component } from 'react';

import { getWindowSize, onWindowResize } from '../../utils/dom';

const propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  onResize: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

const instances = new Set();

/**
 * Render something based on the window size. Automatically re-renders when the
 * window size changes. Good for doing responsive stuff when CSS is not enough.
 */
export default class WithWindowSize extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowSize: getWindowSize(),
    };
  }

  componentDidMount() {
    instances.add(this);
  }

  componentWillUnmount() {
    instances.delete(this);
  }

  render() {
    const { children } = this.props;
    const { windowSize } = this.state;

    if (typeof children === 'function') {
      return children(windowSize);
    }

    return children;
  }
}

WithWindowSize.propTypes = propTypes;

// Add only one resize listener and batch updates.
onWindowResize(() => {
  for (const instance of instances) {
    const { onResize } = instance.props;

    instance.setState({ windowSize: getWindowSize() });

    if (onResize) {
      onResize();
    }
  }
});
