export const RESULT_INPUT_BLUR = 'competition/RESULT_INPUT_BLUR';
export const RESULT_INPUT_CHANGE = 'competition/RESULT_INPUT_CHANGE';
export const SUBMIT = 'competition/SUBMIT';
export const HAS_CLICKED_SUBMIT = 'competition/HAS_CLICKED_SUBMIT';
export const POST_COUPON = 'competition/POST_COUPON';
export const RESET_ENTERED_RESULTS = 'competition/RESET_ENTERED_RESULTS';
export const READ_FROM_LOCAL_STORAGE = 'competition/READ_FROM_LOCAL_STORAGE';

export const resultInputBlur = ({ matchId, homeAway }) => ({
  type: RESULT_INPUT_BLUR,
  matchId,
  homeAway,
});

export const resultInputChange = ({ matchId, homeAway, value }) => ({
  type: RESULT_INPUT_CHANGE,
  matchId,
  homeAway,
  value,
});

export const submit = () => ({
  type: SUBMIT,
});

export const hasClickedSubmit = () => ({
  type: HAS_CLICKED_SUBMIT,
});

export const postCoupon = wrappedAction => ({
  type: POST_COUPON,
  wrappedAction,
});

export const resetEnteredResults = () => ({
  type: RESET_ENTERED_RESULTS,
});

export const readFromLocalStorage = ({ enteredResultsByMatchId }) => ({
  type: READ_FROM_LOCAL_STORAGE,
  enteredResultsByMatchId,
});
