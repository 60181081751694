import {
  FETCH_ATG_JACKPOTS,
  FETCH_COMPETITION,
  FETCH_COMPETITION_COUPON,
  FETCH_COMPETITION_RESULT,
  FETCH_CONTENT,
  FETCH_LATEST_ROUND,
  FETCH_LINEUPS,
  FETCH_MATCH,
  FETCH_MATCH_STATISTICS,
  FETCH_ROUND,
  FETCH_TEAM,
  FETCH_TEAM_COMING_MATCHES,
  FETCH_TEAM_STATISTICS,
  FETCH_TOURNAMENTS_LIST,
} from '../actions/models';
import { genericFetchInitialState, genericFetchReducer } from './genericFetch';

// This reducer stores data models fetched from the API. They can be used in
// several different pages. If you need a data model, chances are you want to
// grab it from here (or fetch and save here). Sometimes pages have their own
// lists of content, though, depending on the API and use case.
const initialState = {
  competitionsBySlug: {},
  competitionCouponsBySlug: {},
  competitionResultsBySlug: {},
  comingMatchesByTeamId: {},
  contentBySlug: {},
  lineupsByMatchId: {},
  matchesByRoundMatchId: {},
  statisticsByMatchId: {},
  statisticsByTeamId: {},
  teamsBySlug: {},
  tournamentsList: genericFetchInitialState,
  carouselSlides: {},
  atgJackpots: genericFetchInitialState,
  latestRounds: genericFetchInitialState,
  rounds: {
    europatipset: {
      latest: genericFetchInitialState,
    },
    stryktipset: {
      latest: genericFetchInitialState,
    },
    topptipset: {
      latest: genericFetchInitialState,
    },
    powerplay: {
      latest: genericFetchInitialState,
    },
  },
};

export default function modelsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPETITION:
      return {
        ...state,
        competitionsBySlug: {
          ...state.competitionsBySlug,
          [action.slug]: genericFetchReducer(
            state.competitionsBySlug[action.slug],
            action.wrappedAction,
          ),
        },
      };

    case FETCH_COMPETITION_COUPON:
      return {
        ...state,
        competitionCouponsBySlug: {
          ...state.competitionCouponsBySlug,
          [action.slug]: genericFetchReducer(
            state.competitionCouponsBySlug[action.slug],
            action.wrappedAction,
          ),
        },
      };

    case FETCH_COMPETITION_RESULT:
      return {
        ...state,
        competitionResultsBySlug: {
          ...state.competitionResultsBySlug,
          [action.slug]: genericFetchReducer(
            state.competitionResultsBySlug[action.slug],
            action.wrappedAction,
          ),
        },
      };

    case FETCH_CONTENT:
      return {
        ...state,
        contentBySlug: {
          ...state.contentBySlug,
          [action.slug]: genericFetchReducer(
            state.contentBySlug[action.slug],
            action.wrappedAction,
          ),
        },
      };

    case FETCH_LATEST_ROUND:
      return {
        ...state,
        latestRounds: {
          ...state.latestRounds,
          data: action.wrappedAction.data,
        },
      };

    case FETCH_ATG_JACKPOTS:
      return {
        ...state,
        atgJackpots: {
          ...state.atgJackpots,
          data: action.wrappedAction.data,
        },
      };

    case FETCH_ROUND: {
      const { roundType, roundId, wrappedAction } = action;
      const key = roundId == null ? 'latest' : roundId;

      return {
        ...state,
        rounds: {
          ...state.rounds,
          [roundType]: {
            ...state.rounds[roundType],
            [key]: genericFetchReducer(
              state.rounds[roundType][key],
              wrappedAction,
            ),
          },
        },
      };
    }

    case FETCH_LINEUPS:
      return {
        ...state,
        lineupsByMatchId: {
          ...state.lineupsByMatchId,
          [action.matchId]: genericFetchReducer(
            state.lineupsByMatchId[action.matchId],
            action.wrappedAction,
          ),
        },
      };

    case FETCH_MATCH:
      return {
        ...state,
        matchesByRoundMatchId: {
          ...state.matchesByRoundMatchId,
          [action.roundMatchId]: genericFetchReducer(
            state.matchesByRoundMatchId[action.roundMatchId],
            action.wrappedAction,
          ),
        },
      };
    case FETCH_MATCH_STATISTICS:
      return {
        ...state,
        statisticsByMatchId: {
          ...state.statisticsByMatchId,
          [action.matchId]: genericFetchReducer(
            state.statisticsByMatchId[action.matchId],
            action.wrappedAction,
          ),
        },
      };
    case FETCH_TEAM:
      return {
        ...state,
        teamsBySlug: {
          ...state.teamsBySlug,
          [action.slug]: genericFetchReducer(
            state.teamsBySlug[action.slug],
            action.wrappedAction,
          ),
        },
      };

    case FETCH_TEAM_COMING_MATCHES:
      return {
        ...state,
        comingMatchesByTeamId: {
          ...state.comingMatchesByTeamId,
          [action.teamId]: genericFetchReducer(
            state.comingMatchesByTeamId[action.teamId],
            action.wrappedAction,
          ),
        },
      };

    case FETCH_TEAM_STATISTICS:
      return {
        ...state,
        statisticsByTeamId: {
          ...state.statisticsByTeamId,
          [action.teamId]: genericFetchReducer(
            state.statisticsByTeamId[action.teamId],
            action.wrappedAction,
          ),
        },
      };

    case FETCH_TOURNAMENTS_LIST:
      return {
        ...state,
        tournamentsList: genericFetchReducer(
          state.tournamentsList,
          action.wrappedAction,
        ),
      };

    default:
      return state;
  }
}
