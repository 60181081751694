import PropTypes from 'prop-types';

import { classList } from '../../utils';
import styles from './styles.scss';

const supportedNumbers = [0, 1, 2, 3, 4];

VerticalSpacing.propTypes = {
  padding: PropTypes.oneOf(supportedNumbers),
  between: PropTypes.oneOf(supportedNumbers),
  center: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

/**
 * Adds some space at the top and bottom of a container, as well as between all
 * children. Both values are optional.
 */
export default function VerticalSpacing({
  padding = 0,
  between = 0,
  center = false,
  className = '',
  children = null,
}) {
  return (
    <div
      className={classList({
        [styles[`between-${between}`]]: true,
        [styles[`padding-${padding}`]]: true,
        [styles.center]: center,
        [className]: true,
      })}
    >
      {children}
    </div>
  );
}
