import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { actionToPath, NOT_FOUND } from 'redux-first-router';
import Link from 'redux-first-router-link';
import { createStructuredSelector } from 'reselect';

import logo from '../../images/logo.svg';
import {
  toAbout,
  toAnalyses,
  toAnalysesAdmin,
  toBig9,
  toBlog,
  toCompetition,
  toGameStartPage,
  toHome,
  toPlayReduced,
  toPlayTogether,
  toProfilePage,
  toSchool,
  toSelectEvent,
  toSharedBets,
  toSoccer,
  toTipsters,
  toWorldCup,
} from '../../main/actions/router';
import config from '../../main/config';
import routesMap, { getNav } from '../../main/routesMap';
import { locationPropType } from '../../main/selectors';
import { userSelector } from '../../main/selectors/auth';
import {
  classList,
  getUserName,
  loginUrl,
  logoutUrl,
  registerUrl,
} from '../../utils';
import { GAME_TYPES } from '../../utils/productUtil';
import {
  authenticatedTipsterSelector,
  uncorrectedSelector,
} from '../../views/analyses/selectors';
import Burger from '../Burger';
import Chevron from '../Chevron';
import TabBar, { SPACER } from '../TabBar';
import UncorrectedBetTipsBadge from '../UncorrectedBetTipsBadge';
import WithWindowSize from '../WithWindowSize';
import BurgerButton from './BurgerButton';
import NavDropdown from './NavDropdown';
import NavDropdownTabBarChild from './NavDropdownTabBarChild';
import styles from './styles.scss';
import UserItem from './UserItems';

function to(toAction, render = title => title) {
  const route = routesMap[toAction.type];
  return {
    to: toAction,
    children: render(route == null ? '?' : getNav(route, toAction.payload)),
  };
}

const useDropdown = location =>
  useCallback(
    ({
      alignRight = false,
      children,
      items,
      className,
      noLink,
      underline = true,
      activeStyling = true,
    }) => ({
      to: undefined,
      children,
      items,
      isActive: activeStyling
        ? () =>
            location.type !== NOT_FOUND &&
            items.some(
              item =>
                item.to != null &&
                `${location.pathname}/`.startsWith(
                  actionToPath(item.to, routesMap),
                ),
            )
        : undefined,
      render: tabNode => (
        <NavDropdown alignRight={alignRight} tabNode={tabNode} items={items} />
      ),
      className,
      noLink,
      underline,
    }),
    [location.pathname, location.type],
  );

/**
 * The top page navigation.
 */

const Nav = ({
  location,
  border,
  user,
  authenticatedTipster = false,
  adminUncorrected = {},
}) => {
  const dropdown = useDropdown(location);

  const loggedInUserItems = user.data
    ? [
        // Profile link if user is a tipster
        authenticatedTipster && {
          to: toProfilePage({ slug: authenticatedTipster.slug }),
          children: (
            <div className={styles.myProfileTab}>
              <span>Min profil</span>
              <UncorrectedBetTipsBadge
                count={authenticatedTipster.uncorrected_bet_tips}
              />
            </div>
          ),
        },
        // This link redirects to the profile page
        {
          href: config.USERPAGES_URL,
          children: 'Mitt TR Media-konto',
        },
        // Create bet if user is a tipster
        authenticatedTipster && to(toSelectEvent()),
        // Admin link if user is a Överodds admin
        Object.prototype.hasOwnProperty.call(user.data.groups, 'overodds') &&
          user.data.groups.overodds.includes('admin') &&
          adminUncorrected.data && {
            to: toAnalysesAdmin(),
            children: (
              <div className={styles.myProfileTab}>
                <span>Administrera spelrekar</span>
                <UncorrectedBetTipsBadge count={adminUncorrected.data.length} />
              </div>
            ),
          },
        // Logout link
        {
          href: logoutUrl(),
          children: 'Logga ut',
        },
      ].filter(Boolean)
    : [];

  const loggedInUser = user.data ? (
    <UserItem
      title={user.fetching ? 'Loggar ut…' : getUserName(user.data)}
      loading={user.fetching}
      className={styles.loggedIn}
    />
  ) : null;

  const loginItems = [
    user.data == null
      ? {
          href: registerUrl(),
          className: 'register',
          children: 'Registrera dig',
        }
      : undefined,
    user.data == null
      ? {
          href: loginUrl(),
          className: 'login',
          children: (
            <UserItem
              title={user.fetching ? 'Loggar in…' : 'Logga in'}
              loading={user.fetching}
            />
          ),
        }
      : dropdown({
          alignRight: true,
          underline: false,
          children: loggedInUser,
          items: user.data ? loggedInUserItems : [],
          activeStyling: false,
        }),
  ];

  const worldCupItems = [
    config.WORLD_CUP_ENABLED ? to(toWorldCup()) : undefined,
    config.COMPETITION_SLUG == null ? undefined : to(toCompetition()),
  ].filter(Boolean);

  const tabs = useCallback(
    device =>
      [
        dropdown({
          children:
            device === 'desktop' ? (
              <NavDropdownTabBarChild
                icon={open => (
                  <Chevron
                    className={styles.chevronIcon}
                    direction={open ? 'up' : 'down'}
                  />
                )}
                text="Speltips"
              />
            ) : (
              'Speltips'
            ),
          items: [
            to(toAnalyses()),
            to(toBlog()),
            to(toBig9()),
            ...GAME_TYPES.map(gameType => to(toGameStartPage({ gameType }))),
          ],
        }),
        to(toPlayTogether()),
        to(toPlayReduced()),
        to(toTipsters()),
        worldCupItems.length === 0
          ? undefined
          : worldCupItems.length === 1
          ? worldCupItems[0]
          : dropdown({
              children: 'Fotbolls-VM',
              items: worldCupItems,
            }),
        dropdown({
          children:
            device === 'desktop' ? (
              <NavDropdownTabBarChild
                icon={open => <Burger barGap={3} cross={open} />}
                text="Mer"
              />
            ) : (
              'Mer'
            ),
          items: [
            to(toSchool()),
            to(toSharedBets()),
            to(toSoccer()),
            to(toAbout()),
            {
              onClick: () => {
                window.OneTrust && window.OneTrust.ToggleInfoDisplay();
              },
              children: 'Cookie-inställningar',
            },
          ],
        }),
      ].filter(Boolean),
    [dropdown, worldCupItems],
  );

  const createEvent =
    user.data && authenticatedTipster ? { ...to(toSelectEvent()) } : null;

  const tabsDesktop = [
    ...tabs('desktop'),
    SPACER,
    createEvent,
    ...loginItems,
  ].filter(Boolean);

  const tabsMobile = [
    ...tabs('mobile'),
    user.data
      ? dropdown({
          children: loggedInUser,
          className: styles.userSeparator,
          noLink: true,
        })
      : undefined,
    ...loggedInUserItems,
  ].filter(Boolean);

  return (
    <WithWindowSize>
      {windowSize => (
        <nav
          className={classList({
            [styles.root]: true,
            [styles.border]: border,
          })}
        >
          <Link to={toHome()} className={styles.logoWrapper}>
            <img src={logo} alt="Överodds" className={styles.logo} />
          </Link>

          {windowSize.navHorizontalAndUp ? (
            <TabBar tabs={tabsDesktop} />
          ) : (
            <div className={styles.navCollapsed}>
              <NavDropdown
                alignRight
                tabNode={<BurgerButton />}
                items={tabsMobile}
                user={user}
              />
            </div>
          )}
        </nav>
      )}
    </WithWindowSize>
  );
};

Nav.propTypes = {
  location: locationPropType.isRequired,
  border: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  adminUncorrected: PropTypes.object,
  authenticatedTipster: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default connect(
  createStructuredSelector({
    user: userSelector,
    authenticatedTipster: authenticatedTipsterSelector,
    adminUncorrected: uncorrectedSelector,
  }),
)(Nav);
