import {
  genericFetchInitialState,
  genericFetchKeyedReducer,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import { FETCH_LEAGUE_POSTS, FETCH_TEAM_POSTS } from './actions';

const initialState = {
  leagues: genericFetchInitialState,
  teamsByLeagueTag: {},
};

export default function soccerReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LEAGUE_POSTS:
      return {
        ...state,
        leagues: genericFetchReducer(state.leagues, action.wrappedAction),
      };

    case FETCH_TEAM_POSTS:
      return genericFetchKeyedReducer(
        state,
        'teamsByLeagueTag',
        action.tag,
        action.wrappedAction,
      );

    default:
      return state;
  }
}
