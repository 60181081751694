import {
  genericFetchInitialState,
  genericFetchKeyedReducer,
  genericFetchMoreReducer,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  FETCH_BETTING_SCHOOL,
  FETCH_MORE_SCHOOL_POSTS,
  FETCH_SCHOOL_TIPSTERS,
} from './actions';

const initialState = {
  byId: {},
  schoolTipsters: genericFetchInitialState,
};

export default function tipsterReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BETTING_SCHOOL:
      return genericFetchKeyedReducer(
        state,
        'byId',
        action.id ?? 'all',
        action.wrappedAction,
      );
    case FETCH_SCHOOL_TIPSTERS:
      return {
        ...state,
        schoolTipsters: genericFetchReducer(
          state.schoolTipsters,
          action.wrappedAction,
        ),
      };
    case FETCH_MORE_SCHOOL_POSTS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: genericFetchMoreReducer(
            state.byId[action.id],
            action.wrappedAction,
          ),
        },
      };
    }
    default:
      return state;
  }
}
