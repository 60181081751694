import {
  genericFetchInitialState,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  FETCH_ANALYSES,
  FETCH_DEFAULT_ANALYSIS,
  QUERY_CHANGE,
  TOGGLE_SIDEBAR,
} from './actions';

const initialState = {
  // Whether or not the sidebar is visible (only matters for smaller screens).
  sidebarOpen: false,
  defaultAnalysis: genericFetchInitialState,
  analyses: genericFetchInitialState,
  query: '',
};

export default function gameReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ANALYSES:
      return {
        ...state,
        analyses: genericFetchReducer(state.analyses, action.wrappedAction),
      };

    case FETCH_DEFAULT_ANALYSIS:
      return {
        ...state,
        defaultAnalysis: genericFetchReducer(
          state.defaultAnalysis,
          action.wrappedAction,
        ),
      };

    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarOpen: action.open,
      };
    }

    case QUERY_CHANGE: {
      return {
        ...state,
        query: action.query,
      };
    }

    default:
      return state;
  }
}
