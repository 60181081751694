import { createSelector } from 'reselect';

import { gameSelector } from '../selectors';

export const statisticsSelector = createSelector(
  gameSelector,
  game => game.statistics,
);

export const expandedTablesByIdSelector = createSelector(
  statisticsSelector,
  statistics => statistics.expandedTablesById,
);

export const statisticsFiltersSelector = createSelector(
  statisticsSelector,
  statistics => statistics.filters,
);
