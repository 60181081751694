import {
  genericFetchInitialState,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  FETCH_SYNDICATES_LIST,
  HANDLE_DELETE_SYNDICATE,
  HANDLE_SUBMIT,
  HANDLE_USER_SYNDICATES,
  SYNDICATE_FORM_CLOSE,
} from './actions';

const initialState = {
  syndicates: genericFetchInitialState,
  userSyndicates: genericFetchInitialState,
  createdSyndicate: genericFetchInitialState,
  deletedSyndicate: genericFetchInitialState,
};

export default function syndicatesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SYNDICATES_LIST:
      return {
        ...state,
        syndicates: genericFetchReducer(state.syndicates, action.wrappedAction),
      };

    case HANDLE_USER_SYNDICATES:
      return {
        ...state,
        userSyndicates: genericFetchReducer(
          state.createdSyndicate,
          action.wrappedAction,
        ),
      };

    case HANDLE_SUBMIT:
      return {
        ...state,
        createdSyndicate: genericFetchReducer(
          state.createdSyndicate,
          action.wrappedAction,
        ),
      };

    case HANDLE_DELETE_SYNDICATE:
      return {
        ...state,
        deletedSyndicate: genericFetchReducer(
          state.deletedSyndicate,
          action.wrappedAction,
        ),
      };

    case SYNDICATE_FORM_CLOSE:
      return {
        ...state,
        createdSyndicate: genericFetchInitialState,
        deletedSyndicate: genericFetchInitialState,
      };

    default:
      return state;
  }
}
