import PropTypes from 'prop-types';

import UserIcon from '../../icons/user.svg';
import Icon from '../Icon';
import Spinner from '../Spinner';
import { Underline } from '../TabBar';
import styles from './styles.scss';

const UserItem = ({ title, loading = false, className = '' }) => (
  <span className={`${className} ${styles.flexDiv}`}>
    <div>
      {loading ? (
        <Spinner className={styles.loginIcon} />
      ) : (
        <Icon icon={UserIcon} className={styles.loginIcon} />
      )}
    </div>
    <Underline>{title}</Underline>
  </span>
);

UserItem.propTypes = {
  title: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default UserItem;
