import { call, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { LEAGUE_POST, SOCCER, TEAM_POST } from '../../main/actions/router';
import { fetchGeneric, onlyIfMissing } from '../../main/sagas/genericFetch';
import { fetchContentSagaHelper } from '../../main/sagas/models';
import { fetchLeaguePosts, fetchTeamPosts, START_FETCH_TEAMS } from './actions';
import { selectLeagues, selectTeams } from './selectors';

export default function* rootSaga() {
  yield takeLatest([SOCCER], fetchLeaguePostsSaga);
  yield takeLatest([LEAGUE_POST, TEAM_POST], fetchContentSagaHelper);
  yield takeLatest([START_FETCH_TEAMS], fetchTeamPostsSaga);
}

export function* fetchLeaguePostsSaga(action) {
  yield fetchGeneric(
    fetchLeaguePosts,
    onlyIfMissing(selectLeagues),
    call(api.latestContent, {
      type: api.CONTENT_TYPES.LEAGUE,
    }),
    action,
  );
}

export function* fetchTeamPostsSaga(action) {
  const tag = action.tag;

  yield fetchGeneric(
    fetchTeamPosts(tag),
    onlyIfMissing(selectTeams, tag),
    call(api.latestContent, {
      type: api.CONTENT_TYPES.TEAM,
      tag,
      limit: 100,
    }),
    action,
  );
}
