export const SUBMIT_TIPSTER_FORM = 'becomeTipster/SUBMIT_TIPSTER_FORM';
export const FETCH_TIPSTER_FORM = 'becomeTipster/FETCH_TIPSTER_FORM';

export const submitTipsterForm = formData => ({
  type: SUBMIT_TIPSTER_FORM,
  formData,
});

export const fetchTipsterForm = wrappedAction => ({
  wrappedAction,
  type: FETCH_TIPSTER_FORM,
});
