// Based on:
// https://github.com/faceyspacey/redux-first-router-restore-scroll/blob/a45f93a31c8033c74e919015ee2ab8e22a064fce/src/SessionStorage.js
//
// Uses in-memory storage instead of `sessionStorage`, since sessionStorage
// isn’t allowed in incognito mode on iOS and caused a crazy amount of errors in
// Bugsnag.

const STATE_KEY_PREFIX = '@@scroll|';

export default class SessionStorage {
  constructor() {
    this.storage = new Map();
    this.prevKey = undefined;
  }

  setPrevKey(key) {
    this.prevKey = key;
  }

  read(location, key) {
    const stateKey = this.getStateKey(location, key);
    return this.storage.get(stateKey);
  }

  save(passedLocation, key, value) {
    const location =
      key == null
        ? passedLocation
        : { key: this.prevKey, hash: passedLocation.hash };

    const stateKey = this.getStateKey(location, key);
    this.storage.set(stateKey, value);

    if (key) {
      const newKey = location.key || location.hash || 'loadPage';
      if (newKey !== this.prevKey) {
        this.prevKey = newKey;
      }
    }
  }

  getStateKey(location, key) {
    const locationKey = location.key || location.hash || 'loadPage';
    const stateKeyBase = `${STATE_KEY_PREFIX}${locationKey}`;
    return key == null ? stateKeyBase : `${stateKeyBase}|${key}`;
  }
}
