import Link from 'redux-first-router-link';

import arrowRight from '../../images/arrow-right.svg';
import big9 from '../../images/big9.svg';
import bomben from '../../images/bomben.svg';
import europaTipset from '../../images/europatipset.svg';
import fulltraff from '../../images/fulltraff.svg';
import powerplay from '../../images/powerplay.svg';
import strykTipset from '../../images/stryktipset.svg';
import svenskaSpel from '../../images/svSpelWithBg.svg';
import teaser from '../../images/teaser.svg';
import toppTipset from '../../images/topptipset.svg';
import { classList, getSvSpelAffiliateLink } from '../../utils';
import styles from './styles.scss';

const gamesMap = [
  {
    icon: teaser,
    alt: 'Spela med Svenska Spel Sport & Casino',
    link: 'https://spela.svenskaspel.se/',
  },
  {
    icon: strykTipset,
    alt: 'Stryktipset',
    link: 'https://spela.svenskaspel.se/stryktipset',
  },
  {
    icon: big9,
    alt: 'Big9',
    link: 'https://www.atg.se/sport/big9',
  },
  {
    icon: europaTipset,
    alt: 'Europatipset',
    link: 'https://spela.svenskaspel.se/europatipset',
  },
  {
    icon: toppTipset,
    alt: 'Topptipset',
    link: 'https://spela.svenskaspel.se/topptipset',
  },
  {
    icon: bomben,
    alt: 'Bomben',
    link: 'https://spela.svenskaspel.se/bomben',
  },
  {
    icon: powerplay,
    alt: 'Powerplay',
    link: 'https://spela.svenskaspel.se/powerplay',
  },
  {
    icon: fulltraff,
    alt: 'Fulltraff',
    link: 'https://spela.svenskaspel.se/fulltraff',
  },
];

const SvSpelBar = () => (
  <div className={styles.root}>
    {gamesMap.map((game, index) => {
      const isBig9 = game.alt === 'Big9';

      return (
        <Link
          to={isBig9 ? game.link : getSvSpelAffiliateLink(game.link)}
          target="_blank"
          key={`SvSpelItem${index}`}
          className={classList({
            [styles.item]: true,
            [styles[game.alt.toLowerCase()]]: index > 0,
            [styles.teaser]: index === 0,
          })}
        >
          {index !== 0 && !isBig9 && (
            <img src={svenskaSpel} className={styles.svSpelIcon} alt="" />
          )}
          <img className={styles.logo} src={game.icon} alt={game.alt} />
          {index === 0 && (
            <img src={arrowRight} className={styles.arrowRight} alt="→" />
          )}
        </Link>
      );
    })}
  </div>
);

export default SvSpelBar;
