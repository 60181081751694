export const FETCH_TIPSTERS = 'tipsters/FETCH_TIPSTERS';
export const FETCH_TIPSTER_LIST = 'tipsters/FETCH_TIPSTER_LISTS';
export const GET_TIPSTER_LIST = 'tipsters/GET_TIPSTER_LISTS';

export const fetchTipsters = wrappedAction => ({
  type: FETCH_TIPSTERS,
  wrappedAction,
});

export const fetchTipsterList = listPath => wrappedAction => ({
  type: FETCH_TIPSTER_LIST,
  wrappedAction,
  listPath,
});
