import { call, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { PLAY_REDUCED } from '../../main/actions/router';
import { fetchGeneric, onlyIfMissing } from '../../main/sagas/genericFetch';
import { fetchPlayReduced } from './actions';
import { playReducedSelector } from './selectors';

export default function* rootSaga() {
  yield takeLatest([PLAY_REDUCED], playReducedSaga);
}

export function* playReducedSaga(action) {
  yield fetchGeneric(
    fetchPlayReduced,
    onlyIfMissing(playReducedSelector),
    call(api.getYoutubePlaylists),
    action,
  );
}
