import truth from '../../api/auth';
import { AUTH } from '../actions/auth';
import { genericFetchInitialState, successState } from './genericFetch';

const initialState = {
  user: {
    ...genericFetchInitialState,
    data: truth.user,
  },
  userUpdated: false,
};

export default function authReducer(state = initialState, action) {
  if (action.type === AUTH) {
    return {
      user: {
        ...successState,
        data: action.user,
      },
      userUpdated: true,
    };
  }

  return state;
}
