import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { WORLD_CUP } from '../../main/actions/router';
import { fetchGeneric, onlyIfMissing } from '../../main/sagas/genericFetch';
import { fetchContentSaga } from '../../main/sagas/models';
import { locationSelector } from '../../main/selectors';
import { fetchAnalyses, fetchDefaultAnalysis, toggleSidebar } from './actions';
import { analysesSelector, defaultAnalysisSelector } from './selectors';

// There should be 40 analyses in total. Add another 10 for good measure.
const NUM_ANALYSES = 50;

export default function* rootSaga() {
  yield takeLatest(WORLD_CUP, closeSidebar);
  yield takeLatest(WORLD_CUP, fetchAnalysesSaga);
  yield takeLatest(WORLD_CUP, fetchCurrentAnalysisSaga);
}

export function* closeSidebar() {
  yield put(toggleSidebar({ open: false }));
}

export function* fetchAnalysesSaga(action) {
  yield fetchGeneric(
    fetchAnalyses,
    onlyIfMissing(analysesSelector),
    call(fetchLatestGroupAndTeamAnalyses),
    action,
  );
}

export function fetchLatestGroupAndTeamAnalyses() {
  return api
    .latestContent({
      type: [api.CONTENT_TYPES.GROUP_ANALYSIS, api.CONTENT_TYPES.TEAM_ANALYSIS],
      limit: NUM_ANALYSES,
    })
    .then(response =>
      response.results.filter(analysis => {
        // The API can oddly enough return an empty object as group if no group
        // has been specified in the admin, so check for that as well as `null`.
        const hasGroup =
          analysis.group != null && Object.keys(analysis.group).length > 0;
        return analysis.type === api.CONTENT_TYPES.TEAM_ANALYSIS
          ? hasGroup && analysis.team != null
          : hasGroup;
      }),
    );
}

export function* fetchCurrentAnalysisSaga(action) {
  const location = yield select(locationSelector);
  const { slug } = location.payload;

  if (slug == null) {
    yield fetchGeneric(
      fetchDefaultAnalysis,
      onlyIfMissing(defaultAnalysisSelector),
      call(fetchLatestSeasonAnalysis),
      action,
    );
  } else {
    yield call(fetchContentSaga, slug, action);
  }
}

export function fetchLatestSeasonAnalysis() {
  return api
    .latestContent({
      type: api.CONTENT_TYPES.SEASON_ANALYSIS,
      limit: 1,
    })
    .then(response =>
      response.results.length > 0 ? response.results[0] : api.NONE,
    );
}
