import { createSelector } from 'reselect';

import config from '../../main/config';
import {
  competitionCouponsBySlugSelector,
  competitionResultsBySlugSelector,
  competitionsBySlugSelector,
} from '../../main/selectors/models';

export const competitionSelector = state => state.competition;

export const enabledCompetitionSelector = createSelector(
  competitionsBySlugSelector,
  competitionsBySlug =>
    competitionsBySlug[config.COMPETITION_SLUG] || {
      fetching: false,
      error: new Error(
        `Kunde inte hitta tävling för ${config.COMPETITION_SLUG}`,
      ),
    },
);

export const competitionCouponSelector = createSelector(
  competitionCouponsBySlugSelector,
  competitionCouponsBySlug =>
    competitionCouponsBySlug[config.COMPETITION_SLUG] || {
      fetching: false,
      error: new Error(
        `Kunde inte hitta tävlingskupong för ${config.COMPETITION_SLUG}`,
      ),
    },
);

export const competitionResultSelector = createSelector(
  competitionResultsBySlugSelector,
  competitionResultsBySlug =>
    competitionResultsBySlug[config.COMPETITION_SLUG] || {
      fetching: false,
      error: new Error(
        `Kunde inte hitta tävlingsresultat för ${config.COMPETITION_SLUG}`,
      ),
    },
);

export const enteredResultsByMatchIdSelector = createSelector(
  competitionSelector,
  competition => competition.enteredResultsByMatchId,
);

export const hasClickedSubmitSelector = createSelector(
  competitionSelector,
  competition => competition.hasClickedSubmit,
);

export const postedCouponSelector = createSelector(
  competitionSelector,
  competition => competition.postedCoupon,
);
