import { all, call, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { PROFILE } from '../../main/actions/router';
import { alwaysFetch, fetchGeneric } from '../../main/sagas/genericFetch';
import { fetchTipsterListSaga } from '../tipsters/sagas';
import {
  fetchMoreBetTips,
  fetchTipsterBetTips,
  fetchTipsterProfile,
  SELECTED_FILTERS_UPDATE,
  START_FETCH_MORE_BET_TIPS,
} from './actions';
import { getBetTipsHistorySelector, getBetTipsLiveSelector } from './selectors';

const BET_TIPS_TO_LOAD_AT_A_TIME = 10;

export default function* rootSaga() {
  yield takeLatest(PROFILE, fetchTipsterProfileSaga);
  yield takeLatest(SELECTED_FILTERS_UPDATE, fetchTipsterProfileSaga);
  yield takeLatest(PROFILE, fetchTipsterListSaga);
  yield takeLatest(START_FETCH_MORE_BET_TIPS, fetchMoreBetTipsSaga);
}

export function* fetchTipsterProfileSaga(action) {
  const { slug, filters } = action.payload;
  yield fetchGeneric(
    fetchTipsterProfile(slug),
    alwaysFetch,
    call(api.getTipsterProfile, { slug, filters }),
    action,
  );

  const betTipData = {
    slug,
    tipster: slug,
    limit: BET_TIPS_TO_LOAD_AT_A_TIME,
    live: false,
    ...filters,
  };
  yield all([
    call(fetchBetTips, betTipData),
    call(fetchBetTips, {
      ...betTipData,
      live: true,
    }),
  ]);
}

export function* fetchBetTips(action) {
  yield fetchGeneric(
    fetchTipsterBetTips(action.slug, action.live),
    alwaysFetch,
    call(api.getTipsterBetTips, {
      ...action,
    }),
    action,
  );
}

export function* fetchMoreBetTipsSaga(action) {
  const { slug, live } = action;
  const betTips = live
    ? yield select(getBetTipsLiveSelector)
    : yield select(getBetTipsHistorySelector);

  const nextUrl = betTips.data == null ? undefined : betTips.data.next;

  if (nextUrl == null) {
    return;
  }
  yield fetchGeneric(
    fetchMoreBetTips(slug, live),
    alwaysFetch,
    call(api.getUrl, nextUrl),
    action,
  );
}
