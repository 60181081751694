import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import config from '../../main/config';
import { toPropType } from '../../main/prop-types';
import { classList } from '../../utils';
import { getGameType } from '../../utils/productUtil';
import styles from './styles.scss';

const EXTERNAL_LINK = /^(?:\w+:)?\/\//;

function isWhiteListedExternal(url) {
  return url.startsWith(config.AUTH_URL);
}

SmartLink.propTypes = {
  to: toPropType,
  colored: PropTypes.bool,
  unstyled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  // ...props
};

export default function SmartLink({
  to: passedTo,
  colored = false,
  unstyled = false,
  className: passedClassName = '',
  children,
  onClick,
  ...props
}) {
  const to =
    typeof passedTo === 'string' && passedTo.startsWith(window.location.origin)
      ? passedTo.slice(window.location.origin.length)
      : passedTo;

  const className = classList({
    [styles.root]: !unstyled,
    [styles.inheritColor]: !unstyled && !colored,
    [passedClassName]: true,
  });
  if (to && to.payload) {
    to.payload.gameType = getGameType(to.payload.gameType);
  }
  return to == null ? (
    <a className={className}>{children}</a>
  ) : typeof to === 'string' && EXTERNAL_LINK.test(to) ? (
    <>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        onClick={onClick}
        href={to}
        target={isWhiteListedExternal(to) ? undefined : '_blank'}
        rel={isWhiteListedExternal(to) ? undefined : 'noopener noreferrer'}
        className={className}
      >
        {children}
      </a>
    </>
  ) : (
    <Link {...props} to={to} className={className} onClick={onClick}>
      {children}
    </Link>
  );
}
