import PropTypes from 'prop-types';

import Burger from '../Burger';
import styles from './styles.scss';

const BurgerButton = ({ onClick, open = false }) => (
  <button
    type="button"
    className={styles.burger}
    aria-label="Meny"
    onClick={() => {
      onClick();
    }}
  >
    <div className={styles.burgerInner}>
      <Burger cross={open} />
    </div>
  </button>
);

BurgerButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BurgerButton;
