const browser = detectBrowser();

document.documentElement.dataset.browser = browser;

export default browser;

function detectBrowser() {
  if (window.chrome) {
    return 'chrome';
  }

  if (window.sidebar) {
    return 'firefox';
  }

  if (window.MSInputMethodContext && document.documentMode) {
    return 'ie';
  }

  if (window.MSInputMethodContext && !document.documentMode) {
    return 'edge';
  }

  if (/Apple/.test(window.navigator.vendor)) {
    return 'safari';
  }

  return 'unknown';
}

export const iOS =
  window.navigator.platform &&
  /iPad|iPhone|iPod/.test(window.navigator.platform);
