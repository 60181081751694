import { call, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import {
  ABOUT,
  ANALYSES,
  BLOG,
  HOME,
  SCHOOL,
  SHARED_BETS,
  TIPSTERS,
} from '../../main/actions/router';
import { fetchGeneric, onlyIfMissing } from '../../main/sagas/genericFetch';
import { fetchTipsterList, fetchTipsters, GET_TIPSTER_LIST } from './actions';
import { tipsterListSelector, tipsterSelector } from './selectors';

export default function* rootSaga() {
  yield takeLatest(TIPSTERS, fetchTipstersSaga);
  yield takeLatest(TIPSTERS, fetchTipsterListSaga);
  yield takeLatest(HOME, fetchTipsterListSaga);
  yield takeLatest(SHARED_BETS, fetchTipsterListSaga);
  yield takeLatest(BLOG, fetchTipsterListSaga);
  yield takeLatest(ANALYSES, fetchTipsterListSaga);
  yield takeLatest(SCHOOL, fetchTipsterListSaga);
  yield takeLatest(ABOUT, fetchTipsterListSaga);
  yield takeLatest(GET_TIPSTER_LIST, fetchTipsterListSaga);
}

export function* fetchTipstersSaga(action) {
  yield fetchGeneric(
    fetchTipsters,
    onlyIfMissing(state => tipsterSelector(state)),
    call(api.getTipsters),
    action,
  );
}

export function* fetchTipsterListSaga(action) {
  const { listPath = 'roi' } = action;
  yield fetchGeneric(
    fetchTipsterList(listPath),
    onlyIfMissing(state => tipsterListSelector(state)[listPath]),
    call(api.getTipstersTopList, { listPath }),
    action,
  );
}
