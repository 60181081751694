const PROPERTIES = [['length', 0]];

const METHODS = [
  ['key', null],
  ['getItem', null],
  ['setItem', undefined],
  ['removeItem', undefined],
  ['clear', undefined],
];

/**
 * `window.localStorage.setItem()` can throw `QuotaExceededError`s in Private
 * Browsing on iOS. And simply accessing `window.localStorage` can throw
 * `SecurityError`s if the user has restricted settings. This wrapper warns
 * instead of throwing errors, in order to reduce the noise on bugsnag.
 */
class NonThrowingLocalStorage {
  constructor() {
    PROPERTIES.forEach(([name, defaultValue]) => {
      Object.defineProperty(this, name, {
        get: () => {
          try {
            return window.localStorage[name];
          } catch (error) {
            console.warn(`Access to localStorage.${name} failed`, {
              error,
              defaultValue,
            });
            return defaultValue;
          }
        },
      });
    });

    METHODS.forEach(([name, defaultValue]) => {
      this[name] = function (...args) {
        try {
          return window.localStorage[name](...args);
        } catch (error) {
          console.warn(`Call to localStorage.${name} failed`, {
            args,
            error,
            defaultValue,
          });
          return defaultValue;
        }
      };
    });
  }
}

const storage = new NonThrowingLocalStorage();
export default storage;
