import { call, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { BIG9, BLOG, BLOG_POST } from '../../main/actions/router';
import {
  alwaysFetch,
  fetchGeneric,
  onlyIfMissing,
} from '../../main/sagas/genericFetch';
import {
  fetchContentSagaHelper,
  fetchLatestRoundsSaga,
} from '../../main/sagas/models';
import {
  FETCH_BLOG_POSTS_BY_AUTHOR,
  fetchAuthors,
  fetchBlogPostsByAuthorId,
  fetchMoreBlogPosts,
  START_FETCH_MORE_BLOG_POSTS,
} from './actions';
import { authorSelector, blogPostsSelector } from './selectors';

const BLOG_POSTS_TO_LOAD_AT_A_TIME = 10;

export default function* rootSaga() {
  yield takeLatest([BLOG, BIG9], fetchBlogPostsSaga);
  yield takeLatest([BLOG, BLOG_POST], getAuthorsSaga);
  yield takeLatest(BLOG, fetchLatestRoundsSaga);
  yield takeLatest(START_FETCH_MORE_BLOG_POSTS, fetchMoreBlogPostsSaga);
  yield takeLatest(FETCH_BLOG_POSTS_BY_AUTHOR, fetchBlogPostsSaga);
  yield takeLatest(BLOG_POST, fetchContentSagaHelper);
  yield takeLatest(BLOG_POST, fetchLatestRoundsSaga);
}

export function* fetchBlogPostsSaga(action) {
  yield fetchGeneric(
    fetchBlogPostsByAuthorId(action.authorId),
    alwaysFetch,
    call(api.latestContent, {
      type: api.CONTENT_TYPES.BLOG,
      limit: BLOG_POSTS_TO_LOAD_AT_A_TIME,
      author_id: action.authorId === 'all' ? undefined : action.authorId,
      tag: action.payload?.tag,
    }),
    action,
  );
}

export function* getAuthorsSaga(action) {
  yield fetchGeneric(
    fetchAuthors,
    onlyIfMissing(authorSelector),
    call(api.getAuthors),
    action,
  );
}

export function* fetchMoreBlogPostsSaga(action) {
  const blogPosts = yield select(blogPostsSelector);
  const nextUrl =
    blogPosts[action.id].data == null
      ? undefined
      : blogPosts[action.id].data.next;

  if (nextUrl == null) {
    return;
  }

  yield fetchGeneric(
    fetchMoreBlogPosts(action.id),
    alwaysFetch,
    call(api.getUrl, nextUrl),
    action,
  );
}
