export const FETCH_ANALYSES = 'worldcup/FETCH_ANALYSES';
export const FETCH_DEFAULT_ANALYSIS = 'worldcup/FETCH_DEFAULT_ANALYSIS';
export const TOGGLE_SIDEBAR = 'worldcup/TOGGLE_SIDEBAR';
export const QUERY_CHANGE = 'worldcup/QUERY_CHANGE';

export const fetchAnalyses = wrappedAction => ({
  type: FETCH_ANALYSES,
  wrappedAction,
});

export const fetchDefaultAnalysis = wrappedAction => ({
  type: FETCH_DEFAULT_ANALYSIS,
  wrappedAction,
});

export const toggleSidebar = ({ open }) => ({
  type: TOGGLE_SIDEBAR,
  open,
});

export const queryChange = ({ query }) => ({
  type: QUERY_CHANGE,
  query,
});
