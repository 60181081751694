export const FETCH_EVENTS_LIST = 'game/events/FETCH_EVENTS_LIST';
export const REFETCH_EVENTS_LIST = 'game/events/REFETCH_EVENTS_LIST';
export const FETCH_MATCHES_LIST = 'game/FETCH_MATCHES_LIST';
export const FETCH_MORE_MATCHES_LIST = 'game/FETCH_MORE_MATCHES_LIST';
export const START_FETCH_MORE_MATCHES_LIST =
  'game/START_FETCH_MORE_MATCHES_LIST';
export const RESET_FILTERS = 'game/RESET_FILTERS';
export const SELECT_ODDS = 'game/SELECT_ODDS';
export const QUERY_CHANGE = 'game/QUERY_CHANGE';
export const FILTER_CHANGE = 'game/FILTER_CHANGE';
export const TOGGLE_SIDEBAR = 'game/TOGGLE_SIDEBAR';
export const START_POST_WAGER = 'game/START_POST_WAGER';
export const POST_WAGER = 'game/POST_WAGER';
export const RESET_WAGER = 'game/RESET_WAGER';
export const READ_FROM_LOCAL_STORAGE = 'game/READ_FROM_LOCAL_STORAGE';

export const fetchEventsList = wrappedAction => ({
  type: FETCH_EVENTS_LIST,
  wrappedAction,
});

export const refetchEventsList = () => ({
  type: REFETCH_EVENTS_LIST,
});

export const fetchMatchesList = wrappedAction => ({
  type: FETCH_MATCHES_LIST,
  wrappedAction,
});

export const fetchMoreMatchesList = wrappedAction => ({
  type: FETCH_MORE_MATCHES_LIST,
  wrappedAction,
});

export const startFetchMoreMatchesList = () => ({
  type: START_FETCH_MORE_MATCHES_LIST,
});

export const selectOdds = ({ id, name, selected }) => ({
  type: SELECT_ODDS,
  id,
  name,
  selected,
});

export const queryChange = ({ query }) => ({
  type: QUERY_CHANGE,
  query,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const filterChange = ({ property, value, checked }) => ({
  type: FILTER_CHANGE,
  property,
  value,
  checked,
});

export const toggleSidebar = ({ open }) => ({
  type: TOGGLE_SIDEBAR,
  open,
});

export const startPostWager = ({ roundType, roundId, items }) => ({
  type: START_POST_WAGER,
  roundType,
  roundId,
  items,
});

export const postWager = (roundType, wrappedAction) => ({
  type: POST_WAGER,
  roundType,
  wrappedAction,
});

export const resetWager = ({ roundType, matchIdsWithChoices }) => ({
  type: RESET_WAGER,
  roundType,
  matchIdsWithChoices,
});

export const readFromLocalStorage = ({ selectedOddsById }) => ({
  type: READ_FROM_LOCAL_STORAGE,
  selectedOddsById,
});
