import { call, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { alwaysFetch, fetchGeneric } from '../../main/sagas/genericFetch';
import { fetchTipsterForm, SUBMIT_TIPSTER_FORM } from './actions';

export default function* rootSaga() {
  yield takeLatest(SUBMIT_TIPSTER_FORM, submitTiptserFormSaga);
}

export function* submitTiptserFormSaga(action) {
  const { formData } = action;

  yield fetchGeneric(
    fetchTipsterForm,
    alwaysFetch,
    call(api.sendEmail, formData),
    action,
  );
}
