import { delay } from 'redux-saga';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import * as api from '../../api';
import { RESET_STATE } from '../../main/actions';
import { fetchGenericSuccess } from '../../main/actions/genericFetch';
import { FETCH_MATCH, fetchMatch } from '../../main/actions/models';
import {
  ENTERED_ANY_GAME_PAGE,
  GAME_EVENTS,
  GAME_INFO,
  GAME_INTERVIEWS,
  GAME_LINEUPS,
  GAME_STATISTICS,
  GAME_SUMMARY,
  GAME_TABLE,
} from '../../main/actions/router';
import {
  alwaysFetch,
  fetchGeneric,
  onlyIfMissing,
} from '../../main/sagas/genericFetch';
import {
  fetchContentSaga,
  fetchLineupsSaga,
  fetchMatchStatisticsSaga,
  fetchRoundSaga,
  fetchTeamComingMatchesSaga,
  fetchTeamStatisticsSaga,
} from '../../main/sagas/models';
import { locationSelector } from '../../main/selectors';
import { statisticsByTeamIdSelector } from '../../main/selectors/models';
import { getYears } from '../../utils/game';
import { MINUTE } from '../../utils/time';
import {
  fetchEventsList,
  fetchMoreMatchesList,
  FILTER_CHANGE,
  postWager,
  QUERY_CHANGE,
  readFromLocalStorage,
  REFETCH_EVENTS_LIST,
  RESET_FILTERS,
  RESET_WAGER,
  SELECT_ODDS,
  START_FETCH_MORE_MATCHES_LIST,
  START_POST_WAGER,
  toggleSidebar,
} from './actions';
import {
  currentMatchSelector,
  matchesListSelector,
  selectedOddsByIdSelector,
} from './selectors';
import { filterChange } from './statistics/actions';
import { statisticsFiltersSelector } from './statistics/selectors';
import {
  fetchSeasonStandings,
  FILTER_CHANGE as TABLE_FILTER_CHANGE,
} from './table/actions';
import { tableFiltersSelector } from './table/selectors';
import {
  contentTypesForInfoTab,
  contentTypesForInterviewsTab,
  selectedOddsByIdFromLocalStorage,
  selectedOddsByIdToLocalStorage,
} from './utils';

const SEARCH_DELAY = 250; // ms
const MAX_MATCH_TIME = 90 * 2 * MINUTE;
const MIN_CURRENT_MATCH_REFETCH_TIMEOUT = MINUTE;

export default function* rootSaga() {
  yield takeLatest(ENTERED_ANY_GAME_PAGE, closeSidebar);
  yield takeLatest(ENTERED_ANY_GAME_PAGE, fetchCurrentMatch);

  yield takeLatest(ENTERED_ANY_GAME_PAGE, fetchMatches);
  yield takeLatest(ENTERED_ANY_GAME_PAGE, readFromLocalStorageSaga);
  yield takeLatest(START_FETCH_MORE_MATCHES_LIST, fetchMoreMatches);

  yield takeLatest(QUERY_CHANGE, fetchMatches);
  yield takeLatest(RESET_FILTERS, fetchMatches);
  yield takeLatest(FILTER_CHANGE, fetchMatches);

  yield takeLatest(SELECT_ODDS, saveOdds);
  yield takeLatest(RESET_WAGER, saveOdds);
  yield takeLatest(RESET_STATE, saveOdds);
  yield takeLatest(START_POST_WAGER, postWagerSaga);

  yield takeLatest(REFETCH_EVENTS_LIST, refetchEventsListSaga);
  yield takeLatest(TABLE_FILTER_CHANGE, fetchSeasonStandingsSaga);

  yield call(refetchCurrentMatchSaga);
}

export function* closeSidebar() {
  yield put(toggleSidebar({ open: false }));
}

export function* fetchMatches(action) {
  if (action.type === QUERY_CHANGE) {
    yield call(delay, SEARCH_DELAY);
  }

  const location = yield select(locationSelector);
  const { gameType, roundId } = location.payload;
  yield call(fetchRoundSaga, { gameType, roundId }, action);
}

export function* fetchMoreMatches(action) {
  const matchesList = yield select(matchesListSelector);
  const nextUrl = matchesList.data == null ? undefined : matchesList.data.next;

  if (nextUrl == null) {
    return;
  }

  yield fetchGeneric(
    fetchMoreMatchesList,
    alwaysFetch,
    call(api.getUrl, nextUrl),
    action,
  );
}

export function getRoundMatchWithContent(roundMatchId) {
  return api
    .getRoundMatch({ roundMatchId })
    .then(roundMatch =>
      Promise.all(
        roundMatch.content.map(({ id }) =>
          api.getContent({ id }).catch(error => error),
        ),
      ).then(content => ({ ...roundMatch, content })),
    );
}

export function* fetchCurrentMatch(action) {
  const { roundMatchId } = action.payload;

  if (roundMatchId == null) {
    return;
  }

  yield fetchGeneric(
    fetchMatch.bind(undefined, roundMatchId),
    onlyIfMissing(currentMatchSelector),
    call(getRoundMatchWithContent, roundMatchId),
    action,
  );

  const location = yield select(locationSelector);
  const currentMatch = yield select(currentMatchSelector);

  if (currentMatch == null || currentMatch.data == null) {
    return;
  }

  switch (location.type) {
    case GAME_EVENTS:
      yield call(fetchEventsListSaga, currentMatch.data.id, action);
      break;

    case GAME_INFO: {
      const contentTypes = contentTypesForInfoTab();
      yield all(
        currentMatch.data.content
          .filter(content => contentTypes.includes(content.type))
          .map(content => call(fetchContentSaga, content.slug, action)),
      );
      break;
    }

    case GAME_INTERVIEWS: {
      const contentTypes = contentTypesForInterviewsTab();
      yield all(
        currentMatch.data.content
          .filter(content => contentTypes.includes(content.type))
          .map(content => call(fetchContentSaga, content.slug, action)),
      );
      break;
    }

    case GAME_LINEUPS:
      yield call(fetchLineupsSaga, currentMatch.data.id, action);
      break;

    case GAME_STATISTICS: {
      const homeTeamId = currentMatch.data.home_team.id;
      const awayTeamId = currentMatch.data.away_team.id;

      yield all([
        call(fetchTeamStatisticsSaga, homeTeamId, action),
        call(fetchTeamStatisticsSaga, awayTeamId, action),
        call(
          fetchTeamComingMatchesSaga,
          homeTeamId,
          currentMatch.data.id,
          action,
        ),
        call(
          fetchTeamComingMatchesSaga,
          awayTeamId,
          currentMatch.data.id,
          action,
        ),
      ]);

      yield call(setDefaultStatisticsFilters, homeTeamId, awayTeamId);
      break;
    }

    case GAME_SUMMARY:
      yield all([
        call(fetchEventsListSaga, currentMatch.data.id, action),
        call(fetchLineupsSaga, currentMatch.data.id, action),
        call(fetchMatchStatisticsSaga, currentMatch.data.id, action),
      ]);
      break;

    case GAME_TABLE:
      yield call(fetchSeasonStandingsSaga, action);
      break;

    default:
    // Do nothing.
  }
}

export function* fetchEventsListSaga(matchId, action) {
  yield fetchGeneric(
    fetchEventsList,
    alwaysFetch,
    call(api.getMatchEvents, { id: matchId }),
    action,
  );
}

export function* refetchEventsListSaga(action) {
  const currentMatch = yield select(currentMatchSelector);

  if (currentMatch == null || currentMatch.data == null) {
    return;
  }

  yield call(fetchEventsListSaga, currentMatch.data.id, action);
}

export function* fetchSeasonStandingsSaga(action) {
  const currentMatch = yield select(currentMatchSelector);
  const filters = api.defaultToUndefined(yield select(tableFiltersSelector));

  if (currentMatch == null || currentMatch.data == null) {
    return;
  }

  const { id } = currentMatch.data.season;
  const tournamentId = currentMatch.data.tournament.id;
  const { group: groupId } = currentMatch.data;

  yield fetchGeneric(
    fetchSeasonStandings,
    alwaysFetch,
    call(
      api.getTournamentSeasonStandings,
      { id, tournamentId, groupId },
      {
        filter: filters.homeAway,
        max_matches: filters.numMatches,
      },
    ),
    action,
  );
}

export function* readFromLocalStorageSaga() {
  yield put(
    readFromLocalStorage({
      selectedOddsById: selectedOddsByIdFromLocalStorage(),
    }),
  );
}

export function* saveOdds() {
  const selectedOddsById = yield select(selectedOddsByIdSelector);
  yield call(selectedOddsByIdToLocalStorage, selectedOddsById);
}

export function* postWagerSaga(action) {
  yield fetchGeneric(
    postWager.bind(undefined, action.roundType),
    alwaysFetch,
    call(
      api.postRoundWager,
      {
        type: action.roundType,
        id: action.roundId,
      },
      {
        items: action.items,
      },
    ),
    action,
  );
}

export function* setDefaultStatisticsFilters(homeTeamId, awayTeamId) {
  const filters = yield select(statisticsFiltersSelector);
  const statisticsByTeamId = yield select(statisticsByTeamIdSelector);
  const homeStatistics = statisticsByTeamId[homeTeamId];
  const awayStatistics = statisticsByTeamId[awayTeamId];

  if (
    homeStatistics == null ||
    homeStatistics.data == null ||
    awayStatistics == null ||
    awayStatistics.data == null
  ) {
    return;
  }

  const maxNumMatches = Math.max(
    homeStatistics.data.matches.length,
    awayStatistics.data.matches.length,
  );

  if (
    typeof filters.numMatches === 'number' &&
    filters.numMatches > maxNumMatches
  ) {
    yield put(filterChange({ name: 'numMatches', value: api.DEFAULT }));
  }

  const years = getYears(homeStatistics.data.matches);

  if (years.length === 0) {
    return;
  }

  // If TO and FROM already have been set and are still valid, let them be.
  if (
    filters.yearFrom != null &&
    filters.yearTo != null &&
    years.includes(Number(filters.yearFrom)) &&
    years.includes(Number(filters.yearTo))
  ) {
    return;
  }

  // TO: Most recent year.
  const yearTo = Math.max(...years);

  // FROM: If possible, one year before TO. Otherwise the most recent
  const wantedFromYear = yearTo - 1;

  const smallerYearsFrom = years.filter(year => year <= yearTo);

  const yearFrom = smallerYearsFrom.includes(wantedFromYear)
    ? wantedFromYear
    : smallerYearsFrom.length > 0
    ? Math.max(...smallerYearsFrom)
    : undefined;

  if (yearTo == null || yearFrom == null) {
    return;
  }

  yield put(filterChange({ name: 'yearFrom', value: String(yearFrom) }));
  yield put(filterChange({ name: 'yearTo', value: String(yearTo) }));
}

// This periodically refetches the current match, so that the Events tab has a
// chance to show up without the user having to reload the page. This can be
// extended to refetch for other reasons as well in the future.
export function* refetchCurrentMatchSaga() {
  while (true) {
    const currentMatch = yield select(currentMatchSelector);

    // When any of these actions fire there is a good chance that the current
    // match has changed.
    const waitForNextMatch = take([ENTERED_ANY_GAME_PAGE, FETCH_MATCH]);

    // If there's no currentMatch yet (we're on a different page, or it is still
    // loading), wait for it to arrive.
    if (currentMatch == null || currentMatch.data == null) {
      yield waitForNextMatch;
      continue;
    }

    const startTimestamp = new Date(currentMatch.data.start_time).getTime();
    const now = Date.now();
    const diff = now - startTimestamp;

    const { slug, has_matchevents: hasMatchEvents } = currentMatch.data;
    const tooOld = diff > MAX_MATCH_TIME;

    // If the match has already been played or already has match events there's
    // no need to refetch anything.
    if (tooOld || hasMatchEvents) {
      yield waitForNextMatch;
      continue;
    }

    // If the match is in the future, wait half of the remaining time before
    // refetching, or at least `MIN_CURRENT_MATCH_REFETCH_TIMEOUT`. If the match
    // is ongoing (but hasn't got match events yet, see above), wait
    // `MIN_CURRENT_MATCH_REFETCH_TIMEOUT`.
    const timeout = Math.max(MIN_CURRENT_MATCH_REFETCH_TIMEOUT, -diff / 2);

    // Either wait for `timeout` to pass, or for the current match to change,
    // whichever happens first.
    const [, didDelay] = yield race([waitForNextMatch, call(delay, timeout)]);

    // `didDelay` is `true` if the `delay` won the race, `undefined` otherwise.
    if (didDelay !== true) {
      continue;
    }

    try {
      const response = yield call(api.getMatch, { id: slug });
      yield put(fetchMatch(slug, fetchGenericSuccess(response)));
    } catch {
      console.warn('Failed to refetch current match', slug);
    }
  }
}
