import PropTypes from 'prop-types';

import { toPropType } from '../../main/prop-types';
import { classList } from '../../utils';
import SmartLink from '../SmartLink';
import styles from './styles.scss';

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  to: toPropType,
  variant: PropTypes.oneOf([
    'regular',
    'grey',
    'lightGrey',
    'outline',
    'regular-lg',
    'outline-lg',
    'white-lg',
    'golden',
    'outlineGolden',
  ]),
  block: PropTypes.bool,
  enabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  alignCenter: PropTypes.bool,
};

/**
 * Click it.
 */
export default function Button({
  type = 'button',
  to,
  variant = 'regular',
  block = false,
  enabled = true,
  onClick,
  alignCenter = false,
  children,
}) {
  const outerProps = {
    className: classList({
      [styles[variant]]: true,
      [styles.block]: block,
      [styles.disabled]: !enabled,
    }),
    onClick:
      onClick == null
        ? undefined
        : () => {
            onClick();
          },
  };

  const innerProps = {
    className: classList({
      [styles.buttonInner]: true,
      [styles.alignCenter]: alignCenter,
    }),
  };

  return to == null ? (
    // eslint-disable-next-line react/button-has-type
    <button type={type} disabled={!enabled} {...outerProps}>
      <div {...innerProps}>{children}</div>
    </button>
  ) : (
    <SmartLink
      onClick={onClick}
      to={enabled ? to : undefined}
      unstyled
      {...outerProps}
    >
      <div {...innerProps}>{children}</div>
    </SmartLink>
  );
}
