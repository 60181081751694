import PropTypes from 'prop-types';

import { classList } from '../../utils';
import styles from './styles.scss';

LinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  bold: PropTypes.bool,
};

/**
 * A button that looks lite a link.
 */
export default function LinkButton({ onClick, children, bold = true }) {
  return (
    <button
      type="button"
      className={classList({ [styles.root]: true, [styles.bold]: bold })}
      onClick={() => {
        onClick();
      }}
    >
      {children}
    </button>
  );
}
