import PropTypes from 'prop-types';

import styles from './styles.scss';

UncorrectedBetTipsBadge.propTypes = {
  count: PropTypes.number.isRequired,
};

export default function UncorrectedBetTipsBadge({ count }) {
  return count > 0 ? (
    <span className={styles.uncorrectedCount}>{count}</span>
  ) : null;
}
