import {
  genericFetchInitialState,
  genericFetchMoreReducer,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  FETCH_EVENTS_LIST,
  FETCH_MATCHES_LIST,
  FETCH_MORE_MATCHES_LIST,
  FILTER_CHANGE,
  POST_WAGER,
  QUERY_CHANGE,
  READ_FROM_LOCAL_STORAGE,
  RESET_FILTERS,
  RESET_WAGER,
  SELECT_ODDS,
  TOGGLE_SIDEBAR,
} from './actions';
import lineupsReducer from './lineups/reducers';
import statisticsReducer from './statistics/reducers';
import tableReducer from './table/reducers';
import { selectedOddsInitialState } from './utils';

const initialState = {
  // Whether or not the sidebar is visible (only matters for smaller screens).
  sidebarOpen: false,

  eventsList: genericFetchInitialState,
  matchesList: genericFetchInitialState,

  query: '',
  selectedFilters: [],
  selectedOddsById: {},

  wagers: {
    europatipset: genericFetchInitialState,
    stryktipset: genericFetchInitialState,
    topptipset: genericFetchInitialState,
    powerplay: genericFetchInitialState,
  },
};

export default function gameReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENTS_LIST:
      return {
        ...state,
        eventsList: genericFetchReducer(state.eventsList, action.wrappedAction),
      };

    case FETCH_MATCHES_LIST:
      return {
        ...state,
        matchesList: genericFetchReducer(
          state.matchesList,
          action.wrappedAction,
        ),
      };

    case FETCH_MORE_MATCHES_LIST:
      return {
        ...state,
        matchesList: genericFetchMoreReducer(
          state.matchesList,
          action.wrappedAction,
        ),
      };

    case SELECT_ODDS:
      return {
        ...state,
        selectedOddsById: {
          ...state.selectedOddsById,
          [action.id]: {
            ...selectedOddsInitialState,
            ...state.selectedOddsById[action.id],
            [action.name]: action.selected,
          },
        },
      };

    case QUERY_CHANGE: {
      return {
        ...state,
        query: action.query,
      };
    }

    case RESET_FILTERS: {
      return {
        ...state,
        selectedFilters: [],
      };
    }

    case FILTER_CHANGE: {
      const { property, value, checked } = action;

      return {
        ...state,
        selectedFilters: [
          ...state.selectedFilters.filter(
            filter => filter.property !== property || filter.value !== value,
          ),
          ...(checked ? [{ property, value }] : []),
        ],
      };
    }

    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarOpen: action.open,
      };
    }

    case POST_WAGER:
      return {
        ...state,
        wagers: {
          ...state.wagers,
          [action.roundType]: genericFetchReducer(
            state.wagers[action.roundType],
            action.wrappedAction,
          ),
        },
      };

    case RESET_WAGER:
      return {
        ...state,
        selectedOddsById: action.matchIdsWithChoices.reduce(
          (selectedOddsById, slug) => ({
            ...selectedOddsById,
            [slug]: undefined,
          }),
          state.selectedOddsById,
        ),
        wagers: {
          ...state.wagers,
          [action.roundType]: genericFetchInitialState,
        },
      };

    case READ_FROM_LOCAL_STORAGE:
      return {
        ...state,
        selectedOddsById: action.selectedOddsById,
      };

    default:
      return {
        ...state,
        lineups: lineupsReducer(state.lineups, action),
        statistics: statisticsReducer(state.statistics, action),
        table: tableReducer(state.table, action),
      };
  }
}
