import { fnv32a } from '@trmediaab/zebra-utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { AUTH } from '../../main/actions/auth';
import {
  ANALYSES,
  ANALYSES_ADMIN,
  ANALYSIS_POST,
  ANALYSIS_POST_CREATE,
  ANALYSIS_POST_EDIT,
  HOME,
  SELECT_BETOFFER,
  SELECT_EVENT,
  toAnalyses,
  toAnalysisPost,
} from '../../main/actions/router';
import {
  alwaysFetch,
  fetchGeneric,
  onlyIfMissing,
} from '../../main/sagas/genericFetch';
import { fetchLatestRoundsSaga } from '../../main/sagas/models';
import { locationSelector } from '../../main/selectors';
import { userSelector } from '../../main/selectors/auth';
import {
  DELETE_ANALYSIS,
  fetchAuthenticatedTipster,
  fetchEvent,
  fetchEventGroups,
  fetchEventList,
  fetchFilteredPublicAnalyses,
  fetchMoreContentList,
  fetchPrivateAnalysis,
  fetchPublicAnalysis,
  fetchUncorrectedAnalyses,
  fetchUpcomingAnalyses,
  initAnalysisForm,
  SELECTED_FILTERS_UPDATE,
  setAvailableFilters,
  setSelectedFilterKey,
  START_FETCH_MORE_ANALYSES,
  SUBMIT_ANALYSIS_FORM,
  UPDATE_EVENT_LIST,
} from './actions';
import {
  availableFiltersSelector,
  eventById,
  eventGroups,
  latestAnalysesByFilterKeySelector,
  privateAnalyses,
  publicAnalyses,
} from './selectors';

export default function* rootSaga() {
  yield takeLatest(
    [ANALYSES, HOME, SELECTED_FILTERS_UPDATE],
    fetchLatestAnalysesSaga,
  );
  yield takeLatest(AUTH, fetchAuthenticatedTipsterSaga);
  yield takeLatest(ANALYSES, fetchLatestRoundsSaga);
  yield takeLatest(ANALYSIS_POST, fetchPublicAnalysisSaga);
  yield takeLatest(ANALYSIS_POST, fetchLatestRoundsSaga);
  yield takeLatest(SELECT_EVENT, fetchEventGroupsSaga);
  yield takeLatest(SELECT_BETOFFER, fetchEventSaga);
  yield takeLatest(ANALYSIS_POST_CREATE, fetchEventSaga);
  yield takeLatest(START_FETCH_MORE_ANALYSES, fetchMoreAnalysesSaga);
  yield takeLatest(UPDATE_EVENT_LIST, fetchEventListSaga);
  yield takeLatest(SUBMIT_ANALYSIS_FORM, submitAnalysisFormSaga);
  yield takeLatest(DELETE_ANALYSIS, deleteAnalysisSaga);
  yield takeLatest(
    [ANALYSIS_POST_CREATE, ANALYSIS_POST_EDIT],
    analysisFormSaga,
  );
  yield takeLatest([ANALYSES_ADMIN, AUTH], fetchUncorrectedAnalysesSaga);
  yield call(fetchUncorrectedAnalysesSaga);
  yield takeLatest(ANALYSES_ADMIN, fetchUpcomingAnalysesSaga);

  yield call(fetchAuthenticatedTipsterSaga);
}

export function* fetchAuthenticatedTipsterSaga(action) {
  yield fetchGeneric(
    fetchAuthenticatedTipster,
    alwaysFetch,
    call(api.getAuthenticatedTipster),
    action,
  );
}

export function* fetchLatestAnalysesSaga(action) {
  // On entering the home or analysis page we first need to
  // get the latest available filters. Later a filter change action
  // will be dispatched which will get the actual analyses to display
  if (action.type === HOME || action.type === ANALYSES) {
    const availableFilters = yield select(availableFiltersSelector);
    if (availableFilters == null) {
      const res = yield call(api.getBetTipsLatest, { limit: 1 });
      yield put(setAvailableFilters(res.filters));
    }
  } else {
    const { filters } = action.payload;
    const filterKey = fnv32a(JSON.stringify(filters));
    const betTipData = {
      limit: 9,
      ...filters,
    };

    yield put(setSelectedFilterKey(filterKey));
    yield fetchGeneric(
      fetchFilteredPublicAnalyses(filterKey),
      onlyIfMissing(latestAnalysesByFilterKeySelector, filterKey),
      call(api.getBetTipsLatest, betTipData),
      action,
    );
  }
}

export function* fetchEventGroupsSaga(action) {
  yield fetchGeneric(
    fetchEventGroups,
    onlyIfMissing(eventGroups),
    call(api.getEventGroups),
    action,
  );
}

export function* deleteAnalysisSaga(action) {
  const { analysisId } = action;
  yield fetchGeneric(
    fetchPrivateAnalysis.bind(undefined, analysisId),
    alwaysFetch,
    call(api.deleteBetTip, analysisId),
    action,
  );

  const analysis = yield select(state => privateAnalyses(state)[analysisId]);

  if (analysis.data === undefined) {
    yield put(toAnalyses()); // todo: redirect to previus path
  }
}

export function* fetchEventSaga(action) {
  const { eventId } = action.payload;

  yield fetchGeneric(
    fetchEvent.bind(undefined, eventId),
    onlyIfMissing(state => eventById(state)[eventId]),
    call(api.getEvent, { eventId }),
    action,
  );
}

export function* fetchPublicAnalysisSaga(action) {
  const { analysisId } = action.payload;

  yield fetchGeneric(
    fetchPublicAnalysis.bind(undefined, analysisId),
    onlyIfMissing(state => publicAnalyses(state)[analysisId]),
    call(api.getBetTip, { analysisId }),
    action,
  );
}

export function* fetchPrivateAnalysisSaga(action) {
  const { analysisId } = action.payload;

  yield fetchGeneric(
    fetchPrivateAnalysis.bind(undefined, analysisId),
    onlyIfMissing(state => privateAnalyses(state)[analysisId]),
    call(api.getPrivateBetTip, { analysisId }),
    action,
  );
}

export function* fetchUpcomingAnalysesSaga(action) {
  yield fetchGeneric(
    fetchUpcomingAnalyses,
    alwaysFetch, // todo
    call(api.getPrivateBetTips, { upcoming: 'true' }),
    action,
  );
}

export function* fetchUncorrectedAnalysesSaga(action) {
  const user = yield select(state => userSelector(state));
  if (
    user.data &&
    Object.prototype.hasOwnProperty.call(user.data.groups, 'overodds') &&
    user.data.groups.overodds.includes('admin')
  ) {
    yield fetchGeneric(
      fetchUncorrectedAnalyses,
      alwaysFetch, // todo
      call(api.getPrivateBetTips, { uncorrected: 'true' }),
      action,
    );
  }
}

export function* analysisFormSaga(action) {
  if (action.type === ANALYSIS_POST_EDIT) {
    const { analysisId } = action.payload;

    yield call(fetchPrivateAnalysisSaga, action);

    const analysis = yield select(state => privateAnalyses(state)[analysisId]);

    if (analysis && analysis.data) {
      yield call(fetchEventSaga, {
        payload: { eventId: analysis.data.event.id },
      });
      yield put(initAnalysisForm(analysis.data));
    }
  } else {
    const location = yield select(locationSelector);
    const { outcomeId, betOfferId, provider, eventId } = location.payload;

    yield put(
      initAnalysisForm({
        outcome_id: Number.parseInt(outcomeId, 10),
        bet_offer: Number.parseInt(betOfferId, 10),
        provider,
        event_id: Number.parseInt(eventId, 10),
      }),
    );
  }
}

export function* fetchEventListSaga(action) {
  yield fetchGeneric(
    fetchEventList,
    alwaysFetch,
    call(api.getEventList, { groupId: action.groupId }),
    action,
  );
}

export function* submitAnalysisFormSaga(action) {
  const { formData } = action;
  const method = formData.id ? api.updateBetTip : api.createBetTip;

  const response = yield fetchGeneric(
    formData.id
      ? fetchPrivateAnalysis.bind(undefined, formData.id)
      : fetchPrivateAnalysis.bind(undefined, 'new'),
    alwaysFetch,
    call(method, formData),
    action,
  );
  if (formData.id) {
    yield fetchGeneric(
      fetchPublicAnalysis.bind(undefined, formData.id),
      alwaysFetch,
      call(api.getBetTip, { analysisId: formData.id }),
      action,
    );
  }
  // TODO: Delete public analysis in store
  if (response && response.id) {
    yield call(fetchAuthenticatedTipsterSaga);
    yield put(toAnalysisPost({ analysisId: response.id }));
  }
}

export function* fetchMoreAnalysesSaga(action) {
  const latestAnalyses = yield select(latestAnalysesByFilterKeySelector);

  const analyses = latestAnalyses[action.key];
  const nextUrl =
    analyses.data != null && analyses.data.next
      ? analyses.data.next
      : undefined;

  if (nextUrl == null) {
    return;
  }

  call(api.getUrl, nextUrl);
  yield fetchGeneric(
    fetchMoreContentList(action.key),
    alwaysFetch,
    call(api.getUrl, nextUrl),
    action,
  );
}
