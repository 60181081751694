import PropTypes from 'prop-types';
import { useContext } from 'react';

import { NavOpenContext } from './NavDropdown';
import styles from './navDropdownTabBarChild.scss';

const NavDropdownTabChild = ({ icon, text }) => {
  const navOpen = useContext(NavOpenContext);

  return (
    <>
      {icon(navOpen)} <span className={styles.label}>{text}</span>
    </>
  );
};

NavDropdownTabChild.propTypes = {
  icon: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default NavDropdownTabChild;
