export const TRACK_BETTIP_REFERRAL_CLICK =
  'ANALYTICS/TRACK_BETTIP_REFERRAL_CLICK';

export const TRACK_OPEN_BETTIP = 'ANALYTICS/TRACK_OPEN_BETTIP';

export const TRACK_CLICK_READ_MORE_BLOG =
  'ANALYTICS/TRACK_CLICK_READ_MORE_BLOG';

export const TRACK_VISIT_BLOG = 'ANALYTICS/TRACK_VISIT_BLOG';

export const trackBetTipReferralClick = (betTip, provider) => ({
  type: TRACK_BETTIP_REFERRAL_CLICK,
  betTip,
  provider,
});

export const trackOpenBetTip = betTip => ({
  type: TRACK_OPEN_BETTIP,
  betTip,
});

export const trackClickReadMoreBlog = article => ({
  type: TRACK_CLICK_READ_MORE_BLOG,
  article,
});

export const trackVisitBlog = article => ({
  type: TRACK_VISIT_BLOG,
  article,
});
