import { delay } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import truth from '../../api/auth';
import { RESET_STATE } from '../../main/actions';
import { COMPETITION } from '../../main/actions/router';
import config from '../../main/config';
import { alwaysFetch, fetchGeneric } from '../../main/sagas/genericFetch';
import {
  fetchCompetitionCouponsSaga,
  fetchCompetitionResultSaga,
  fetchCompetitionSaga,
} from '../../main/sagas/models';
import { locationSelector } from '../../main/selectors';
import { accessibilityFocus } from '../../utils/dom';
import {
  hasClickedSubmit,
  postCoupon,
  readFromLocalStorage,
  RESET_ENTERED_RESULTS,
  resetEnteredResults,
  RESULT_INPUT_BLUR,
  SUBMIT,
} from './actions';
import { TABLES_WRAPPER_ID } from './constants';
import {
  enabledCompetitionSelector,
  enteredResultsByMatchIdSelector,
  postedCouponSelector,
} from './selectors';
import {
  enteredResultsByMatchIdFromLocalStorage,
  enteredResultsByMatchIdToLocalStorage,
} from './utils';

export default function* rootSaga() {
  yield takeLatest(COMPETITION, fetchThisCompetitionSaga);
  yield takeLatest(COMPETITION, fetchThisCompetitionResultSaga);
  yield takeLatest(COMPETITION, fetchSubmittedCouponSaga);
  yield takeLatest(COMPETITION, readFromLocalStorageSaga);
  yield takeLatest(RESULT_INPUT_BLUR, saveEnteredResults);
  yield takeLatest(RESET_ENTERED_RESULTS, saveEnteredResults);
  yield takeLatest(RESET_STATE, saveEnteredResults);
  yield takeLatest(SUBMIT, submitSaga);
}

export function* fetchThisCompetitionSaga(action) {
  yield call(fetchCompetitionSaga, config.COMPETITION_SLUG, action);
}

export function* fetchThisCompetitionResultSaga(action) {
  yield call(
    fetchCompetitionResultSaga,
    {
      slug: config.COMPETITION_SLUG,
      shouldRequest:
        action.type === COMPETITION && !action.justLoggedIn
          ? undefined
          : alwaysFetch,
    },
    action,
  );
}

export function* fetchSubmittedCouponSaga(action) {
  const location = yield select(locationSelector);

  // Allow adding for example `?fetchOverride=ignorePrevious` to the URL as a
  // way to be able to submit competition more than once for debugging.
  const { query: { fetchOverride = 'noOverride' } = {} } = location;

  yield call(
    fetchCompetitionCouponsSaga,
    {
      slug: config.COMPETITION_SLUG,
      fetchOverride:
        action.type === COMPETITION && !action.justLoggedIn
          ? fetchOverride
          : 'alwaysFetch',
    },
    action,
  );
}

export function* readFromLocalStorageSaga() {
  yield put(
    readFromLocalStorage({
      enteredResultsByMatchId: enteredResultsByMatchIdFromLocalStorage(),
    }),
  );
}

export function* saveEnteredResults() {
  const enteredResultsByMatchId = yield select(enteredResultsByMatchIdSelector);
  yield call(enteredResultsByMatchIdToLocalStorage, enteredResultsByMatchId);
}

export function* submitSaga(action) {
  if (!truth.isAuthenticated()) {
    return;
    // TODO - user is not logged in. This needs to be refactored when/if creating a new competition
  }

  const competition = yield select(enabledCompetitionSelector);
  const enteredResultsByMatchId = yield select(enteredResultsByMatchIdSelector);

  if (competition.data == null) {
    return;
  }

  yield put(hasClickedSubmit());

  const matches = Object.values(competition.data.matches);

  const enteredResults = matches.map(match => {
    const results = enteredResultsByMatchId[match.id];
    return {
      matchId: match.id,
      home: results == null ? '' : results.home.value,
      away: results == null ? '' : results.away.value,
    };
  });

  const data = enteredResults.reduce((result, { matchId, home, away }) => {
    result[matchId] = { home, away };
    return result;
  }, {});

  const valid = enteredResults.every(
    item => item.home.trim() !== '' && item.away.trim() !== '',
  );

  if (valid) {
    yield fetchGeneric(
      postCoupon,
      alwaysFetch,
      call(api.postCompetitionCoupon, { id: config.COMPETITION_SLUG }, data),
      action,
    );

    const postedCoupon = yield select(postedCouponSelector);

    if (postedCoupon.data != null) {
      yield all([
        call(fetchSubmittedCouponSaga, action),
        call(fetchThisCompetitionResultSaga, action),
      ]);
      yield put(resetEnteredResults());
    }
  } else {
    yield call(delay, 0);
    yield call(focusFirstInvalidInput);
  }
}

function focusFirstInvalidInput() {
  const input = document.querySelector(`#${TABLES_WRAPPER_ID} input:invalid`);
  if (input == null) {
    console.warn('No invalid input to focus found.');
  } else {
    accessibilityFocus(input);
    try {
      input.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } catch {
      // For browsers not supporting fancy scrolling.
      input.scrollIntoView(true);
    }
  }
}
