import {
  genericFetchInitialState,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import { FETCH_TIPSTER_LIST, FETCH_TIPSTERS } from './actions';

const initialState = {
  tipsters: genericFetchInitialState,
  roi: genericFetchInitialState,
  net_result: genericFetchInitialState,
  'roi/?sports=FOOTBALL': genericFetchInitialState,
  'net_result/?sports=FOOTBALL': genericFetchInitialState,
  'roi/?sports=TROTTING': genericFetchInitialState,
  'net_result/?sports=TROTTING': genericFetchInitialState,
  'roi/?sports=ICE_HOCKEY': genericFetchInitialState,
  'net_result/?sports=ICE_HOCKEY': genericFetchInitialState,
};

export default function tipsterReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TIPSTERS:
      return {
        ...state,
        tipsters: genericFetchReducer(state.tipsters, action.wrappedAction),
      };

    case FETCH_TIPSTER_LIST:
      return {
        ...state,
        [action.listPath]: genericFetchReducer(
          state.List_ROI,
          action.wrappedAction,
        ),
      };

    default:
      return state;
  }
}
