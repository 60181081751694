import PropTypes from 'prop-types';

export const toPropType = PropTypes.oneOfType([
  PropTypes.shape({ type: PropTypes.string.isRequired }).isRequired,
  PropTypes.string.isRequired,
  PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
]);

export const searchFilterPropType = PropTypes.shape({
  property: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

export const oddsRecommendationPropType = PropTypes.oneOf([
  'home',
  'draw',
  'away',
]);
