import PropTypes from 'prop-types';

import { classList } from '../../utils';
import Button from '../Button';
import Spinner from '../Spinner';
import VerticalSpacing from '../VerticalSpacing';
import styles from './styles.scss';

Loading.propTypes = {
  isLoading: PropTypes.bool,
  pastDelay: PropTypes.bool,
  timedOut: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.object.isRequired,
  ]),
  errorMessage: PropTypes.string,
  retry: PropTypes.func,
  children: PropTypes.node,
};

/**
 * Used with react-loadable, and by <GenericFetch>.
 */
export default function Loading({
  isLoading = false,
  pastDelay = false,
  timedOut = false,
  error,
  errorMessage = 'Innehållet gick inte att hämta 😞',
  retry,
  children,
}) {
  if (isLoading) {
    return timedOut ? (
      <Container>
        <p>
          Sidan tog för lång tid att hämta{' '}
          <span role="img" aria-label="Disappointed emoji">
            😞
          </span>
        </p>
        {retry != null && (
          <p>
            <Button onClick={retry}>Försök igen</Button>
          </p>
        )}
        {children == null ? (
          retry == null ? (
            <p>Testa att ladda om sidan.</p>
          ) : null
        ) : (
          children
        )}
      </Container>
    ) : (
      <Container visible={pastDelay}>
        <Spinner className={styles.spinner} />
        {children}
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <p>{errorMessage}</p>
        {DEBUG && typeof error !== 'boolean' && (
          <pre className={styles.pre}>
            {`${error.message}\nText: ${error.responseText || '(missing)'}`}
          </pre>
        )}
        {retry != null && (
          <p>
            <Button onClick={retry}>Försök igen</Button>
          </p>
        )}
        {children == null ? (
          retry == null ? (
            <p>Testa att ladda om sidan.</p>
          ) : null
        ) : (
          children
        )}
      </Container>
    );
  }

  return null;
}

Container.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

function Container({ visible = true, children }) {
  return (
    <VerticalSpacing
      padding={2}
      between={1}
      className={classList({
        [styles.root]: true,
        [styles.invisible]: !visible,
      })}
    >
      {children}
    </VerticalSpacing>
  );
}
