import {
  genericFetchInitialState,
  genericFetchKeyedReducer,
  genericFetchMoreReducer,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  FETCH_MORE_BET_TIPS,
  FETCH_PROFILE,
  FETCH_TIPSTER_BET_TIPS,
} from './actions';

const initialState = {
  profilesBySlug: {},
  liveTipsBySlug: {},
  historicTipsBySlug: {},
};

export default function tipsterReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE:
      return {
        ...state,
        profilesBySlug: {
          ...state.profilesBySlug,
          [action.slug]: genericFetchReducer(
            state.profilesBySlug[action.slug],
            action.wrappedAction,
          ),
        },
        liveTipsBySlug: {
          ...state.liveTipsBySlug,
          [action.slug]: genericFetchInitialState,
        },
        historicTipsBySlug: {
          ...state.historicTipsBySlug,
          [action.slug]: genericFetchInitialState,
        },
      };

    case FETCH_TIPSTER_BET_TIPS: {
      const base = action.live ? 'liveTipsBySlug' : 'historicTipsBySlug';
      return genericFetchKeyedReducer(
        state,
        base,
        action.slug,
        action.wrappedAction,
      );
    }

    case FETCH_MORE_BET_TIPS: {
      const base = action.live ? 'liveTipsBySlug' : 'historicTipsBySlug';
      return {
        ...state,
        [base]: {
          ...state[base],
          [action.slug]: genericFetchMoreReducer(
            state[base][action.slug],
            action.wrappedAction,
          ),
        },
      };
    }

    default:
      return state;
  }
}
