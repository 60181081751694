import cssGlobals from '../../main/css/globals';

export const WAIT_FOR_ALL_AD_SLOTS = 500;

export const STATUS = {
  INITIAL: 'INITIAL',
  FILLED: 'FILLED',
  EMPTY: 'EMPTY',
};

const panoramaRoundAnalysisSizeMapping = [
  {
    viewport: [1580, 0],
    sizes: [[980, 240]],
  },

  {
    viewport: [0, 0],
    sizes: [[320, 320]],
  },
];

const panoramaSizeMapping = [
  {
    viewport: [980 + cssGlobals['g-spacing'] * 4, 0],
    sizes: [[980, 240]],
  },
  {
    viewport: [0, 0],
    sizes: [[320, 320]],
  },
];

const stickySizeMapping = [
  {
    sizes: [[160, 600]],
  },
];

const sticky2SizeMapping = [
  {
    sizes: [[300, 600]],
  },
];

const modulSizeMapping = [
  {
    viewport: [0, 0],
    sizes: [[320, 320]],
  },
  {
    viewport: [498, 0],
    sizes: [[468, 240]],
  },
  {
    viewport: [660, 0],
    sizes: [[630, 250]],
  },
];

export const UNIT_CONFIGS = {
  panoramaroundanalysis: panoramaRoundAnalysisSizeMapping,
  panorama: panoramaSizeMapping,
  skyscraper: stickySizeMapping,
  skyscraper_2: sticky2SizeMapping,
  modul_1: modulSizeMapping,
  modul_2: modulSizeMapping,
};

export const PLACEMENTS = {
  panoramaRoundAnalysis: 'Panorama',
  panorama: 'Panorama',
  skyscraper: 'Sticky',
  skyscraper_2: 'Sticky_2',
  modul_1: 'Modul_1',
  modul_2: 'Modul_2',
};

export const ADS_PREFIX = 'OVERODDS';
