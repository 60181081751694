import PropTypes from 'prop-types';

import { classList } from '../../utils';
import Ad from '../Ad';
import VerticalSpacing from '../VerticalSpacing';
import styles from './styles.scss';

CenteredLayout.propTypes = {
  // “sidebar” just makes the layout a little less wide. Used in the right
  // section in <SidebarLayout>.
  sidebar: PropTypes.bool,
  skyscraper: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

function renderContent(sidebar = false, skyscraper = false, children) {
  if (sidebar || !skyscraper) {
    return children;
  }

  return (
    <>
      <Ad
        placement="skyscraper"
        wrap={slot => (
          <div className={styles.skyscraper}>
            <VerticalSpacing padding={2}>{slot}</VerticalSpacing>
          </div>
        )}
      />
      <div className={styles.skyscraperChildrenWrapper}>{children}</div>
    </>
  );
}

/**
 * Make a section use a maximum width and center it.
 */
export default function CenteredLayout({ sidebar, skyscraper, children }) {
  return (
    // The extra, unstyled wrapper <div> allows using <CenteredLayout> when the
    // parent has `display: flex; flex-direction: column;`. (Flexbox changes
    // what `margin: auto;` means.)
    <div>
      <div
        className={classList({
          [styles.root]: true,
          [styles.hasSidebar]: sidebar,
          [styles.hasSkyscraper]: !sidebar && skyscraper,
        })}
      >
        {renderContent(sidebar, skyscraper, children)}
      </div>
    </div>
  );
}
