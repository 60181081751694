import './browser';
import './index.scss';
import './css/consentBanner.scss';
import 'focus-visible';
import 'whatwg-fetch';

import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { createBrowserHistory as createHistory } from 'history';
import queryString from 'query-string';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createMiddleware } from 'redux-beacon';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { connectRoutes, redirect } from 'redux-first-router';
import restoreScroll from 'redux-first-router-restore-scroll';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { AdsLoaderProvider } from '../components/Ad/AdsLoaderContext';
import ErrorBoundary from '../components/ErrorBoundary';
import Root from '../components/Root';
import aboutSaga from '../views/about/sagas';
import analysesReducer from '../views/analyses/reducers';
import analysesSaga from '../views/analyses/sagas';
import tipsterFormReducer from '../views/becomeTipster/reducers';
import becomeTipster from '../views/becomeTipster/sagas';
import blogReducer from '../views/blog/reducers';
import blogSaga from '../views/blog/sagas';
import competitionReducer from '../views/competition/reducers';
import competitionSaga from '../views/competition/sagas';
import bestgameEmbedSaga from '../views/embed/bestgame/sagas';
import gameReducer from '../views/game/reducers';
import gameSaga from '../views/game/sagas';
import homeReducer from '../views/home/reducers';
import homeSaga from '../views/home/sagas';
import playReducedReducer from '../views/playReduced/reducers';
import playReducedSaga from '../views/playReduced/sagas';
import syndicatesReducer from '../views/playTogether/reducers';
import syndicatesSaga from '../views/playTogether/sagas';
import profileReducer from '../views/profile/reducers';
import profileSaga from '../views/profile/sagas';
import bettingSchoolReducer from '../views/school/reducers';
import bettingSchoolSaga from '../views/school/sagas';
import sharedBetsReducer from '../views/sharedBets/reducers';
import sharedBetsSaga from '../views/sharedBets/sagas';
import soccerReducer from '../views/soccer/reducers';
import soccerSaga from '../views/soccer/sagas';
import statisticsReducer from '../views/statistics/reducers';
import statisticsSaga from '../views/statistics/sagas';
import tipstersReducer from '../views/tipsters/reducers';
import tipstersSaga from '../views/tipsters/sagas';
import worldCupReducer from '../views/worldcup/reducers';
import worldCupSaga from '../views/worldcup/sagas';
import { RESET_STATE } from './actions';
import Bugsnag from './bugsnag';
import config from './config';
import eventsMap, { LOCATION_CHANGED } from './eventsMap';
import authReducer from './reducers/auth';
import modelsReducer from './reducers/models';
import routesMap from './routesMap';
import analyticsSaga from './sagas/analytics';
import authSaga from './sagas/auth';
import ScrollStorage from './ScrollStorage';
import { locationSelector } from './selectors';

const gtm = GoogleTagManager();
const gtmMiddleware = createMiddleware(eventsMap, gtm);
let routerInitialized = false;
// We need to special case some aspects when we've landed on a page meant
// for embedding on other sites
const isEmbedPage = window.location.pathname.startsWith('/embed');

const {
  reducer: routerReducer,
  middleware: routerMiddleware,
  enhancer: routerEnhancer,
} = connectRoutes(routesMap, {
  createHistory,
  restoreScroll: restoreScroll({
    stateStorage: new ScrollStorage(),
  }),
  // Disable redux-first-router `document.title`-setting support. We set the
  // title with react-helmet instead.
  title: () => {},
  querySerializer: queryString,
  onBeforeChange: (dispatch, _getState, { action }) => {
    // Break out of iframe if we're on a page meant for embedding
    if (
      isEmbedPage &&
      action.kind !== 'load' &&
      action.meta?.location.kind === 'push'
    ) {
      window.top.location =
        window.location.origin + action.meta.location.current.pathname;
      // Prevent internal navigation by redirecting to the page we're
      // already on
      dispatch(redirect({ type: action.meta.location.prev.type }));
    }
  },
  onAfterChange: (dispatch, getState) => {
    if (config.GTM_ENABLED && routerInitialized) {
      const state = getState();
      const { pathname } = locationSelector(state);
      dispatch({ type: LOCATION_CHANGED, payload: pathname });
    }
    routerInitialized = true;
  },
});

const sagaMiddleware = createSagaMiddleware({
  onError(error) {
    console.error('Saga error', error);
    Bugsnag.notify(error);
  },
});

const appReducer = combineReducers({
  location: routerReducer,
  analyses: analysesReducer,
  tipsterForm: tipsterFormReducer,
  playReduced: playReducedReducer,
  auth: authReducer,
  blog: blogReducer,
  competition: competitionReducer,
  game: gameReducer,
  home: homeReducer,
  models: modelsReducer,
  statistics: statisticsReducer,
  worldCup: worldCupReducer,
  profiles: profileReducer,
  tipsters: tipstersReducer,
  bettingSchool: bettingSchoolReducer,
  sharedBets: sharedBetsReducer,
  soccer: soccerReducer,
  playTogether: syndicatesReducer,
});

function rootReducer(passedState, action) {
  const state = action.type === RESET_STATE ? undefined : passedState;
  return appReducer(state, action);
}

function* rootSaga() {
  yield all(
    [
      !isEmbedPage && aboutSaga(),
      !isEmbedPage && analysesSaga(),
      !isEmbedPage && becomeTipster(),
      !isEmbedPage && authSaga(),
      bestgameEmbedSaga(),
      !isEmbedPage && blogSaga(),
      !isEmbedPage && playReducedSaga(),
      !isEmbedPage && competitionSaga(),
      !isEmbedPage && gameSaga(),
      !isEmbedPage && soccerSaga(),
      !isEmbedPage && syndicatesSaga(),
      homeSaga(),
      !isEmbedPage && statisticsSaga(),
      !isEmbedPage && worldCupSaga(),
      !isEmbedPage && profileSaga(),
      !isEmbedPage && tipstersSaga(),
      !isEmbedPage && bettingSchoolSaga(),
      analyticsSaga(),
      !isEmbedPage && sharedBetsSaga(),
    ].filter(Boolean),
  );
}

const middlewares = applyMiddleware(
  gtmMiddleware,
  routerMiddleware,
  sagaMiddleware,
);

const store = createStore(
  rootReducer,
  composeWithDevTools(routerEnhancer, middlewares),
);

sagaMiddleware.run(rootSaga);

// redux-first-router immediately fires an action for the current page based on
// the startup URL, but this seems to happen before the sagas get to run. As a
// workaround, re-fire the action so that sagas get a chance to run.
store.dispatch(store.getState().location);

const page = <Root strippedPage={isEmbedPage} />;

render(
  <ErrorBoundary>
    <Provider store={store}>
      {isEmbedPage ? page : <AdsLoaderProvider>{page}</AdsLoaderProvider>}
    </Provider>
  </ErrorBoundary>,
  document.querySelector(`#${config.APP_ELEMENT_ID}`),
);
