import PropTypes from 'prop-types';

import styles from './styles.scss';

CurrentPageBar.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Displays the name of the current page in a thin horizontal bar.
 */
export default function CurrentPageBar({ children }) {
  return <div className={styles.root}>{children}</div>;
}
