import { useCallback, useEffect, useId, useRef } from 'react';

import { useAdsLoaderContext } from './AdsLoaderContext';

// useDfpSlot() is a custom hook that returns a slot object. The slot object
// is appended to the AdsLoaderProvider component via the AdsLoaderContext's method
// addSlotToLoader() and removed via the removeSlotFromLoader() method.
//
// This hook has code that registers and unregisters the slot with Google Publisher Tag.
//
// AdsLoader manages the lifecycle of these useDfpSlot slots.
const useDfpSlot = ({
  path,
  sizes,
  sizeMapping,
  targeting,
  adSenseTargeting,
  renderOutOfThePage,
  onSlotRender,
}) => {
  const slot = useRef(null);
  const slotId = useId();
  const { addSlotToLoader, removeSlotFromLoader } = useAdsLoaderContext();

  const onSlotRenderEnded = useCallback(
    event => {
      if (event.slot.getSlotElementId() !== slotId) {
        return;
      }

      slot.current.filled = !event.isEmpty;

      if (typeof onSlotRender === 'function') {
        onSlotRender(event);
      }
    },
    [onSlotRender, slotId],
  );

  // Defines and registers the slot with Google Publisher Tag and sets up
  // onSlotRender event listener.
  const defineSlot = useCallback(() => {
    if (slot.current.defined) {
      return;
    }

    // Prevent defineSlot from being called more than once for this slot.
    slot.current.defined = true;

    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.googletag.cmd.push(() => {
      const gptSlot = renderOutOfThePage
        ? window.googletag.defineOutOfPageSlot(path, slotId)
        : window.googletag.defineSlot(path, sizes, slotId);

      if (gptSlot) {
        slot.current.gptSlot = gptSlot;

        if (targeting) {
          Object.keys(targeting).forEach(key => {
            window.googletag.pubads().setTargeting(key, targeting[key]);
          });
        }

        if (adSenseTargeting) {
          Object.keys(adSenseTargeting).forEach(key => {
            window.googletag.pubads().set(key, adSenseTargeting[key]);
          });
        }

        gptSlot.addService(window.googletag.pubads());

        if (sizeMapping && sizeMapping.length > 0) {
          const sizeMappingBuilder = window.googletag.sizeMapping();
          for (const { viewport, sizes } of sizeMapping) {
            sizeMappingBuilder.addSize(viewport, sizes);
          }
          gptSlot.defineSizeMapping(sizeMappingBuilder.build());
        }

        window.googletag
          .pubads()
          .addEventListener('slotRenderEnded', onSlotRenderEnded);
      } else {
        // reset defined to false if for some reason gtpSlot is not created
        // from defineSlot or defineOutOfPageSlot
        slot.current.defined = false;
      }
    });
  }, [
    adSenseTargeting,
    onSlotRenderEnded,
    path,
    renderOutOfThePage,
    sizeMapping,
    sizes,
    slotId,
    targeting,
  ]);

  // Removes the slot from the AdsLoader component and unregisters it
  // from Google Publisher Tag and removes the onSlotRender event listener.
  const removeSlot = useCallback(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.googletag.cmd.push(() => {
      if (slot.current.gptSlot && !slot.current.removed) {
        slot.current.removed = true;
        removeSlotFromLoader(slot.current);
        window.googletag.destroySlots([slot.current.gptSlot]);
        window.googletag
          .pubads()
          .removeEventListener('slotRenderEnded', onSlotRenderEnded);
      }
    });
  }, [onSlotRenderEnded, removeSlotFromLoader]);

  // Creates the slot object and appends it to the AdsLoader component.
  useEffect(() => {
    if (!slot.current) {
      slot.current = {
        slotId,
        gptSlot: null,
        filled: false,
        defineSlot,
        defined: false,
        removeSlot,
        removed: false,
      };

      addSlotToLoader(slot.current);
    }

    // Cleanup function that removes the slot
    return () => {
      removeSlot();
    };
  }, [addSlotToLoader, defineSlot, removeSlot, slotId]);

  return slotId;
};

export default useDfpSlot;
