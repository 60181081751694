import { createSelector } from 'reselect';

export const modelsSelector = state => state.models;

export const comingMatchesByTeamIdSelector = createSelector(
  modelsSelector,
  statistics => statistics.comingMatchesByTeamId,
);

export const competitionsBySlugSelector = createSelector(
  modelsSelector,
  models => models.competitionsBySlug,
);

export const competitionCouponsBySlugSelector = createSelector(
  modelsSelector,
  models => models.competitionCouponsBySlug,
);

export const competitionResultsBySlugSelector = createSelector(
  modelsSelector,
  models => models.competitionResultsBySlug,
);

export const contentBySlugSelector = createSelector(
  modelsSelector,
  models => models.contentBySlug,
);

export const roundsSelector = createSelector(
  modelsSelector,
  models => models.rounds,
);

export const latestRoundsSelector = createSelector(
  modelsSelector,
  models => models.latestRounds,
);

export const ATGJackpotsSelector = createSelector(
  modelsSelector,
  models => models.atgJackpots,
);

export const lineupsByMatchIdSelector = createSelector(
  modelsSelector,
  models => models.lineupsByMatchId,
);

export const statisticsByMatchIdSelector = createSelector(
  modelsSelector,
  models => models.statisticsByMatchId,
);

export const matchesByRoundMatchIdSelector = createSelector(
  modelsSelector,
  models => models.matchesByRoundMatchId,
);

export const teamsBySlugSelector = createSelector(
  modelsSelector,
  models => models.teamsBySlug,
);

export const tournamentsListSelector = createSelector(
  modelsSelector,
  models => models.tournamentsList,
);

export const statisticsByTeamIdSelector = createSelector(
  modelsSelector,
  models => models.statisticsByTeamId,
);
