import cssGlobals from '../main/css/globals';
import localStorage from '../main/localStorage';
import { debounce } from '.';

/**
 * Focuses an element for accessibility reasons.
 *
 * - Adds `tabindex="-1"` temporarily to make sure that the element can be
 *   focused, and does not get an outline.
 * - Prevents the browser from changing the scroll position.
 */
export function accessibilityFocus(element) {
  const { scrollX, scrollY } = window;
  const originalTabindex = element.tabIndex;
  element.tabIndex = -1;
  element.focus();
  element.tabindex = originalTabindex;
  window.scrollTo(scrollX, scrollY);
}

export function getWindowSize() {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return {
    width,
    height,

    phonePortraitAndDown: width < cssGlobals['g-breakpoint-phone-landscape'],
    phoneLandscapeAndDown: width < cssGlobals['g-breakpoint-tablet-portrait'],
    tabletPortraitAndDown: width < cssGlobals['g-breakpoint-tablet-landscape'],
    tabletLandscapeAndDown: width < cssGlobals['g-breakpoint-desktop'],
    phoneLandscapeAndUp: width >= cssGlobals['g-breakpoint-phone-landscape'],
    tabletPortraitAndUp: width >= cssGlobals['g-breakpoint-tablet-portrait'],
    tabletLandscapeAndUp: width >= cssGlobals['g-breakpoint-tablet-landscape'],
    desktopAndUp: width >= cssGlobals['g-breakpoint-desktop'],

    navCollapsedAndDown: width < cssGlobals['g-breakpoint-nav-horizontal'],
    navHorizontalAndUp: width >= cssGlobals['g-breakpoint-nav-horizontal'],
  };
}

export function readFromLocalStorage(key) {
  const stored = localStorage.getItem(key);

  // Nothing has been stored yet.
  if (stored == null) {
    return null;
  }

  let parsed;
  try {
    parsed = JSON.parse(stored);
  } catch (error) {
    console.warn('readFromLocalStorage: Failed parse.', {
      localStorageKey: key,
      stored,
      error,
    });
    return null;
  }

  return parsed;
}

export function writeToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function deleteFromLocalStorage(key) {
  localStorage.removeItem(key);
}

/**
 * Runs `fn` `timeout` milliseconds after the window size stops changing.
 */
export function onWindowResize(fn, timeout = 250) {
  const wrapper = debounce(fn, timeout);
  const options = { capture: true, passive: true };

  const events = ['resize', 'orientaionchange'];

  for (const eventName of events) {
    window.addEventListener(eventName, wrapper, options);
  }

  return () => {
    for (const eventName of events) {
      window.removeEventListener(eventName, wrapper, options);
    }
  };
}
