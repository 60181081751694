import { SORT_TABLE } from './actions';

export const SORT_DEFAULT = 'default';

const initialState = {
  sort: {
    home: SORT_DEFAULT,
    away: SORT_DEFAULT,
  },
};

export default function lineupsReducer(state = initialState, action) {
  switch (action.type) {
    case SORT_TABLE:
      return {
        ...state,
        sort: {
          ...state.sort,
          [action.team]: action.property,
        },
      };

    default:
      return state;
  }
}
