import {
  genericFetchInitialState,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import {
  HAS_CLICKED_SUBMIT,
  POST_COUPON,
  READ_FROM_LOCAL_STORAGE,
  RESET_ENTERED_RESULTS,
  RESULT_INPUT_BLUR,
  RESULT_INPUT_CHANGE,
} from './actions';

const enteredResultsInitialState = {
  home: {
    touched: false,
    value: '',
  },
  away: {
    touched: false,
    value: '',
  },
};

const initialState = {
  enteredResultsByMatchId: {},
  hasClickedSubmit: false,
  postedCoupon: genericFetchInitialState,
};

export default function gameReducer(state = initialState, action) {
  switch (action.type) {
    case RESULT_INPUT_BLUR: {
      const { [action.matchId]: previous = enteredResultsInitialState } =
        state.enteredResultsByMatchId;
      return {
        ...state,
        enteredResultsByMatchId: {
          ...state.enteredResultsByMatchId,
          [action.matchId]: {
            ...previous,
            [action.homeAway]: {
              ...previous[action.homeAway],
              value: clean(previous[action.homeAway].value),
              touched: true,
            },
          },
        },
      };
    }

    case RESULT_INPUT_CHANGE: {
      const { [action.matchId]: previous = enteredResultsInitialState } =
        state.enteredResultsByMatchId;
      return {
        ...state,
        enteredResultsByMatchId: {
          ...state.enteredResultsByMatchId,
          [action.matchId]: {
            ...previous,
            [action.homeAway]: {
              ...previous[action.homeAway],
              value: action.value,
              touched: true,
            },
          },
        },
      };
    }

    case HAS_CLICKED_SUBMIT: {
      return {
        ...state,
        hasClickedSubmit: true,
      };
    }

    case POST_COUPON: {
      return {
        ...state,
        postedCoupon: genericFetchReducer(
          state.postedCoupon,
          action.wrappedAction,
        ),
      };
    }

    case RESET_ENTERED_RESULTS:
      return {
        ...state,
        enteredResultsByMatchId: {},
        hasClickedSubmit: false,
      };

    case READ_FROM_LOCAL_STORAGE:
      return {
        ...state,
        enteredResultsByMatchId: action.enteredResultsByMatchId,
      };

    default:
      return state;
  }
}

function clean(value) {
  return value.replace(/\D/g, '').replace(/^0+(\d)$/, '$1');
}
