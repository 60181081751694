export const FETCH_SEASON_STANDINGS = 'game/table/FETCH_SEASON_STANDINGS';
export const FILTER_CHANGE = 'game/table/FILTER_CHANGE';

export const fetchSeasonStandings = wrappedAction => ({
  type: FETCH_SEASON_STANDINGS,
  wrappedAction,
});

export const filterChange = ({ name, value }) => ({
  type: FILTER_CHANGE,
  name,
  value,
});
