import PropTypes from 'prop-types';
import { Component } from 'react';
import { NavLink } from 'redux-first-router-link';

import { toPropType } from '../../main/prop-types';
import { classList } from '../../utils';
import WithDomCalculation from '../WithDomCalculation';
import styles from './styles.scss';

const UNDERLINE_CLASS = 'js-TabBarUnderline';
const MEASURE_CLASS_ACTIVE = 'js-TabBarMeasurementActive';
const LINE_EXTRA = 5; // px

export const SPACER = 'spacer';

const tabPropType = PropTypes.shape({
  to: toPropType,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  isActive: PropTypes.func,
  // Remember to use the `<Underline>` component (see below) when using
  // non-string children!
  children: PropTypes.node,
  render: PropTypes.func,
});

const linkPropType = PropTypes.shape({
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
});

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      tabPropType.isRequired,
      linkPropType.isRequired,
      PropTypes.oneOf([SPACER]).isRequired,
    ]).isRequired,
  ).isRequired,
};

/**
 * A horizontal list of links, one of which is selected. The selected tab is
 * underlined. The lines animates between tabs when switching tabs.
 */
export default class TabBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linePosition: { left: 0, width: 0 },
    };

    this.containerElement = null;
  }

  updateLinePosition() {
    if (!this.containerElement) {
      return;
    }

    const underlineElement = this.containerElement.querySelector(
      `.${MEASURE_CLASS_ACTIVE} .${UNDERLINE_CLASS}`,
    );

    if (!underlineElement) {
      this.setLinePosition({ left: 0, width: 0 });
      return;
    }

    const containerRect = this.containerElement
      ? this.containerElement.getBoundingClientRect()
      : {};

    const rect = underlineElement.getBoundingClientRect();

    const scrollLeft = this.containerElement
      ? this.containerElement.scrollLeft
      : 0;

    this.setLinePosition({
      left: rect.left - containerRect.left + scrollLeft,
      width: rect.width,
    });
  }

  setLinePosition({ left, width }) {
    const { linePosition } = this.state;

    if (linePosition.left !== left || linePosition.width !== width) {
      this.setState({ linePosition: { left, width } });
    }
  }

  componentDidUpdate() {
    this.updateLinePosition();
  }

  render() {
    const { tabs } = this.props;
    const { linePosition } = this.state;

    return (
      <div
        className={styles.root}
        ref={element => {
          this.containerElement = element;
        }}
      >
        <ul className={styles.tabs}>
          {tabs.map((tab, index) => {
            if (tab === SPACER) {
              return (
                <li key={index} role="separator" className={styles.spacer} />
              );
            }

            if (tab.href != null) {
              return (
                <li key={index} className={styles.tabWrapper}>
                  <a
                    href={tab.href}
                    rel="noopener noreferrer"
                    className={classList({
                      [styles.button]: true,
                      [styles.register]: tab.className === 'register',
                      [styles.login]: tab.className === 'login',
                    })}
                  >
                    {tab.children}
                  </a>
                </li>
              );
            }

            const {
              to,
              exact = false,
              strict = false,
              isActive,
              children,
              underline = true,
              render = content => content,
            } = tab;

            const activeClassName = `${styles.active} ${MEASURE_CLASS_ACTIVE}`;

            const content = (
              <>
                {/* This fake text exists since the active item is bolder. The
                fake text is always bold and takes up space, but is invisible.
                The real text is then put on top. This is to avoid jumping when
                switching tabs due to bolder text taking more space. */}
                <span aria-hidden="true" className={styles.fakeText}>
                  {children}
                </span>
                <span className={styles.realText}>
                  {underline ? <Underline>{children}</Underline> : children}
                </span>
              </>
            );

            return (
              <li key={index} className={styles.tabWrapper}>
                {render(
                  to == null ? (
                    <button
                      type="button"
                      className={classList({
                        [styles.tab]: true,
                        [activeClassName]:
                          isActive == null ? false : isActive(),
                      })}
                    >
                      {content}
                    </button>
                  ) : (
                    <NavLink
                      to={to}
                      exact={exact}
                      strict={strict}
                      className={styles.tab}
                      activeClassName={activeClassName}
                      isActive={isActive}
                    >
                      {content}
                    </NavLink>
                  ),
                )}
              </li>
            );
          })}
        </ul>

        <WithDomCalculation onCalculation={this.updateLinePosition.bind(this)}>
          <div
            className={styles.line}
            style={{
              transform: `translate3d(${
                linePosition.left - LINE_EXTRA
              }px, 0, 0) scaleX(${
                linePosition.width <= 0
                  ? 0
                  : linePosition.width + LINE_EXTRA * 2
              })`,
            }}
          />
        </WithDomCalculation>
      </div>
    );
  }
}

TabBar.propTypes = propTypes;

Underline.propTypes = {
  children: PropTypes.node.isRequired,
};

// Marks what part of a tab to underline when the tab is selected.
export function Underline({ children }) {
  return <span className={UNDERLINE_CLASS}>{children}</span>;
}
