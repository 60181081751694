import PropTypes from 'prop-types';

import Bugsnag from '../../main/bugsnag';
import Loading from '../Loading';

FallbackComponent.propTypes = {
  error: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
};

function FallbackComponent({ error, info }) {
  return (
    <Loading
      error={new Error(`${error}\n${info.componentStack}`)}
      errorMessage="Ett oväntat fel inträffade 😞"
    />
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function ErrorBoundary({ children }) {
  const BugsnagErrorBoundary = Bugsnag.getPlugin('react');
  return (
    <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </BugsnagErrorBoundary>
  );
}
