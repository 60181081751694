import { createSelector } from 'reselect';

import { locationSelector } from '../../main/selectors';
import { contentBySlugSelector } from '../../main/selectors/models';

export const worldCupSelector = state => state.worldCup;

export const analysesSelector = createSelector(
  worldCupSelector,
  worldCup => worldCup.analyses,
);

export const defaultAnalysisSelector = createSelector(
  worldCupSelector,
  worldCup => worldCup.defaultAnalysis,
);

export const currentAnalysisSelector = createSelector(
  locationSelector,
  contentBySlugSelector,
  defaultAnalysisSelector,
  (location, contentBySlug, defaultAnalysis) => {
    const { slug } = location.payload;
    return slug == null
      ? defaultAnalysis
      : contentBySlug[slug] || {
          fetching: false,
          error: new Error(`Kunde inte hitta innehåll för ${slug}`),
        };
  },
);

export const sidebarOpenSelector = createSelector(
  worldCupSelector,
  worldCup => worldCup.sidebarOpen,
);

export const querySelector = createSelector(
  worldCupSelector,
  worldCup => worldCup.query,
);
