export const FETCH_BLOG_POSTS = 'home/FETCH_BLOG_POSTS';
export const FETCH_CAROUSEL_SLIDES = 'home/FETCH_CAROUSEL_SLIDES';
export const FETCH_NEWS = 'home/FETCH_NEWS';
export const FETCH_GOLDEN_BET_TIP = 'home/FETCH_GOLDEN_BET_TIP';
export const FETCH_SVENSKA_SPEL_WIDGET = 'home/FETCH_SVENSKA_SPEL_WIDGET';
export const FETCH_MONTH_TOP_USER = 'home/FETCH_MONTH_TOP_USER';
export const FETCH_TOP_STREAK = 'home/FETCH_TOP_STREAK';
export const FETCH_BEST_MATCH = 'home/FETCH_BEST_MATCH';

export const fetchBlogPosts = wrappedAction => ({
  type: FETCH_BLOG_POSTS,
  wrappedAction,
});

export const fetchCarouselSlides = wrappedAction => ({
  type: FETCH_CAROUSEL_SLIDES,
  wrappedAction,
});

export const fetchNews = wrappedAction => ({
  type: FETCH_NEWS,
  wrappedAction,
});

export const fetchGoldenBetTip = wrappedAction => ({
  type: FETCH_GOLDEN_BET_TIP,
  wrappedAction,
});

export const fetchSvenskaSpelWidget = wrappedAction => ({
  type: FETCH_SVENSKA_SPEL_WIDGET,
  wrappedAction,
});

export const fetchMonthTopUser = wrappedAction => ({
  type: FETCH_MONTH_TOP_USER,
  wrappedAction,
});

export const fetchTopStreak = wrappedAction => ({
  type: FETCH_TOP_STREAK,
  wrappedAction,
});

export const fetchBestMatch = wrappedAction => ({
  type: FETCH_BEST_MATCH,
  wrappedAction,
});
