export const LOCATION_CHANGED = 'events/LOCATION_CHANGED';
export const GTM_EVENT = 'events/GTM_EVENT';

// Must match a google analytic tracking type
const hitType = {
  EVENT: 'event',
  PAGEVIEW: 'pageview',
};

// Must match an event name in google tag manager
const gtmEvents = {
  GA_EVENT: 'event-ga',
};

export const eventCategories = {
  OPEN_BETTIP: 'open bettip',
  BETTIP_TIPSTER: 'bettip tipster',
  BETTIP_SPORT: 'bettip sport',
  BETTIP_PROVIDER: 'bettip provider',
  VISIT_BLOG: 'visit blog',
  CLICK_EVENTS: 'click events',
  BETTIP_REFERRAL: 'bettip referral',
};

export const eventActions = {
  OPEN_BETTIP: 'open bettip',
  BETTIP_REFERRAL_PROVIDER_LINK: 'bettip referral provider link',
  BETTIP_REFERRAL: 'bettip referral',
  CLICK_READ_MORE_BLOG_HOME_PAGE: 'read more blog home page',
};

export function pageView(action) {
  return {
    hitType: hitType.PAGEVIEW,
    page: action.payload,
  };
}

const event = data => {
  const {
    eventCategory = null,
    eventAction = null,
    eventLabel = null,
    eventValue = null,
  } = data;
  return {
    hitType: hitType.EVENT,
    event: gtmEvents.GA_EVENT,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
  };
};

const eventsMap = {
  [LOCATION_CHANGED]: pageView,
  [GTM_EVENT]: event,
};

export default eventsMap;
