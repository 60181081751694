import PropTypes from 'prop-types';

import ChevronDownIcon from '../../icons/chevron-down.svg';
import { classList } from '../../utils';
import Icon from '../Icon';
import styles from './styles.scss';

Chevron.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']).isRequired,
};

/**
 * Display an SVG icon with a normalized size and other common props.
 */
export default function Chevron({ className, direction }) {
  return (
    <Icon
      icon={ChevronDownIcon}
      className={classList({
        [className]: true,
        [styles[direction]]: direction !== 'down',
      })}
    />
  );
}
