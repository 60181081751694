import { createSelector } from 'reselect';

export const authSelector = state => state.auth;

export const userSelector = createSelector(authSelector, auth => auth.user);

export const userRolesSelector = createSelector(userSelector, user =>
  user &&
  user.data &&
  Object.prototype.hasOwnProperty.call(user.data.groups, 'overodds')
    ? user.data.groups.overodds
    : [],
);
