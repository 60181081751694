import {
  genericFetchInitialState,
  genericFetchReducer,
} from '../../main/reducers/genericFetch';
import { FETCH_SHARED_BETS } from './actions';

const initialState = {
  all: genericFetchInitialState,
};

export default function tipsterReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHARED_BETS:
      return {
        ...state,
        all: genericFetchReducer(state.sharedBets, action.wrappedAction),
      };
    default:
      return state;
  }
}
