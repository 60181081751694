import { createSelector } from 'reselect';

export const homeSelector = state => state.home;

export const analysesSelector = createSelector(
  homeSelector,
  home => home.analyses,
);

export const blogPostsSelector = createSelector(
  homeSelector,
  home => home.blogPosts,
);

export const carouselSlidesSelector = createSelector(
  homeSelector,
  home => home.carouselSlides,
);

export const newsSelector = createSelector(homeSelector, home => home.news);

export const goldenBetTipSelector = createSelector(
  homeSelector,
  home => home.goldenBetTip,
);

export const svenskaSpelWidgetSelector = createSelector(
  homeSelector,
  home => home.svenskaSpelWidget,
);

export const topMonthUserSelector = createSelector(
  homeSelector,
  home => home.topMonthUser,
);

export const topStreakSelector = createSelector(
  homeSelector,
  home => home.topStreak,
);

export const bestMatchSelector = createSelector(
  homeSelector,
  home => home.bestMatch,
);
