export const FETCH_SYNDICATES_LIST = 'syndicates/FETCH_SYNDICATES';
export const FETCH_USER_SYNDICATES_LIST = 'syndicates/FETCH_USER_SYNDICATES';
export const HANDLE_USER_SYNDICATES = 'syndicates/HANDLE_USER_SYNDICATES';
export const SUBMIT_SYNDICATE_FORM = 'syndicates/SUBMIT_SYNDICATE_FORM';
export const HANDLE_SUBMIT = 'syndicates/HANDLE_SUBMIT';
export const SYNDICATE_FORM_CLOSE = 'syndicates/SYNDICATE_FORM_CLOSE';
export const DELETE_SYNDICATE = 'syndicates/DELETE_SYNDICATE';
export const HANDLE_DELETE_SYNDICATE = 'syndicates/HANDLE_DELETE_SYNDICATE';

export const fetchSyndicatesList = wrappedAction => ({
  type: FETCH_SYNDICATES_LIST,
  wrappedAction,
});

export const fetchUserSyndicatesList = (shouldOverride = false) => ({
  type: FETCH_USER_SYNDICATES_LIST,
  shouldOverride,
});

export const fetchUserSyndicates = wrappedAction => ({
  type: HANDLE_USER_SYNDICATES,
  wrappedAction,
});

export const submitSyndicateForm = formData => ({
  type: SUBMIT_SYNDICATE_FORM,
  formData,
});

export const submitSyndicate = wrappedAction => ({
  type: HANDLE_SUBMIT,
  wrappedAction,
});

export const deleteSyndicate = id => ({
  type: DELETE_SYNDICATE,
  id,
});

export const handleDeleteSyndicate = wrappedAction => ({
  type: HANDLE_DELETE_SYNDICATE,
  wrappedAction,
});

export const syndicateFormClose = (shouldOverride = false, id) => ({
  type: SYNDICATE_FORM_CLOSE,
  shouldOverride,
  id,
});
