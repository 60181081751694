import './styles.scss';

import PropTypes from 'prop-types';

Icon.propTypes = {
  icon: PropTypes.func.isRequired,
  // ...restProps passed on.
};

/**
 * Display an SVG icon with a normalized size and other common props.
 */
export default function Icon({ icon: SVGIcon, ...restProps }) {
  return <SVGIcon data-icon {...restProps} />;
}
