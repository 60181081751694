// Route actions:
export const ABOUT = 'ABOUT';
export const ABOUT_TEAM_MEMBER = 'ABOUT_TEAM_MEMBER';
export const ANALYSES = 'ANALYSES';
export const ANALYSES_ADMIN = 'ANALYSES_ADMIN';
export const ANALYSIS_POST = 'ANALYSIS_POST';
export const ANALYSIS_POST_EDIT = 'ANALYSIS_POST_EDIT';
export const ANALYSIS_POST_CREATE = 'ANALYSIS_POST_CREATE';
export const BECOME_TIPSTER = 'BECOME_TIPSTER';
export const BESTGAME_EMBED = 'BESTGAME_EMBED';
export const BLOG = 'BLOG';
export const BIG9 = 'BIG9';
export const BLOG_POST = 'BLOG_POST';
export const COMPETITION = 'COMPETITION';
export const GAME = 'GAME';
export const GAME_EVENTS = 'GAME_EVENTS';
export const GAME_INFO = 'GAME_INFO';
export const GAME_INTERVIEWS = 'GAME_INTERVIEWS';
export const GAME_LINEUPS = 'GAME_LINEUPS';
export const GAME_STATISTICS = 'GAME_STATISTICS';
export const GAME_SUMMARY = 'GAME_SUMMARY';
export const GAME_TABLE = 'GAME_TABLE';
export const HOME = 'HOME';
export const STATISTICS = 'STATISTICS';
export const STATISTICS_ONE_TEAM = 'STATISTICS_ONE_TEAM';
export const STATISTICS_TWO_TEAMS = 'STATISTICS_TWO_TEAMS';
export const WORLD_CUP = 'WORLD_CUP';
export const SCHOOL = 'SCHOOL';
export const PLAY_TOGETHER = 'PLAY_TOGETHER';
export const SCHOOL_POST = 'SCHOOL_POST';
export const SCHOOL_AUTHOR = 'SCHOOL_AUTHOR';
export const PROFILE = 'PROFILE';
export const TIPSTERS = 'TIPSTERS';
export const PLAY_REDUCED = 'PLAY_REDUCED';
export const SELECT_EVENT = 'SELECT_EVENT';
export const SELECT_BETOFFER = 'SELECT_BETOFFER';
export const SHARED_BETS = 'SHARED_BETS';
export const SOCCER = 'SOCCER';
export const LEAGUE_POST = 'LEAGUE_POST';
export const TEAM_POST = 'TEAM_POST';

// Other router actions:
export const GAME_FIRST_TAB = GAME_INFO;
export const ENTERED_ANY_GAME_PAGE = 'ENTERED_ANY_GAME_PAGE';

const simpleRouteAction = type => ({
  type,
});

export const toAbout = simpleRouteAction.bind(undefined, ABOUT);
export const toBecomeTipster = simpleRouteAction.bind(
  undefined,
  BECOME_TIPSTER,
);
export const toAnalyses = simpleRouteAction.bind(undefined, ANALYSES);
export const toBlog = simpleRouteAction.bind(undefined, BLOG);
export const toCompetition = simpleRouteAction.bind(undefined, COMPETITION);
export const toHome = simpleRouteAction.bind(undefined, HOME);
export const toStatistics = simpleRouteAction.bind(undefined, STATISTICS);
export const toSoccer = simpleRouteAction.bind(undefined, SOCCER);
export const toAnalysesAdmin = simpleRouteAction.bind(
  undefined,
  ANALYSES_ADMIN,
);

export const toAnalysisPost = ({ analysisId }) => ({
  type: ANALYSIS_POST,
  payload: {
    analysisId,
  },
});

export const toAnalysisPostEdit = ({ analysisId }) => ({
  type: ANALYSIS_POST_EDIT,
  payload: {
    analysisId,
  },
});

export const toBig9 = () => ({
  type: BIG9,
  payload: {
    tag: 'big9',
  },
});

export const toBlogPost = ({ slug }) => ({
  type: BLOG_POST,
  payload: {
    slug,
  },
});

export const toSelectBetOffer = ({ eventId }) => ({
  type: SELECT_BETOFFER,
  payload: {
    eventId,
  },
});

export const toSelectEvent = () => ({
  type: SELECT_EVENT,
});

export const toAnalysisForm = ({
  eventId,
  outcomeId,
  betOfferId,
  provider,
}) => ({
  type: ANALYSIS_POST_CREATE,
  payload: {
    betOfferId,
    eventId,
    outcomeId,
    provider,
  },
});

export const toStatisticsOneTeam = ({ slug1 }) => ({
  type: STATISTICS_ONE_TEAM,
  payload: {
    slug1,
  },
});

export const toStatisticsTwoTeams = ({ slug1, slug2 }) => ({
  type: STATISTICS_TWO_TEAMS,
  payload: {
    slug1,
    slug2,
  },
});

export const toWorldCup = ({ slug } = {}) => ({
  type: WORLD_CUP,
  payload: {
    slug,
  },
});

export const toSchool = () => ({
  type: SCHOOL,
});

export const toPlayTogether = () => ({
  type: PLAY_TOGETHER,
});

export const toSharedBets = () => ({
  type: SHARED_BETS,
});

export const toPlayReduced = () => ({
  type: PLAY_REDUCED,
});

export const toSchoolPost = ({ slug }) => ({
  type: SCHOOL_POST,
  payload: {
    slug,
  },
});

export const toSchoolAuthor = ({ slug }) => ({
  type: SCHOOL_AUTHOR,
  payload: {
    slug,
  },
});

export const toTipsters = () => ({
  type: TIPSTERS,
  payload: {},
});

export const toGameStartPage = ({ gameType, roundId }) => ({
  type: GAME,
  payload: {
    gameType,
    roundId,
  },
});

export const toGameSubPage = (
  actionType,
  { gameType, roundId, roundMatchId, query },
) => ({
  type: actionType,
  payload: {
    gameType,
    roundId,
    roundMatchId,
  },
  query,
});

export const enteredAnyGamePage = ({ gameType, roundId, roundMatchId }) => ({
  type: ENTERED_ANY_GAME_PAGE,
  payload: {
    gameType,
    roundId,
    roundMatchId,
  },
});

export const toProfilePage = ({ slug }) => ({
  type: PROFILE,
  payload: { slug },
});

export const toLeaguePost = ({ slug }) => ({
  type: LEAGUE_POST,
  payload: { slug },
});

export const toTeamPost = ({ league, slug }) => ({
  type: TEAM_POST,
  payload: { league, slug },
});
