import { delay } from 'redux-saga';
import { all, call, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import {
  STATISTICS,
  STATISTICS_ONE_TEAM,
  STATISTICS_TWO_TEAMS,
} from '../../main/actions/router';
import {
  alwaysFetch,
  fetchGeneric,
  onlyIfMissing,
} from '../../main/sagas/genericFetch';
import {
  fetchTeamSaga,
  fetchTournamentsListSaga,
} from '../../main/sagas/models';
import { locationSelector } from '../../main/selectors';
import { teamsBySlugSelector } from '../../main/selectors/models';
import {
  fetchMoreTeamsList,
  fetchPopularTeams,
  fetchPopularTournaments,
  fetchTeam1Statistics,
  fetchTeam2Statistics,
  fetchTeamsList,
  FILTER_CHANGE,
  QUERY_CHANGE,
  START_FETCH_MORE_TEAMS_LIST,
} from './actions';
import {
  filtersSelector,
  popularTeamsSelector,
  popularTournamentsSelector,
  teamsListSelector,
} from './selectors';

const NUM_POPULAR_TEAMS = 8;
const NUM_POPULAR_TOURNAMENTS = NUM_POPULAR_TEAMS;
const TEAMS_TO_LOAD_AT_A_TIME = 10;
const SEARCH_DELAY = 250; // ms

export default function* rootSaga() {
  yield takeLatest(STATISTICS, fetchPopularSaga);
  yield takeLatest(STATISTICS_ONE_TEAM, fetchPopularSaga);

  yield takeLatest(STATISTICS_ONE_TEAM, fetchOneTeamSaga);
  yield takeLatest(STATISTICS_TWO_TEAMS, fetchTwoTeamsSaga);

  yield takeLatest(
    [STATISTICS, STATISTICS_ONE_TEAM, STATISTICS_TWO_TEAMS],
    fetchTournamentsListSaga,
  );

  yield takeLatest(FILTER_CHANGE, refetchStatisticsSaga);
  yield takeLatest(QUERY_CHANGE, fetchTeamsSaga);
  yield takeLatest(START_FETCH_MORE_TEAMS_LIST, fetchMoreTeamsSaga);
}

export function* fetchTeamsSaga(action) {
  const query = action.query.trim();

  if (query === '') {
    return;
  }

  yield call(delay, SEARCH_DELAY);

  yield fetchGeneric(
    fetchTeamsList,
    alwaysFetch,
    call(api.listTeams, {
      query,
      limit: TEAMS_TO_LOAD_AT_A_TIME,
    }),
    action,
  );
}

export function* fetchMoreTeamsSaga(action) {
  const teams = yield select(teamsListSelector);
  const nextUrl = teams.data == null ? undefined : teams.data.next;

  if (nextUrl == null) {
    return;
  }

  yield fetchGeneric(
    fetchMoreTeamsList,
    alwaysFetch,
    call(api.getUrl, nextUrl),
    action,
  );
}

export function* fetchPopularSaga(action) {
  yield all([
    call(fetchPopularTeamsSaga, action),
    call(fetchPopularTournamentsSaga, action),
  ]);
}

export function* fetchPopularTeamsSaga(action) {
  yield fetchGeneric(
    fetchPopularTeams,
    onlyIfMissing(popularTeamsSelector),
    call(api.listTeams, { sort: 'popular', limit: NUM_POPULAR_TEAMS }),
    action,
  );
}

export function* fetchPopularTournamentsSaga(action) {
  yield fetchGeneric(
    fetchPopularTournaments,
    onlyIfMissing(popularTournamentsSelector),
    call(api.listTournaments, {
      sort: 'popular',
      limit: NUM_POPULAR_TOURNAMENTS,
    }),
    action,
  );
}

export function* fetchOneTeamSaga(action) {
  const { slug1 } = action.payload;
  yield call(fetchTeamAndStatisticsSaga, slug1, fetchTeam1Statistics, action);
}

export function* fetchTwoTeamsSaga(action) {
  const { slug1, slug2 } = action.payload;
  yield all([
    call(fetchTeamAndStatisticsSaga, slug1, fetchTeam1Statistics, action),
    call(fetchTeamAndStatisticsSaga, slug2, fetchTeam2Statistics, action),
  ]);
}

export function* fetchTeamAndStatisticsSaga(
  slug,
  statistcsActionCreator,
  retryAction,
) {
  yield call(fetchTeamSaga, slug, retryAction);

  const teamsBySlug = yield select(teamsBySlugSelector);
  const team = teamsBySlug[slug];

  if (team == null || team.data == null) {
    return;
  }

  yield call(
    fetchStatisticsSaga,
    team.data.id,
    statistcsActionCreator,
    retryAction,
  );
}

export function* fetchStatisticsSaga(
  teamId,
  statistcsActionCreator,
  retryAction,
) {
  const filters = yield select(filtersSelector);

  yield fetchGeneric(
    statistcsActionCreator,
    alwaysFetch,
    call(
      api.getTeamFilterStatistics,
      { id: teamId },
      api.defaultToUndefined(filters),
    ),
    retryAction,
  );
}

export function* refetchStatisticsSaga(action) {
  const location = yield select(locationSelector);
  const teamsBySlug = yield select(teamsBySlugSelector);

  const { slug1, slug2 } = location.payload;

  const team1 = teamsBySlug[slug1];
  const team2 = teamsBySlug[slug2];

  yield all(
    [
      team1 == null || team1.data == null
        ? undefined
        : call(
            fetchStatisticsSaga,
            team1.data.id,
            fetchTeam1Statistics,
            action,
          ),
      team2 == null || team2.data == null
        ? undefined
        : call(
            fetchStatisticsSaga,
            team2.data.id,
            fetchTeam2Statistics,
            action,
          ),
    ].filter(Boolean),
  );
}
