import { all, call, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { SCHOOL, SCHOOL_AUTHOR, SCHOOL_POST } from '../../main/actions/router';
import {
  alwaysFetch,
  fetchGeneric,
  onlyIfMissing,
} from '../../main/sagas/genericFetch';
import {
  fetchContentSagaHelper,
  fetchLatestRoundsSaga,
} from '../../main/sagas/models';
import {
  FETCH_SCHOOL_POSTS_BY_AUTHOR,
  fetchBettingSchool,
  fetchMoreSchoolPosts,
  fetchTipstersAction,
  START_FETCH_MORE_SCHOOL_POSTS,
} from './actions';
import { mapSlugToId, slugs } from './constants';
import { schoolPostSelector, schoolTipsterSelector } from './selectors';

export default function* rootSaga() {
  yield takeLatest(
    [SCHOOL_AUTHOR, SCHOOL, FETCH_SCHOOL_POSTS_BY_AUTHOR],
    fetchBettingSchoolSaga,
  );
  yield takeLatest([SCHOOL, SCHOOL_AUTHOR], fetchSchoolTipsterSaga);
  yield takeLatest(START_FETCH_MORE_SCHOOL_POSTS, fetchMoreSchoolPostsSaga);
  yield takeLatest(
    [FETCH_SCHOOL_POSTS_BY_AUTHOR, SCHOOL_AUTHOR, SCHOOL],
    fetchLatestRoundsSaga,
  );
  yield takeLatest(SCHOOL_POST, fetchContentSagaHelper);
}

export function* fetchBettingSchoolSaga(action) {
  const { slug, id } = action.payload;
  const authorId = id ?? mapSlugToId[slug];

  yield fetchGeneric(
    fetchBettingSchool(authorId),
    onlyIfMissing(schoolPostSelector, authorId),
    call(api.latestContent, {
      type: 'betting_school',
      limit: 10,
      author_id: authorId ?? undefined,
    }),
    action,
  );
}

export function* fetchSchoolTipsterSaga(action) {
  const teachersToFetch = slugs.map(slug =>
    call(api.getTipsterProfile, { slug }),
  );
  yield fetchGeneric(
    fetchTipstersAction,
    onlyIfMissing(schoolTipsterSelector),
    all(teachersToFetch),
    action,
  );
}

export function* fetchMoreSchoolPostsSaga(action) {
  const schoolPosts = yield select(schoolPostSelector);

  const nextUrl =
    schoolPosts[action.id].data == null
      ? undefined
      : schoolPosts[action.id].data.next;

  if (nextUrl == null) {
    return;
  }
  yield fetchGeneric(
    fetchMoreSchoolPosts(action.id),
    alwaysFetch,
    call(api.getUrl, nextUrl),
    action,
  );
}
